import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Task } from '../shared/task.type';
import { ObjectType } from './task.type';

// TODO(SL-1445): Implement this service to use the api endpoints once they are built

export interface TasksResponse {
    data: Task[];
}

@Injectable()
export class TaskService {
    getSampleData(): Observable<TasksResponse> {
        // set constant time (for vis reg build), set day to 2 days ahead
        const dueDate = new Date().setHours(10, 5, 0, 0) + (1000 * 60 * 60 * 24 * 2);
        const taskList: Task[] = [
            {
                title: 'Changes for Order #570833 (Shipping Soon)',
                description: 'There is an issue with a report that needs to be reviewed, please see if you can determine what the problem is',
                createdBy: 'Rhonda Pearson',
                dateCreated: new Date('Oct 15, 2019').valueOf(),
                dueDate,
                object: ObjectType.ORDER,
                assignedTo: [
                    {
                        name: 'Bob Johnson',
                        avatarUrl: ''
                    }
                ]
            },
            {
                title: 'Changes for Order #570836 (Shipping Soon)',
                description: 'There is an issue with a report that needs to be reviewed, please see if you can determine what the problem is',
                createdBy: 'Rhonda Pearson',
                dateCreated: new Date('Oct 17, 2019').valueOf(),
                dueDate,
                object: ObjectType.ORDER,
                assignedTo: [
                    {
                        name: 'Trent Kelly',
                        avatarUrl: ''
                    },
                    {
                        name: 'Peter Davidson',
                        avatarUrl: ''
                    },
                    {
                        name: 'Billy Goulding',
                        avatarUrl: ''
                    },
                    {
                        name: 'Nathan Weret',
                        avatarUrl: ''
                    }
                ]
            },
            {
                title: 'Changes for Order #570843 (Shipping Soon)',
                description: 'There is an issue with a report that needs to be reviewed, please see if you can determine what the problem is Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
                createdBy: 'Rhonda Pearson',
                dateCreated: new Date('Oct 20, 2019').valueOf(),
                dueDate,
                object: ObjectType.ORDER,
                assignedTo: [
                    {
                        name: 'Bob Johnson',
                        avatarUrl: ''
                    },
                    {
                        name: 'Trent Kelly',
                        avatarUrl: ''
                    },
                    {
                        name: 'Peter Davidson',
                        avatarUrl: ''
                    },
                    {
                        name: 'Jane Doe',
                        avatarUrl: ''
                    },
                    {
                        name: 'Tom Real',
                        avatarUrl: ''
                    }
                ]
            }
        ];
        return of({ data: taskList });
    }
}
