import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { Subscription, Unsubscribable } from 'rxjs';
import { AppColumn, ColumnSize, StackedAppColumn } from '../app-column';
import { AppColumnRouterModalMatcherService } from './app-column-router-modal-matcher.service';
import { AppStateService } from '../../state/state-management/app-state.service';
import { RouteGroupingService } from './route-grouping.service';
import { AppColumnsService } from './app-columns.service';

@Injectable()
export class AppColumnRouterService implements OnDestroy {
    private locationSubscription: Unsubscribable;
    private routers: {
        [id: number]: {
            router: Router,
            subscription: Subscription,
            stackedRouters: {
                router: Router,
                subscription: Subscription
            }[]
        }
    } = {};
    private appColumnRoutersRegistered = 0;
    private appColumnsRestored = false;

    constructor(
        private routeGroupingService: RouteGroupingService,
        private location: Location,
        private appStateService: AppStateService,
        private appColumnsService: AppColumnsService,
        private appColumnRouterModalMatcherService: AppColumnRouterModalMatcherService
    ) {
        this.locationSubscription = this.location.subscribe((changeState: PopStateEvent) => {
            const { state } = changeState;
            if (state) {
                this.restoreAppsFromHistory(state.appColumns);
            }
        });
        this.appStateService.registerGetAppState(() => this.appColumnsService.serialize());
        this.appStateService.monitorAppStateChanges();

        // If there are no app columns to restore, then navigate to the url in the url bar
        if (this.appColumnsService.getAll().length === 0) {
            this.navigateToLocation();
        }
    }

    ngOnDestroy() {
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
    }

    registerAppColumnRouter(appColumnId: number, router: Router) {
        const subscription = router.events.subscribe(observer => {
            if (observer instanceof NavigationStart) {
                this.appColumnsService.addAppColumnUrlChange(appColumnId, observer.url);
            }
        });
        this.routers[appColumnId] = { router, subscription, stackedRouters: [] };
        this.appColumnRoutersRegistered += 1;

        // If appColumns are restored, we route the appColumn to it's url and change the location.
        // Otherwise, we route the appColumn to it's url, but we don't change the location
        this.routeAppColumnToUrl(appColumnId, null, !this.appColumnsRestored);


        // Once all routers are registered, we navigate to the url in the url bar
        if (!this.appColumnsRestored && this.appColumnRoutersRegistered === this.appColumnsService.getAll().length) {
            this.appColumnsRestored = true;
            this.navigateToLocation()
        }
    }

    registerStackedAppColumnRouter(appColumnId: number, stackedAppColumn: StackedAppColumn, router: Router) {
        const subscription = router.events.subscribe(observer => {
            if (observer instanceof NavigationStart) {
                this.appColumnsService.addStackedAppColumnUrlChange(appColumnId, observer.url);
            }
        });
        this.routers[appColumnId].stackedRouters.push({ router, subscription });
        this.routeStackedAppColumnToUrl(appColumnId, stackedAppColumn.url);
    }

    unRegisterAppColumnRouter(appColumnId: number) {
        this.routers[appColumnId].subscription.unsubscribe();
        delete this.routers[appColumnId];
    }

    unRegisterStackedAppColumnRouter(appColumnId: number) {
        const stackedRouter = this.routers[appColumnId].stackedRouters.pop();
        if (stackedRouter) {
            stackedRouter.subscription.unsubscribe();
        }
    }

    routeAppColumnToUrl(
        appColumnId: number,
        url?: string,
        skipLocationChange = false) {
        const appColumn = this.appColumnsService.getById(appColumnId);
        const urlToUse = url ? url : appColumn.url;
        if (this.routers[appColumnId]) {
            this.routers[appColumnId].router.navigateByUrl(urlToUse, { skipLocationChange }).then(result => {
                if (result === null && !skipLocationChange) {
                    this.location.go(urlToUse);
                }
            });
        }
    }

    routeStackedAppColumnToUrl(appColumnId: number, url?: string, skipLocationChange = false) {
        const stackedRoutes = this.routers[appColumnId].stackedRouters;
        const appColumn = this.appColumnsService.getById(appColumnId);

        const stackedAppColumn = appColumn.getTopMostStackedAppColumn();
        if (!stackedAppColumn) {
            throw new Error(`Stacked app columns do not exist for appColumnId: ${appColumnId}`);
        }
        if (stackedRoutes.length > 0) {
            stackedRoutes[stackedRoutes.length - 1].router.navigateByUrl(url ? url : stackedAppColumn.url, { skipLocationChange });
        }
    }

    routeStackedAppColumnBack(appColumnId: number) {
        const appColumn = this.appColumnsService.getById(appColumnId);
        const backUrl = appColumn.getTopMostStackedAppColumn().getBackUrl();
        this.appColumnsService.removeStackAppColumnLastUrlChange(appColumnId);
        if (backUrl) {
            this.routeStackedAppColumnToUrl(appColumnId, backUrl);
        } else {
            this.closeStackedAppColumn(appColumnId);
        }
    }

    // TODO: Remove the moveColumnAndSetSelected parameter when Order Manager is no longer used in the app.
    navigate(url: string, moveColumnAndSetSelected = true) {
        this.navigateToUrl(url, moveColumnAndSetSelected);
    }

    navigateAndStack(appColumnId: number, url: string, isModal = false) {
        this.appColumnsService.addStackedAppColumn(appColumnId, url, isModal);
    }

    setSelected(appColumnId: number) {
        const appColumn = this.appColumnsService.getById(appColumnId);
        if (!appColumn.isSelected) {
            this.appColumnsService.setSelected(appColumn);
            if (appColumn.stackedAppColumns.length > 0) {
                this.routeStackedAppColumnToUrl(appColumnId);
            } else {
                this.routeAppColumnToUrl(appColumnId);
            }
        }
    }

    setColumnSize(appColumn: AppColumn, size: ColumnSize) {
        this.appColumnsService.setAppColumnSize(appColumn.id, size);
        this.location.go(appColumn.url);
    }

    move(appColumnIdToMove: number, targetAppColumnId: number) {
        const appColumn = this.appColumnsService.getById(appColumnIdToMove);
        this.appColumnsService.move(appColumnIdToMove, targetAppColumnId);
        this.location.go(appColumn.url);
    }

    closeAppColumn(appColumnId: number) {
        const appColumns = this.appColumnsService.getAll();
        const appColumnIndex = appColumns.findIndex((ac) => ac.id === appColumnId);

        if (appColumns[appColumnIndex].isOnStage) {
            if (appColumns.length > 1) {
                const id = appColumnIndex > 0
                    ? appColumns[appColumnIndex - 1].id
                    : appColumns[appColumnIndex + 1].id;
                this.setSelected(id);
            } else {
                this.location.go('/');
            }
        }

        this.appColumnsService.remove(appColumnId);
    }

    closeStackedAppColumn(appColumnId: number) {
        const appColumn = this.appColumnsService.getById(appColumnId);
        this.appColumnsService.removeStackedAppColumn(appColumnId);
        const url = appColumn.stackedAppColumns.length > 0
            ? appColumn.getTopMostStackedAppColumn().url
            : appColumn.url;
        this.location.go(url);
    }

    restoreAppsFromHistory(appColumns: AppColumn[]) {
        const apps = { before: [...this.appColumnsService.getAll()], after: appColumns };
        this.appStateService.restoreAppState(appColumns);

        if (apps.before.length === apps.after.length) {
            const urlChanged = this.urlDiff(apps.before, apps.after);
            if (urlChanged) {
                this.routeAppColumnToUrl(urlChanged.id, null, true);
            }

            const urlStackChanged = this.urlStackDiff(apps.before, apps.after);
            if (urlStackChanged) {
                this.routeStackedAppColumnToUrl(urlStackChanged.id, urlStackChanged.url, true);
            }
        }
    }

    private urlDiff(before: AppColumn[], after: AppColumn[]) {
        const change = after.find((value, index) => value.url !== before[index].url);
        if (change) {
            return { id: change.id, url: change.url };
        }
        return null;
    }

    private navigateToLocation() {
        const currentUrl = this.location.path();
        if (this.appColumnRouterModalMatcherService.urlHasModalDSL(currentUrl)) {
            this.openBaseColumnWithModal(currentUrl);
        } else if (!this.isSelectedAppColumn(currentUrl)) {
            this.navigate(currentUrl);
        }
    }

    // TODO: Remove the moveColumnAndSetSelected parameter when Order Manager is no longer used in the app,
    // leave only the logic for where moveColumnAndSetSelected is true.
    private navigateToUrl(url: string, moveColumnAndSetSelected = true): void {
        const routeGroupingKey = this.routeGroupingService.getRouteGroupingKeyForUrl(url);
        const appColumn = routeGroupingKey === null ? undefined : this.appColumnsService.getByRouteGroupingKey(routeGroupingKey);
        if (appColumn === undefined) { // need to add new appColumn
            // TODO: Display dashboard
            if (url !== '') {
                this.appColumnsService.add(url, routeGroupingKey);
            }
        } else {
            if (moveColumnAndSetSelected) {
                if (!appColumn.isOnStage) {
                    // TODO: Commented out per DEV-4535 app column changes.
                    // this.appColumnsService.moveAppColumnOnStage(appColumn);
                    this.appColumnsService.slideToAppColumn(appColumn);
                } else {
                    this.appColumnsService.setSelected(appColumn);
                }
                this.routeAppColumnToUrl(appColumn.id, url, false);
            } else {
                this.routeAppColumnToUrl(appColumn.id, url, !appColumn.isSelected);
            }
        }
    }

    private urlStackDiff(before: AppColumn[], after: AppColumn[]) {
        const topUrl = (appColumn: AppColumn) => {
            return appColumn.stackedAppColumns[appColumn.stackedAppColumns.length - 1].url;
        };
        const change = after.find((value, index) => value.stackedAppColumns.length > 0 && topUrl(value) !== topUrl(before[index]));
        if (change) {
            return { id: change.id, url: topUrl(change) };
        }
        return null;
    }

    private openBaseColumnWithModal(currentUrl: string) {
        const baseUrl = this.appColumnRouterModalMatcherService.getBaseUrl(currentUrl);
        this.navigate(baseUrl);
        const routeGroupingKey = this.routeGroupingService.getRouteGroupingKeyForUrl(baseUrl);
        const baseAppColumn = routeGroupingKey === null ? undefined : this.appColumnsService.getByRouteGroupingKey(routeGroupingKey);
        if (baseAppColumn && !baseAppColumn.stackedAppColumns.some((appColumn: StackedAppColumn) => appColumn.url === currentUrl)) {
            this.navigateAndStack(baseAppColumn.id, currentUrl, true);
        }
    }

    private isSelectedAppColumn(url: string) {
        let isMatchUrl = false;
        const appColumn = this.appColumnsService.getAll().find(value => value.isSelected);
        if (appColumn) {
            const topAppColumn = appColumn.getTopMostStackedAppColumn();
            isMatchUrl = url === (topAppColumn ? topAppColumn.url : appColumn.url);
        }

        return isMatchUrl;
    }
}
