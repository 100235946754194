import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ScopedOverlay } from './scoped-overlay';

@Directive({
    selector: '[soScopedOverlayContainer]'
})
export class ScopedOverlayContainerDirective implements OnInit, OnChanges {

    @Input() alternateContainerElement: HTMLElement;

    constructor(private overlay: ScopedOverlay,
                private renderer: Renderer2,
                public elementRef: ElementRef) {
    }

    ngOnInit() {
        this.setContainers();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.alternateContainerElement) {
            this.setContainers();
        }
    }

    private setContainers() {
        const container = this.elementRef.nativeElement;
        this.overlay.setContainerElement(container);

        if (this.alternateContainerElement) {
            this.overlay.setAlternateContainerElement(this.alternateContainerElement);
        }

        this.renderer.setStyle(container, 'position', 'relative');
        this.renderer.setStyle(container, 'display', 'block');
    }
}
