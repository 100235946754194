// offsetInSeconds: sets the offset from GMT (Greenwich Mean Time) otherwise known as "Zulu time".
// observeDst: whether the pipe will observe daylight savings time.
export function formatLocalUTC(value: number | string, offsetInSeconds = 0): Date {
    if (typeof value !== 'number') {
        value = Date.parse(value);
    }
    if (isNaN(value)) {
        throw new Error(`formatLocalUTC: Illegal date`);
    }

    return new Date(value + (new Date().getTimezoneOffset() * 60000) + (offsetInSeconds * 1000));
}
