import { Directive, HostBinding, Input } from '@angular/core';

/**
 * Applies component css class bindings for things like layout and state classes
 * to the cssClassBinder property and adds the class to a components host's
 * classList. This provides us access to a component's css class binding without
 * needing to look at that component's classes individually.
 *
 * e.g.
 * <oc-address cssClassBinder="adrAddress__layout--02"></oc-address>
 */
@Directive()
export class CssClassBinderDirective {
    cssClassBinder = '';
    @HostBinding('class') private classes = '';

    // Keep track of existing classes to prevent overriding when no css class binding is provided
    private existingClasses = '';

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('class')
    set existing(existingClasses: string) {
        // NOTE: this method is not triggered when this.renderer.addClass() is called, meaning
        // that renderer classes will not be added to the host
        this.existingClasses = existingClasses;
        this.classes = `${this.existingClasses} ${this.cssClassBinder}`;
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('cssClassBinder')
    set bindings(classBindings: string) {
        this.cssClassBinder = classBindings;
        this.classes = `${this.existingClasses} ${this.cssClassBinder}`;
    }
}
