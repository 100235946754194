import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TotalSummaryComponent, TotalSummaryHeaderDirective } from './total-summary.component';
import { TotalSummaryLabelComponent } from './total-summary-label/total-summary-label.component';
import { TotalSummaryValueComponent } from './total-summary-value/total-summary-value.component';
import { Header01Module } from '@center-suite/shared/ui/header';

export { TotalSummaryValueComponent } from './total-summary-value/total-summary-value.component';

@NgModule({
    imports: [
        CommonModule,
        Header01Module
    ],
    declarations: [
        TotalSummaryComponent,
        TotalSummaryLabelComponent,
        TotalSummaryValueComponent,
        TotalSummaryHeaderDirective
    ],
    exports: [
        TotalSummaryComponent,
        TotalSummaryLabelComponent,
        TotalSummaryValueComponent,
        TotalSummaryHeaderDirective
    ]
})
export class TotalSummaryModule {
}
