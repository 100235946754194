import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppColumnFactory } from '../app-column.factory';
import { AppState } from '../../state/state-management';
import { SET_APP_COLUMN_TITLE_AND_AVATAR } from '../../state/state-management';
import { Avatar } from '@center-suite/shared/utility/common';

@Injectable()
export class AppColumnTitleAndAvatarService {
    title = new EventEmitter<string>();

    constructor(
        private appColumnFactory: AppColumnFactory,
        private store: Store<AppState>) {}

    setAppColumnTitleAndAvatar(title: string, secondaryTitle?: string, avatar?: Avatar) {
        this.title.emit(title);
        this.store.dispatch({
            type: SET_APP_COLUMN_TITLE_AND_AVATAR,
            payload: {
                appColumnId: this.appColumnFactory.appColumnId,
                title,
                secondaryTitle,
                avatar
            }
        });
    }
}
