import { actionRunner } from '../action-runner';
import {
    ADD_APP_COLUMN,
    ADD_APP_COLUMN_URL_CHANGE,
    ADD_STACKED_APP_COLUMN,
    ADD_STACKED_APP_COLUMN_URL_CHANGE,
    MOVE_APP_COLUMN,
    REMOVE_APP_COLUMN,
    REMOVE_STACKED_APP_COLUMN,
    REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE,
    RESIZE_APP_COLUMN,
    RESTORE_APP,
    SELECT_APP_COLUMN,
    SET_REPLACEMENT_APP_COLUMN, SLIDE_TO_APP_COLUMN,
    UNSET_REPLACEMENT_APP_COLUMN
} from '../actions';
import { AppColumn } from '../../../app-column-router/app-column';
import { addAppColumn } from './app-columns/add-app-column';
import { addStackedAppColumn } from './app-columns/add-stacked-app-column';
import { appColumnUrlChange } from './app-columns/app-column-url-change';
import { moveAppColumn } from './app-columns/move-app-column';
import { removeStackedAppColumn } from './app-columns/remove-stacked-app-column';
import { selectAppColumn } from './app-columns/select-app-column';
import { updateColumnSize } from './app-columns/update-column-size';
import {
    RemoveAppColumnAction,
    RestoreAppAction,
    SetReplacementAppColumnAction
} from '../action-types';
import { slideToAppColumn } from './app-columns/slide-to-app-column';

export const appColumns = actionRunner<AppColumn[]>({
    [ADD_APP_COLUMN]: addAppColumn,
    [REMOVE_APP_COLUMN]: (columns: AppColumn[], action: RemoveAppColumnAction) =>
      columns.filter(appColumn => appColumn.id !== action.payload.appColumnId),
    [SELECT_APP_COLUMN]: selectAppColumn,
    [RESIZE_APP_COLUMN]: updateColumnSize,
    [MOVE_APP_COLUMN]: moveAppColumn,
    [SLIDE_TO_APP_COLUMN]: slideToAppColumn,
    [ADD_STACKED_APP_COLUMN]: addStackedAppColumn,
    [REMOVE_STACKED_APP_COLUMN]: removeStackedAppColumn,
    [ADD_APP_COLUMN_URL_CHANGE]: appColumnUrlChange,
    [ADD_STACKED_APP_COLUMN_URL_CHANGE]: appColumnUrlChange,
    [REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE]: appColumnUrlChange,
    [RESTORE_APP]: (columns: AppColumn[], action: RestoreAppAction) => action.payload.appColumns,
    [SET_REPLACEMENT_APP_COLUMN]: (columns: AppColumn[], action: SetReplacementAppColumnAction) => {
        return columns.map(appColumn => {
            if (appColumn.id === action.payload.appColumnId) {
                return Object.assign(appColumn.copy(), { isReplacementColumn: true });
            }
            return appColumn;
        });
    },
    [UNSET_REPLACEMENT_APP_COLUMN]: (columns: AppColumn[]) => {
        return columns.map(appColumn => {
            if (appColumn.isReplacementColumn) {
                return Object.assign(appColumn.copy(), { isReplacementColumn: false });
            }
            return appColumn;
        });
    }
}, []);
