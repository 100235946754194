import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';

import { AppColumnRouterService } from '../../services/app-column-router.service';
import { AppColumnRouterLinkOriginService } from './app-column-router-link-origin.service';
import { WindowDetails, WindowManagerService } from '@center-suite/shared/utility/common';
import { Subscription } from 'rxjs';
import { AppColumnRouterLinkType } from '../../enums/app-column-router-link-type';

@Directive({
    selector: '[acfAppColumnRouterLink]'
})
export class AppColumnRouterLinkDirective implements OnDestroy{
    @HostBinding('attr.href') visibleHref: string;
    @HostBinding('attr.target') target: string;
    @Input() set acfAppColumnRouterLink(path: string) {
        this.visibleHref = path;
        this.routePath = path;
    }
    @Input() linkType: AppColumnRouterLinkType;

    private routePath: string;
    private subscription?: Subscription;

    constructor(
        private appColumnRouterService: AppColumnRouterService,
        private appColumnRouterLinkOriginService: AppColumnRouterLinkOriginService,
        private elementRef: ElementRef,
        private windowOpenerService: WindowManagerService) {}


    @HostListener('click')
    onClick(): boolean {
        if (this.routePath) {
            if (this.linkType === AppColumnRouterLinkType.POPUP_WINDOW) {
                this.subscription?.unsubscribe();

                const windowName = 'ToolWindow';
                const windowDetails: WindowDetails = {
                    width: 900,
                    height: 650,
                    top: 100,
                    left: 100
                }

                this.subscription = this.windowOpenerService.openWindow(this.routePath, windowName, windowDetails)
                    .subscribe(e => {
                        this.navigate('/accounts/' + e.data);
                    });
                return false;
            } else if (this.linkType === AppColumnRouterLinkType.INTERNAL_LINK) {
                return this.navigate(this.routePath);
            } else if (this.linkType === AppColumnRouterLinkType.EXTERNAL_LINK) {
                this.target = '_blank';
            } else {
                return this.navigate(this.routePath);
            }
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private navigate(path: string) {
        this.appColumnRouterService.navigate(path);
        const appColumnRouterLinkOrigin = this.elementRef.nativeElement.getBoundingClientRect();
        this.appColumnRouterLinkOriginService.emitNewOrigin(appColumnRouterLinkOrigin);
        return false;
    }
}
