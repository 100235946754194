import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { REDUCERS_TOKEN, STATE_MANAGEMENT_PROVIDERS } from './state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(REDUCERS_TOKEN)
  ],
  providers: [
    STATE_MANAGEMENT_PROVIDERS,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ]
})
export class AppColumnFrameworkModule {}
