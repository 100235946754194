import { Injectable } from '@angular/core';

import { AppColumnsService } from './services/app-columns.service';

@Injectable()
export class AppColumnFactory {
    public appColumnId: number;

    constructor(private appColumnService: AppColumnsService) {}

    setAppColumnId(id: number) {
        this.appColumnId = id;
    }

    getAppColumn() {
        return this.appColumnService.getById(this.appColumnId);
    }
}
