import { EnumAdapter } from '@center-suite/shared/data-access/utility';
import { Injectable } from '@angular/core';
import { AccountAccess } from '@center-suite/shared/utility/authorization';

@Injectable({
    providedIn: 'root'
})
export class AccountAccessEnumAdapter implements EnumAdapter<string, AccountAccess> {
    apiEnumToViewEnum(apiModel: string): AccountAccess {
        switch (apiModel) {
            case 'AccountViewer':
                return AccountAccess.VIEWER;
            case 'AccountOwner':
                return AccountAccess.PRIMARY_OWNER;
            case 'NotificationsOnly':
                return AccountAccess.NOTIFICATION_ONLY;
            default:
                return null;
        }
    }

    viewEnumToApiEnum(viewModel: AccountAccess): string {
        switch (viewModel) {
            case AccountAccess.VIEWER:
                return 'AccountViewer';
            case AccountAccess.PRIMARY_OWNER:
                return 'AccountOwner';
            case AccountAccess.NOTIFICATION_ONLY:
                return 'NotificationsOnly';
            default:
                return null;
        }
    }
}
