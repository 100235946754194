/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BillToInvoicePaymentTermDaysApiEnum = 'DueUponReceipt' | 'FiveDays' | 'TenDays' | 'FifteenDays' | 'ThirtyDays' | 'FortyFiveDays' | 'SixtyDays' | 'NinetyDays';

export const BillToInvoicePaymentTermDaysApiEnum = {
    DueUponReceipt: 'DueUponReceipt' as BillToInvoicePaymentTermDaysApiEnum,
    FiveDays: 'FiveDays' as BillToInvoicePaymentTermDaysApiEnum,
    TenDays: 'TenDays' as BillToInvoicePaymentTermDaysApiEnum,
    FifteenDays: 'FifteenDays' as BillToInvoicePaymentTermDaysApiEnum,
    ThirtyDays: 'ThirtyDays' as BillToInvoicePaymentTermDaysApiEnum,
    FortyFiveDays: 'FortyFiveDays' as BillToInvoicePaymentTermDaysApiEnum,
    SixtyDays: 'SixtyDays' as BillToInvoicePaymentTermDaysApiEnum,
    NinetyDays: 'NinetyDays' as BillToInvoicePaymentTermDaysApiEnum
};

