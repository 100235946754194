export function getTimezoneOffsetInSeconds(timezoneOffset: string): number {
    const isNegative = timezoneOffset.startsWith('-');
    const timePieces = timezoneOffset.split(':');
    if (timePieces.length !== 3) {
        throw new Error('getTimezoneOffsetInSeconds: Invalid timezoneOffset format');
    }
    const hours = parseInt(isNegative ? timePieces[0].slice(1) : timePieces[0], 10);
    const minutes = parseInt(timePieces[1], 10);
    const seconds = parseInt(timePieces[2], 10);

    return (hours * 3600 + minutes * 60 + seconds) * (isNegative ? -1 : 1)
}
