import { AccountAccess } from '../account-accesss.type';

export interface CurrentProfile {
    readonly profileId: number;
    readonly name: string;
    readonly avatarUrl?: string;
    readonly timeZoneOffsetInSeconds: number;
    readonly accessContexts: Array<AccessContext>;
}

export interface AccessContext {
    accessContextId: number;
    tenantId: number;
    name: string;
    levelOfAccess: AccountAccess;
    accessPolicies: Array<string>;
    dataAccessType: DataAccessType
}

export enum DataAccessType {
    ACCOUNT = 'Account',
    TENANT = 'Tenant',
    ALL_TENANTS = 'AllTenants',
    PROFILE = 'Profile',
    ANONYMOUS = 'Anonymous'
}
