import { Component, ElementRef, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[hdrHeader01]',
    templateUrl: './header-01.component.html',
    styleUrls: ['./header-01.component.scss']
})
export class Header01Component {
    @Input() headerId: number | string;

    constructor(private elementRef: ElementRef) {}

    // needed in modal-container component
    get nativeElement() {
        return this.elementRef.nativeElement;
    }
}
