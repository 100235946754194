import { Injectable } from '@angular/core';
import { BATCH, SET_REPLACEMENT_APP_COLUMN, SHOW_MAX_COLUMN_WARNING, UNSET_REPLACEMENT_APP_COLUMN } from '../actions';
import { AppColumnAction, BatchAction } from '../action-types';

@Injectable({
    providedIn: 'root'
})
export class ShowMaxColumnsWarningCreator {
    show(oldestAccessedAppColumnId: number): BatchAction {
        const actions: AppColumnAction[] = [
            {
                type: SHOW_MAX_COLUMN_WARNING,
                payload: true
            },
            {
                type: SET_REPLACEMENT_APP_COLUMN,
                payload: { appColumnId: oldestAccessedAppColumnId }
            }
        ];

        return {
            type: BATCH, payload: actions
        };
    }

    hide(): BatchAction {
        const actions: AppColumnAction[] = [
            {
                type: SHOW_MAX_COLUMN_WARNING,
                payload: false
            },
            {
                type: UNSET_REPLACEMENT_APP_COLUMN,
                payload: null
            }
        ];

        return {
            type: BATCH, payload: actions
        };
    }
}
