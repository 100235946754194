import { AppColumn, StackedAppColumn } from '../../../../app-column-router/app-column';
import { AddStackedAppColumnAction } from '../../action-types';

export const addStackedAppColumn = (appColumns: AppColumn[], action: AddStackedAppColumnAction) => {
    return appColumns.map(appColumn => {
        if (appColumn.id === action.payload.appColumnId) {
            const newAppColumn = appColumn.copy();
            const stackedAppColumn = new StackedAppColumn(action.payload.url);
            newAppColumn.stackedAppColumns = [...appColumn.stackedAppColumns, stackedAppColumn];
            return newAppColumn;
        }
        return appColumn;
    });
};
