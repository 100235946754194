import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskStatusMessageComponent } from './task-status-message.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [TaskStatusMessageComponent],
    exports: [TaskStatusMessageComponent]
})
export class TaskStatusMessageModule {}
