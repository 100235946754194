import { Directive, HostListener, Input } from '@angular/core';
import { WindowManagerService } from './window-manager.service';

@Directive({
    selector: '[cmnWindowOpenerMessageMonitor]'
})
export class WindowManagerMessageMonitorDirective {
    @Input() url: string;

    constructor(
        private windowOpenerService: WindowManagerService) {
    }

    @HostListener('window:message', ['$event']) onReceivedMessage(event: MessageEvent) {
        if (event.origin === this.url) {
            this.windowOpenerService.onReceivedMessage(event);
        }
    }
}
