import { actionRunner } from '../action-runner';
import { REMOVE_APP_COLUMN, SET_APP_COLUMN_TITLE_AND_AVATAR } from '../actions';
import { AppColumnTitlesAndAvatars } from '../state';
import { RemoveAppColumnAction, SetAppColumnTitleAndAvatarAction } from '../action-types';

export const appColumnsTitlesAndAvatars = actionRunner<AppColumnTitlesAndAvatars>({
    [SET_APP_COLUMN_TITLE_AND_AVATAR]: (appColumnTitlesAndAvatars: AppColumnTitlesAndAvatars, action: SetAppColumnTitleAndAvatarAction) => {
        const appColumnTitlesAndAvatarsCopy = Object.assign({}, appColumnTitlesAndAvatars);
        appColumnTitlesAndAvatarsCopy[action.payload.appColumnId] = {
            title: action.payload.title,
            secondaryTitle: action.payload.secondaryTitle,
            avatar: action.payload.avatar
        };
        return appColumnTitlesAndAvatarsCopy;
    },
    [REMOVE_APP_COLUMN]: (appColumnTitlesAndAvatars: AppColumnTitlesAndAvatars, action: RemoveAppColumnAction) => {
        if (appColumnTitlesAndAvatars[action.payload.appColumnId]) {
            const appColumnTitlesAndAvatarsCopy = Object.assign({}, appColumnTitlesAndAvatars);
            delete appColumnTitlesAndAvatarsCopy[action.payload.appColumnId];
            return appColumnTitlesAndAvatarsCopy;
        }
        return appColumnTitlesAndAvatars;
    }
}, {});
