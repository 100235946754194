/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSummaryApiModel } from './accountSummaryApiModel';


export interface ProfileSummaryApiModel { 
    profileId: number;
    name: string;
    phoneNumber?: string | null;
    emailAddress?: string | null;
    avatarUrl?: string | null;
    primaryOwnedAccount?: AccountSummaryApiModel;
}

