/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditCardProcessingTermsApiEnum } from './creditCardProcessingTermsApiEnum';
import { CreditCardTypeApiEnum } from './creditCardTypeApiEnum';
import { PaymentCollectionTypeApiEnum } from './paymentCollectionTypeApiEnum';


export interface BillToCreditCardApiModel { 
    creditCardLastFourDigits: string;
    creditCardExpirationDate: string;
    creditCardType: CreditCardTypeApiEnum;
    paymentCollectionType: PaymentCollectionTypeApiEnum;
    processingTerms: CreditCardProcessingTermsApiEnum;
    daysInAdvanceToProcess?: number | null;
}

