import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    TaskStatusIconMdBodyDirective,
    TaskStatusIconMdComponent,
    TaskStatusIconMdMediaDirective,
    TaskStatusIconMdSubjectDirective
} from './task-status-icon-md.component';
import { TaskStatusMessageModule } from '../shared/task-status-message/task-status-message.module';
import { Header02Module } from '@center-suite/shared/ui/header';

@NgModule({
    imports: [
        CommonModule,
        TaskStatusMessageModule,
        Header02Module
    ],
    declarations: [
        TaskStatusIconMdComponent,
        TaskStatusIconMdMediaDirective,
        TaskStatusIconMdSubjectDirective,
        TaskStatusIconMdBodyDirective
    ],
    exports: [
        TaskStatusIconMdComponent,
        TaskStatusIconMdMediaDirective,
        TaskStatusIconMdSubjectDirective,
        TaskStatusIconMdBodyDirective
    ]
})
export class TaskStatusIconMdModule {
}
