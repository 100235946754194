import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkIconComponent } from './link-icon.component';

export { LinkIconComponent } from './link-icon.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LinkIconComponent
    ],
    exports: [
        LinkIconComponent
    ]
})
export class LinkIconModule {}
