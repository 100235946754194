import { animate, style, transition, trigger } from '@angular/animations';
import { ANIMATION_DURATION_MEDIUM } from './constants/animation-constants';

//
// To use this animation, you will need to pass it something you expect to change
// [@fadeOnChange]="{form.get('formControlName').value}"
//

export const fadeOnChangeAnimation = trigger('fadeOnChange', [
    transition('* => void', [style({ opacity: '0' })]),
    transition('* => *', [
        style({ opacity: '0' }),
        animate(ANIMATION_DURATION_MEDIUM, style({ opacity: '1' })),
    ]),
]);
