import { ActionReducer } from '@ngrx/store';
import {
    ADJUST_SIZING_AND_OFFSTAGE_STATUS,
    MOVE_APP_COLUMN_ONSTAGE,
    REMOVE_APP_COLUMN,
    RESIZE_APP_COLUMN,
    RESTORE_APP
} from '../actions';
import { AppColumn } from '../../../app-column-router/app-column';
import { metaActionRunner } from '../meta-action-runner';
import {
    AdjustColumnSizingAction,
    MoveAppColumnOnstageAction,
    RemoveAppColumnAction,
    RestoreAppAction,
    UpdateColumnSizeAction
} from '../action-types';

// TODO: Commented out per DEV-4535 app column changes.
// // possible column sizes for the possible column settings (small or dynamic width)
// const possibleColumnSizes: {[key in ColumnSize]: AutoColumnSize[]} = {
//     [ColumnSize.Dynamic]: [AutoColumnSize.Large, AutoColumnSize.Medium, AutoColumnSize.Small],
//     [ColumnSize.Small]: [AutoColumnSize.Small]
// };

// TODO: Commented out per DEV-4535 app column changes.
// // fixed width values for each column size
// const columnWidths: {[key in AutoColumnSize]: number} = {
//     // TODO: Refactor this so that it can be all three sizes for tenants other than Equine Network
//     // [AutoColumnSize.Small]: 366,
//     // [AutoColumnSize.Medium]: 636,
//     [AutoColumnSize.Small]: 984,
//     [AutoColumnSize.Medium]: 984,
//     [AutoColumnSize.Large]: 984
// };

const minViewportWidth = 756;
const navigationWidth = 89;

export const sizeAndSetOffStageStatus = (reducer: ActionReducer<AppColumn[]>): ActionReducer<AppColumn[]> => {
    type InnerReducerAction = RemoveAppColumnAction
        | UpdateColumnSizeAction
        | MoveAppColumnOnstageAction
        | AdjustColumnSizingAction
        | RestoreAppAction;

    const innerReducer = (state: AppColumn[], action: InnerReducerAction) => {
        const nextState: AppColumn[] = reducer(state, action)
            .map(appColumn => appColumn.copy());

        const viewportWidth = action.payload.viewportWidth;
        const navWidth = viewportWidth <= minViewportWidth ? 0 : navigationWidth;
        const stageWidth = viewportWidth - navWidth;

        let usedWidth = 0;
        // TODO: Commented out per DEV-4535 app column changes, replaced with logic below.
        // let isFirstColumn = true;
        // for (const appColumn of nextState) {
        //     appColumn.isOnStage = false;
        //
        //     // Default the first column to show in case stageWidth is very small
        //     if (isFirstColumn) {
        //         appColumn.autoSize = AutoColumnSize.Small;
        //         appColumn.isOnStage = true;
        //         isFirstColumn = false;
        //     }
        //
        //     for (const autoSize of possibleColumnSizes[appColumn.size]) {
        //         const autoBaseWidth = columnWidths[autoSize];
        //         if (usedWidth + autoBaseWidth <= stageWidth) {
        //             appColumn.autoSize = autoSize;
        //             appColumn.isOnStage = true;
        //             usedWidth += autoBaseWidth;
        //             break;
        //         }
        //     }
        // }
        //
        // return nextState;

        const columnWidth = 984;

        // Find the first app column on the stage.
        let firstColumnOnStageIndex = nextState.findIndex(appColumn => appColumn.isOnStage);
        // If there are no columns on stage but there are app columns, default to the first column.
        if (firstColumnOnStageIndex === -1 && nextState.length > 0) {
            firstColumnOnStageIndex = 0;
        }
        if (firstColumnOnStageIndex >= 0) {
            nextState[firstColumnOnStageIndex].isOnStage = true;
            usedWidth += columnWidth;
        }
        // If there is room for additional columns to the right, add them to the stage.
        for (let i = firstColumnOnStageIndex + 1; i < nextState.length; i++) {
            if (usedWidth + columnWidth > stageWidth) {
                nextState[i].isOnStage = false;
            } else {
                usedWidth += columnWidth;
                nextState[i].isOnStage = true;
            }
        }
        // If there is still room for additional columns to the left, add them to the stage.
        for (let i = firstColumnOnStageIndex - 1; i >= 0; i--) {
            if (usedWidth + columnWidth > stageWidth) {
                nextState[i].isOnStage = false;
            } else {
                usedWidth += columnWidth;
                nextState[i].isOnStage = true;
            }
        }

        return nextState;
    };

    return metaActionRunner<AppColumn[]>({
        [REMOVE_APP_COLUMN]: innerReducer,
        [RESIZE_APP_COLUMN]: innerReducer,
        [MOVE_APP_COLUMN_ONSTAGE]: innerReducer,
        [ADJUST_SIZING_AND_OFFSTAGE_STATUS]: innerReducer,
        [RESTORE_APP]: innerReducer
    }, reducer);
};
