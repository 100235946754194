import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AriaModule, ClickEnterEventModule } from '@center-suite/shared/ui/accessibility';
import { IdModule } from '@center-suite/shared/utility/common';
import { MessageInfoBodyDirective, MessageInfoComponent, MessageInfoSubjectDirective } from './message-info.component';
import { MessageInfoIconComponent } from './message-info-icon/message-info-icon.component';
import { TaskStatusModule } from '@center-suite/shared/ui/task';
import {
    MessageInfoListComponent,
    MessageInfoListItemComponent
} from './message-info-list/message-info-list.component';
import { AvatarModule } from '@center-suite/shared/ui/avatar';

@NgModule({
    imports: [
        CommonModule,
        TaskStatusModule,
        AvatarModule,
        AriaModule,
        ClickEnterEventModule,
        IdModule
    ],
    declarations: [
        MessageInfoComponent,
        MessageInfoSubjectDirective,
        MessageInfoBodyDirective,
        MessageInfoIconComponent,
        MessageInfoListComponent,
        MessageInfoListItemComponent
    ],
    exports: [
        MessageInfoComponent,
        MessageInfoSubjectDirective,
        MessageInfoBodyDirective,
        MessageInfoIconComponent,
        MessageInfoListComponent,
        MessageInfoListItemComponent
    ]
})
export class MessageInfoModule {
}
