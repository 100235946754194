/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CountryCodeApiEnum } from './countryCodeApiEnum';
import { BestTimeToReachApiEnum } from './bestTimeToReachApiEnum';


export interface ProfileApiModel { 
    profileId: number;
    name: string;
    emailAddress?: string | null;
    isEmailUndeliverable: boolean;
    phoneNumber?: string | null;
    isPhoneNumberInvalid: boolean;
    canReceiveText: boolean;
    bestTimeToReach?: BestTimeToReachApiEnum;
    avatarUrl?: string | null;
    countryCode: CountryCodeApiEnum;
    timeZoneIdentifier: string;
    hasPin: boolean;
    dateCreated: string;
}

