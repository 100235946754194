import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { sanitizeHTML } from './sanitize-html';

@Directive({
    selector: '[cmnSanitizeHtml]'
})
export class SanitizeHtmlDirective implements OnChanges {
    @HostBinding('innerHTML') innerHtml: string;
    private html: string;

    @Input() set cmnSanitizeHtml(html: string) {
        this.html = html;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['cmnSanitizeHtml'].currentValue !== changes['cmnSanitizeHtml'].previousValue) {
            this.html = changes['cmnSanitizeHtml'].currentValue;
            this.innerHtml = sanitizeHTML(this.html);
        }
    }
}
