import { Component } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[comTotalSummaryValue]',
    templateUrl: 'total-summary-value.component.html',
    styleUrls: ['total-summary-value.component.scss']
})
export class TotalSummaryValueComponent {
}
