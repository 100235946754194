import {
    Component,
    Directive,
    Input,
    TemplateRef,
    ViewChild
} from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: 'com-step-pane-tab'})
export class StepPaneTabDirective {
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'com-step-pane-content' })
export class StepPaneContentDirective {
}

@Component({
  selector: 'com-step-pane',
  templateUrl: './step-pane.component.html',
  styleUrls: ['./step-pane.component.scss']
})
export class StepPaneComponent {
    @ViewChild('tab') tab: TemplateRef<StepPaneTabDirective>;
    @ViewChild('content') content: TemplateRef<StepPaneContentDirective>;
    @Input() isComplete: boolean;
}
