export * from './accountTypes.service';
import { AccountTypesService } from './accountTypes.service';
export * from './accounts.service';
import { AccountsService } from './accounts.service';
export * from './addresses.service';
import { AddressesService } from './addresses.service';
export * from './billTos.service';
import { BillTosService } from './billTos.service';
export * from './contacts.service';
import { ContactsService } from './contacts.service';
export * from './financialOverviews.service';
import { FinancialOverviewsService } from './financialOverviews.service';
export * from './notes.service';
import { NotesService } from './notes.service';
export * from './profiles.service';
import { ProfilesService } from './profiles.service';
export * from './shipTos.service';
import { ShipTosService } from './shipTos.service';
export * from './telligentUser.service';
import { TelligentUserService } from './telligentUser.service';
export const APIS = [AccountTypesService, AccountsService, AddressesService, BillTosService, ContactsService, FinancialOverviewsService, NotesService, ProfilesService, ShipTosService, TelligentUserService];
