import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatedTextComponent } from './truncated-text.component';
import { AriaModule } from '@center-suite/shared/ui/accessibility';
import { ToggleLayoutModule } from '../toggle-layout/toggle-layout.module';
import { ActionIconModule } from '../action-icon/action-icon.module';

@NgModule({
    imports: [
        CommonModule,
        AriaModule,
        ToggleLayoutModule,
        ActionIconModule
    ],
    declarations: [TruncatedTextComponent],
    exports: [TruncatedTextComponent]
})
export class TruncatedTextModule {}
