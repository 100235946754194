import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { DEFAULT_TIMING } from '../constants/animation-constants';

// To use this animation, you'll need to place the "expandListShowHideItem" class on the list items
// You will also need to add the "expandListShowHideItem--collapsed" class to the list items when the list is collapsed.
// You'll need a function that sets a property to true when the animation is done that you can use to add the class
// only when the animation is complete
//
// <ul
//     [@expandListShowHide]="animationState"
//     (@expandListShowHide.done)="onListShowHideDone($event)">
//     <li
//         *ngFor="let item of items"
//         [class.expandListShowHideItem--collapsed]="animationDone && animationState === 'collapsed'"
//         class="expandListShowHideItem">
//     </li>
// </ul>

// An example animation done function, which sets a property we called "animationDone". You will also need to set this
// property where you handle your animation trigger
//
// onListShowHideDone(event: AnimationEvent) {
//     if (event.fromState === "expanded") {
//         this.animationDone = true;
//     }
// }

export const expandListShowHideAnimation = trigger('expandListShowHide', [
    transition('collapsed => expanded', [
        query('.expandListShowHideItem', [
            style({
                height: 0,
                opacity: 0,
                transform: 'scaleY(0)'
            }),
            stagger(100, [
                animate(
                    DEFAULT_TIMING,
                    style({
                        height: '*',
                        opacity: 1,
                        transform: 'scaleY(1)'
                    })
                )
            ])
        ], { optional: true })
    ]),
    transition('expanded => collapsed', [
        query('.expandListShowHideItem', [
            stagger(100, [
                animate(
                    DEFAULT_TIMING,
                    style({
                        height: 0,
                        opacity: 0,
                        transform: 'scaleY(0)'
                    })
                )
            ])
        ], { optional: true })
    ])
]);
