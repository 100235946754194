/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Injector, Optional, Type }      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse,
         HttpEvent, HttpParameterCodec, HttpErrorResponse }  from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable, of, throwError }                        from 'rxjs';
import { map, retryWhen, catchError }                        from 'rxjs/operators';

import { AddUpdateBillToRequest } from '../models/models';
import { BadRequestResponse } from '../models/models';
import { BillToApiModel } from '../models/models';
import { BillToSearchPageResponseApiModel } from '../models/models';
import { BillingMethodApiEnum } from '../models/models';
import { InternalServerErrorResponse } from '../models/models';
import { UnauthorizedResponse } from '../models/models';

import { AppColumnFactory }                                  from '@center-suite/shared/framework/app-column-framework';
import { Adapter, APP_COLUMN_ID, httpRequestRetryStrategy }  from '@center-suite/shared/data-access/utility';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AddBillToRequestParams {
    accountId: string | null;
    addUpdateBillToRequest?: AddUpdateBillToRequest;
}

export interface DeleteBillToRequestParams {
    billToId: number;
}

export interface GetAllowedBillingMethodsRequestParams {
    accountId: string | null;
}

export interface GetBillToByIdRequestParams {
    id: number;
}

export interface SearchBillTosRequestParams {
    accountId: string | null;
    sortBy: 'IsDefault_Desc,IsPreventingFulfillment_Desc,DateLastModified_Desc' | 'IsDefault_Desc,IsPreventingFulfillment_Desc,DateCreated_Desc' | 'Score_Desc';
    searchQuery?: string | null;
    after?: string;
    before?: string;
    pageSize?: number;
}

export interface UpdateBillToRequestParams {
    billToId: number;
    addUpdateBillToRequest?: AddUpdateBillToRequest;
}


@Injectable({
  providedIn: 'root'
})
export class BillTosService {

    protected basePath = 'https://localhost:10000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private injector: Injector, protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration, @Optional() private appColumnFactory: AppColumnFactory) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: AddBillToRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public addBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: AddBillToRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public addBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: AddBillToRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public addBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: AddBillToRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const accountId = requestParameters.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addBillTo.');
        }
        const addUpdateBillToRequest = requestParameters.addUpdateBillToRequest;

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BillToApiModel>(`${this.configuration.basePath}/api/accounts/${encodeURIComponent(String(accountId))}/bill-tos`,
            addUpdateBillToRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('post' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<BillToApiModel> | BillToApiModel | HttpEvent<BillToApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<BillToApiModel>);
                } else {
                    return adapter.adaptItem(res as BillToApiModel);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBillTo(requestParameters: DeleteBillToRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteBillTo(requestParameters: DeleteBillToRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteBillTo(requestParameters: DeleteBillToRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteBillTo(requestParameters: DeleteBillToRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const billToId = requestParameters.billToId;
        if (billToId === null || billToId === undefined) {
            throw new Error('Required parameter billToId was null or undefined when calling deleteBillTo.');
        }


        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/accounts/bill-tos/${encodeURIComponent(String(billToId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllowedBillingMethods<TReturn, TAdapter extends Adapter<Array<BillingMethodApiEnum>, TReturn>>(requestParameters: GetAllowedBillingMethodsRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public getAllowedBillingMethods<TReturn, TAdapter extends Adapter<Array<BillingMethodApiEnum>, TReturn>>(requestParameters: GetAllowedBillingMethodsRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public getAllowedBillingMethods<TReturn, TAdapter extends Adapter<Array<BillingMethodApiEnum>, TReturn>>(requestParameters: GetAllowedBillingMethodsRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public getAllowedBillingMethods<TReturn, TAdapter extends Adapter<Array<BillingMethodApiEnum>, TReturn>>(requestParameters: GetAllowedBillingMethodsRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const accountId = requestParameters.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAllowedBillingMethods.');
        }

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BillingMethodApiEnum>>(`${this.configuration.basePath}/api/accounts/${encodeURIComponent(String(accountId))}/bill-tos/allowed-billing-methods`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<Array<BillingMethodApiEnum>> | Array<BillingMethodApiEnum> | HttpEvent<Array<BillingMethodApiEnum>> | null) => {
                if (res === null) {
                    return [];
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<Array<BillingMethodApiEnum>>);
                } else {
                    return adapter.adaptItem(res as BillingMethodApiEnum[]);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBillToById<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: GetBillToByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public getBillToById<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: GetBillToByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public getBillToById<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: GetBillToByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public getBillToById<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: GetBillToByIdRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBillToById.');
        }

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BillToApiModel>(`${this.configuration.basePath}/api/accounts/bill-tos/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<BillToApiModel> | BillToApiModel | HttpEvent<BillToApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<BillToApiModel>);
                } else {
                    return adapter.adaptItem(res as BillToApiModel);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBillTos<TReturn, TAdapter extends Adapter<BillToSearchPageResponseApiModel, TReturn>>(requestParameters: SearchBillTosRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public searchBillTos<TReturn, TAdapter extends Adapter<BillToSearchPageResponseApiModel, TReturn>>(requestParameters: SearchBillTosRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public searchBillTos<TReturn, TAdapter extends Adapter<BillToSearchPageResponseApiModel, TReturn>>(requestParameters: SearchBillTosRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public searchBillTos<TReturn, TAdapter extends Adapter<BillToSearchPageResponseApiModel, TReturn>>(requestParameters: SearchBillTosRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const accountId = requestParameters.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling searchBillTos.');
        }
        const sortBy = requestParameters.sortBy;
        if (sortBy === null || sortBy === undefined) {
            throw new Error('Required parameter sortBy was null or undefined when calling searchBillTos.');
        }
        const searchQuery = requestParameters.searchQuery;
        const after = requestParameters.after;
        const before = requestParameters.before;
        const pageSize = requestParameters.pageSize;

        const adapter = this.injector.get(tAdapter);

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (searchQuery !== undefined && searchQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchQuery, 'SearchQuery');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'SortBy');
        }
        if (after !== undefined && after !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>after, 'After');
        }
        if (before !== undefined && before !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>before, 'Before');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'PageSize');
        }

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BillToSearchPageResponseApiModel>(`${this.configuration.basePath}/api/accounts/${encodeURIComponent(String(accountId))}/bill-tos`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<BillToSearchPageResponseApiModel> | BillToSearchPageResponseApiModel | HttpEvent<BillToSearchPageResponseApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<BillToSearchPageResponseApiModel>);
                } else {
                    return adapter.adaptItem(res as BillToSearchPageResponseApiModel);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: UpdateBillToRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public updateBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: UpdateBillToRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public updateBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: UpdateBillToRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public updateBillTo<TReturn, TAdapter extends Adapter<BillToApiModel, TReturn>>(requestParameters: UpdateBillToRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const billToId = requestParameters.billToId;
        if (billToId === null || billToId === undefined) {
            throw new Error('Required parameter billToId was null or undefined when calling updateBillTo.');
        }
        const addUpdateBillToRequest = requestParameters.addUpdateBillToRequest;

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<BillToApiModel>(`${this.configuration.basePath}/api/accounts/bill-tos/${encodeURIComponent(String(billToId))}`,
            addUpdateBillToRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('put' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<BillToApiModel> | BillToApiModel | HttpEvent<BillToApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<BillToApiModel>);
                } else {
                    return adapter.adaptItem(res as BillToApiModel);
                }
            })
        );
    }

}
