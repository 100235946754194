import {
    AfterContentInit, AfterViewInit, ChangeDetectorRef,
    Component,
    ContentChild,
    Directive, ElementRef,
    Input,
    OnChanges,
    OnInit,
    ViewChild
} from '@angular/core';
import { MessageInfoIconComponent } from './message-info-icon/message-info-icon.component';
import { CssClassBinderDirective } from '@center-suite/shared/utility/common';
import { Task } from '@center-suite/shared/ui/task';
import { expandHeightShowHideAnimation } from '@center-suite/shared/ui/animation';

interface AriaData {
    [key: string]: string | boolean | null;
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'com-message-info-subject' })
export class MessageInfoSubjectDirective {
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'com-message-info-body' })
export class MessageInfoBodyDirective {
}

@Component({
    selector: 'com-message-info',
    templateUrl: './message-info.component.html',
    styleUrls: ['./message-info.component.scss'],
    animations: [expandHeightShowHideAnimation]
})
export class MessageInfoComponent extends CssClassBinderDirective implements AfterContentInit, AfterViewInit, OnChanges, OnInit {
    @ContentChild(MessageInfoBodyDirective) body: MessageInfoBodyDirective;
    @ContentChild(MessageInfoIconComponent) icon: MessageInfoIconComponent;
    @ViewChild('bodyElement') bodyElement: ElementRef;
    @ViewChild('subjectElement') subjectElement: ElementRef;
    @Input() tasks: Task[];
    includeToggle = true;
    isExpanded = false;
    iconIsAvatar = false;
    container: string;
    animationState = 'collapsed';
    ariaData: AriaData = {};

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.checkState();
    }

    ngOnChanges() {
        this.checkState();
    }

    ngAfterContentInit() {
        this.iconIsAvatar = this.icon.isAvatar;
        this.includeToggle = !!this.body;
    }

    ngAfterViewInit() {
        if (this.bodyElement) {
            this.ariaData = this.updateAriaData(this.ariaData, {
                expanded: 'false',
                controls: this.bodyElement.nativeElement.id,
                labelledby: this.subjectElement.nativeElement.id
            });
        }
    }

    checkState() {
        if (this.cssClassBinder) {
            if (this.cssClassBinder.includes('comMessageInfo__state--02')) {
                this.container = '12';
            } else if (this.cssClassBinder.includes('comMessageInfo__state--03')) {
                this.container = '08';
            } else if (this.cssClassBinder.includes('comMessageInfo__state--04')) {
                this.container = '09';
            } else {
                this.container = '10';
            }
        } else {
            this.container = '03';
        }
    }

    toggleExpandCollapse() {
        this.isExpanded = !this.isExpanded;
        this.animationState = this.isExpanded ? 'expanded': 'collapsed';
        this.ariaData = this.updateAriaData(this.ariaData, { expanded: this.isExpanded.toString() });
    }

    set toggle(value: boolean) {
        this.includeToggle = value;
        this.changeDetectorRef.detectChanges();
    }

    set expand(value: boolean) {
        this.isExpanded = value;
        this.animationState = value ? 'expanded' : 'collapsed';
        this.changeDetectorRef.detectChanges();
    }

    // Override aria data with new values
    private updateAriaData(data: AriaData, newAriaValues: AriaData): AriaData {
        return {
            ...data,
            ...newAriaValues
        };
    }
}
