import { SessionService } from '../base/session.service';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserSessionService extends SessionService {
    setItem(key: string, value: string, replacer: (string | number)[] = null): void {
        if (typeof value === 'object') {
            window.sessionStorage.setItem(key, JSON.stringify(value, replacer));
        } else {
            window.sessionStorage.setItem(key, value);
        }
    }

    getItem(key: string): unknown {
        const value = window.sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    removeItem(key: string): void {
        window.sessionStorage.removeItem(key);
    }

    clear(): void {
        window.sessionStorage.clear();
    }
}
