/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSearchAddressApiModel } from './accountSearchAddressApiModel';
import { AccountSearchBillToApiModel } from './accountSearchBillToApiModel';


export interface AccountSearchMatchedOnApiModel { 
    legacyCustomerId?: string | null;
    invoiceIds: Array<string>;
    orderIds: Array<string>;
    shipmentIds: Array<string>;
    billTos: Array<AccountSearchBillToApiModel>;
    shipTos: Array<AccountSearchAddressApiModel>;
}

