import {
    AfterContentInit,
    Component,
    ContentChild,
    Directive,
    HostBinding,
    OnDestroy
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToggleLayoutFooterComponent } from './toggle-layout-footer/toggle-layout-footer.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'com-toggle-layout-content' })
export class ToggleLayoutContentDirective {}

@Component({
    selector: 'com-toggle-layout',
    templateUrl: './toggle-layout.component.html',
    styleUrls: ['./toggle-layout.component.scss']
})
export class ToggleLayoutComponent implements AfterContentInit, OnDestroy {
    @ContentChild(ToggleLayoutFooterComponent) toggleLayoutFooter: ToggleLayoutFooterComponent;
    @HostBinding('class.comToggleLayout--hasToggle') hasActionIcon = false;
    private ngUnsubscribe = new Subject<void>();

    ngAfterContentInit() {
        this.updateHasActionIcon()
        this.toggleLayoutFooter.actionIcons.changes
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.updateHasActionIcon()
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    updateHasActionIcon() {
        this.hasActionIcon = this.toggleLayoutFooter.actionIcons.length > 0;
    }
}
