import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmnBoldTerm'
})
export class CmnBoldTermPipe implements PipeTransform {
    transform(value = '', searchTerm: string) {
        if (!value || !searchTerm) {
            return value;
        }
        if (typeof value !== 'string') {
            throw new Error(`BoldTermPipe: Invalid value "${value}"`);
        }
        if (typeof searchTerm !== 'string') {
            throw new Error(`BoldTermPipe: Invalid searchTerm "${searchTerm}"`);
        }

        const regex = new RegExp(searchTerm, 'gi');
        return value.replace(regex, substring => `<strong>${substring}</strong>`);
    }
}
