import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class CookieHelperService {
    constructor(private cookieService: CookieService) {
    }

    get(key: string): string {
        const cookieVal = this.cookieService.get(key);

        // check for null/undefined
        return cookieVal == null ? '' : cookieVal;
    }

    set(name: string, value: string, options?: {
        expires?: number | Date;
        path?: string;
        domain?: string;
        secure?: boolean;
        sameSite?: 'Lax' | 'None' | 'Strict';
    }): void {
        this.cookieService.set(name, value, options);
    }
}
