import { NgModule } from '@angular/core';
import { ScopedOverlayContainerDirective } from './scoped-overlay-container.directive';
import { ScopedViewportRuler } from './scoped-viewport-ruler';
import { SCOPED_OVERLAY_CONTAINER_PROVIDERS } from './scoped-overlay-container-providers';

export { ScopedOverlayContainerDirective } from './scoped-overlay-container.directive';

@NgModule({
    declarations: [
        ScopedOverlayContainerDirective
    ],
    exports: [
        ScopedOverlayContainerDirective
    ],
    providers: [
        ScopedViewportRuler,
        SCOPED_OVERLAY_CONTAINER_PROVIDERS
    ]
})
export class ScopedOverlayModule {}
