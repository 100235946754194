/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillToCreditCardRequestModel } from './billToCreditCardRequestModel';
import { BillingMethodApiEnum } from './billingMethodApiEnum';
import { AddressRequest } from './addressRequest';
import { BillToInvoiceRequestModel } from './billToInvoiceRequestModel';


export interface AddUpdateBillToRequest { 
    billingMethod: BillingMethodApiEnum;
    /**
     * Indicates if the bill to should be used by default for new orders.
     */
    isDefault: boolean;
    address: AddressRequest;
    creditCardDetails?: BillToCreditCardRequestModel;
    invoiceDetails?: BillToInvoiceRequestModel;
}

