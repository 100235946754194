import { Component } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[hdrHeader02]',
    templateUrl: './header-02.component.html',
    styleUrls: ['./header-02.component.scss']
})
export class Header02Component {
}
