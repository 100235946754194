import { Injectable } from '@angular/core';

interface TimeDiffDetails {
    dueDate: string;
    durationUntilDueDate: string;
}

@Injectable()
export class TimeDiffService {
    getTimeDiff(date: Date | number): TimeDiffDetails {
        let dueDate: string;
        let durationUntilDueDate = 'P';
        let difference = Math.abs((date as unknown as number) - Date.now());
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        if (days > 0) {
            if (days > 30) {
                const months = Math.floor(days / 30);
                dueDate = `over ${this.formatPlural(months, 'month')}`;
            } else if (days >= 14) {
                const weeks = Math.floor(days / 7);
                dueDate = this.formatPlural(weeks, 'week');
                const leftoverDays = days % 7;
                if (leftoverDays > 0) {
                    dueDate += `, ${this.formatPlural(leftoverDays, 'day')}`;
                }
            } else {
                dueDate = this.formatPlural(days, 'day');
            }
            durationUntilDueDate += `${days}D`;
        }
        difference %= (1000 * 60 * 60 * 24);
        const hours = Math.floor(difference / (1000 * 60 * 60));
        if (hours > 0) {
            if (!dueDate) {
                dueDate = this.formatPlural(hours, 'hour');
            }
            durationUntilDueDate += `T${hours}H`;
        }
        difference %= (1000 * 60 * 60);
        const minutes = Math.floor(difference / (1000 * 60));
        if (minutes > 0) {
            if (!dueDate) {
                dueDate = this.formatPlural(minutes, 'minute');
            }
            const needsT = durationUntilDueDate.indexOf('T') === -1;
            durationUntilDueDate += `${needsT ? 'T' : ''}${minutes}M`;
        } else if (!dueDate) {
            dueDate = 'now';
            durationUntilDueDate = 'PT0M';
        }
        return { dueDate, durationUntilDueDate };
    }

    private formatPlural(count: number, units: string): string {
        return `${count} ${units}${count !== 1 ? 's' : ''}`;
    }
}
