export abstract class Window {
    abstract on(type: string, listener: (ev: UIEvent) => void, debounceTime?: number): () => unknown;
    abstract matchMedia(mediaQuery: string): MediaQueryList;
    abstract getWindowDimensions(): WindowDimensions;
    abstract addProperty(name: string, value: unknown): void;
}

export interface WindowDimensions {
    windowHeight: number;
    windowWidth: number;
}
