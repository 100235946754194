import { Directive, HostBinding, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';

import { IdService } from './id.service';

@Directive({
    selector: '[cmnId]'
})
export class IdDirective implements OnDestroy, OnInit {
    @HostBinding('id') id: string;
    @Output() setId = new EventEmitter<string>();

    constructor(private idService: IdService) {
        this.id = this.idService.getUniqueId();
    }

    ngOnInit() {
        this.setId.emit(this.id);
    }

    ngOnDestroy() {
        this.idService.reclaimId(this.id);
    }
}
