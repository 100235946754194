import { NgModule } from '@angular/core';
import { HeaderSectionComponent } from './header-section.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [HeaderSectionComponent],
    exports: [HeaderSectionComponent]
})
export class HeaderSectionModule {}
