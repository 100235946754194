import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IdService {
    private count = 0;
    private availableIds: string[] = [];

    getUniqueId() {
        return this.availableIds.length > 0
            ? this.availableIds.pop()
            : `id${++this.count}`;
    }

    reclaimId(id: string) {
        this.availableIds.push(id);
    }
}
