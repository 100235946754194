import { ActionReducer } from '@ngrx/store';
import { BATCH } from '../actions';
import { metaActionRunner } from '../meta-action-runner';
import { Core } from '../state';
import { BatchAction } from '../action-types';

export const batch = (reducer: ActionReducer<Core>): ActionReducer<Core> => {
    return metaActionRunner<Core>({
        [BATCH]: (state: Core, action: BatchAction) => {
            const newState = action.payload.reduce((prev, next) => reducer(prev, next), state);
            return newState;
        }
    }, reducer);
};
