import { NgModule } from '@angular/core';
import { CmnArrayToStringPipe } from './array-to-string.pipe';

export { CmnArrayToStringPipe } from './array-to-string.pipe';

@NgModule({
    declarations: [CmnArrayToStringPipe],
    exports: [CmnArrayToStringPipe]
})
export class ArrayToStringModule {}
