import { ConnectionPositionPair } from '@angular/cdk/overlay';

// Top Positions
export function positionTopLeft(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass: 'soScopedOverlay__topLeft',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionTopCenter(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        panelClass: 'soScopedOverlay__topCenter',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionTopRight(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass: 'soScopedOverlay__topRight',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

// Bottom Positions
export function positionBottomLeft(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: 'soScopedOverlay__bottomLeft',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionBottomCenter(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        panelClass: 'soScopedOverlay__bottomCenter',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionBottomRight(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        panelClass: 'soScopedOverlay__bottomRight',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

// Left Positions
export function positionLeftTop(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        panelClass: 'soScopedOverlay__leftTop',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionLeftCenter(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        panelClass: 'soScopedOverlay__leftCenter',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionLeftBottom(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return  {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass: 'soScopedOverlay__leftBottom',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

// Right Positions
export function positionRightTop(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: 'soScopedOverlay__rightTop',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionRightCenter(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        panelClass: 'soScopedOverlay__rightCenter',
        offsetX: offsetX,
        offsetY: offsetY
    };
}

export function positionRightBottom(offsetX = 0, offsetY = 0): ConnectionPositionPair {
    return {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass: 'soScopedOverlay__rightBottom',
        offsetX: offsetX,
        offsetY: offsetY
    };
}
