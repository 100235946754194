import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmnArrayToString'
})
export class CmnArrayToStringPipe implements PipeTransform {
    transform(input: Array<string>): string {
        return input.join(', ');
    }
}
