/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CountryCodeApiEnum } from './countryCodeApiEnum';
import { StateRegionCodeApiEnum } from './stateRegionCodeApiEnum';


export interface LocationApiModel { 
    city: string;
    stateRegionCode: StateRegionCodeApiEnum;
    postalCodes: Array<string>;
    countryCode: CountryCodeApiEnum;
    timezone: string;
    /**
     * TimezoneOffset has the format (-)hh:mm:ss (Ex -07:00:00)
     */
    timezoneOffset: string;
}

