import { actionRunner } from '../../action-runner';
import {
    ADD_APP_COLUMN_URL_CHANGE,
    ADD_STACKED_APP_COLUMN_URL_CHANGE,
    REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE
} from '../../actions';
import { AppColumn } from '../../../../app-column-router/app-column';
import {
    AddAppColumnUrlChangeAction,
    AddStackedAppColumnUrlChangeAction,
    RemoveStackedAppColumnLastUrlChangeAction
} from '../../action-types';

export const appColumnUrlChange = actionRunner<AppColumn[]>({
    [ADD_APP_COLUMN_URL_CHANGE]: (appColumns: AppColumn[], action: AddAppColumnUrlChangeAction) => {
        if (appColumns.find(appColumn => appColumn.id === action.payload.appColumnId).url !== action.payload.url) {
            return appColumns.map(appColumn => {
                if (appColumn.id === action.payload.appColumnId) {
                    return Object.assign(appColumn.copy(), {url: action.payload.url});
                }
                return appColumn;
            });
        }
        return appColumns;
    },
    [ADD_STACKED_APP_COLUMN_URL_CHANGE]: (appColumns: AppColumn[], action: AddStackedAppColumnUrlChangeAction) => {
        return appColumns.map(appColumn => {
            if (appColumn.id === action.payload.appColumnId) {
                const stackedAppColumn = appColumn.getTopMostStackedAppColumn().copy();
                const length = stackedAppColumn.historyUrls.length;
                if (length === 0 || stackedAppColumn.historyUrls[length - 1] !== action.payload.url) {
                    stackedAppColumn.url = action.payload.url;
                    stackedAppColumn.historyUrls.push(action.payload.url);
                    return Object.assign(appColumn.copy(), {stackedAppColumns: [stackedAppColumn]});
                }
            }
            return appColumn;
        });
    },
    [REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE]: (appColumns: AppColumn[], action: RemoveStackedAppColumnLastUrlChangeAction) => {
        return appColumns.map(appColumn => {
            if (appColumn.id === action.payload.appColumnId) {
                const stackedAppColumn = appColumn.getTopMostStackedAppColumn().copy();
                stackedAppColumn.historyUrls.pop();
                return Object.assign(appColumn.copy(), {stackedAppColumns: [stackedAppColumn]});
            }
            return appColumn;
        });
    }
}, []);
