import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountTypesService } from './services/accountTypes.service';
import { AccountsService } from './services/accounts.service';
import { AddressesService } from './services/addresses.service';
import { BillTosService } from './services/billTos.service';
import { ContactsService } from './services/contacts.service';
import { FinancialOverviewsService } from './services/financialOverviews.service';
import { NotesService } from './services/notes.service';
import { ProfilesService } from './services/profiles.service';
import { ShipTosService } from './services/shipTos.service';
import { TelligentUserService } from './services/telligentUser.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    public static forAppColumnHostComponent() {
        return [
            
            AccountTypesService,
            
            AccountsService,
            
            AddressesService,
            
            BillTosService,
            
            ContactsService,
            
            FinancialOverviewsService,
            
            NotesService,
            
            ProfilesService,
            
            ShipTosService,
            
            TelligentUserService
        ];
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
