import { NgModule } from '@angular/core';
import { ClickEnterEventDirective } from './click-enter-event.directive';

export { ClickEnterEventDirective } from './click-enter-event.directive';

@NgModule({
    declarations: [ClickEnterEventDirective],
    exports: [ClickEnterEventDirective],
})
export class ClickEnterEventModule { }
