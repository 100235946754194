import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmnHighlightTerm'
})
export class CmnHighlightTermPipe implements PipeTransform {
    transform(value: string, searchTerm: string) {
        if (value === null || value === undefined || searchTerm === null || searchTerm === undefined) {
            return value;
        }

        searchTerm = searchTerm.replace(/[\][)(]*/g, '');
        if (searchTerm === '') {
            return value;
        }

        const pattern = searchTerm
            .split('')
            .reduce((a, b) => `${a}${b}`);

        return value.replace(new RegExp(pattern, 'i'), substr => {
            return `<mark class="cmnHighlightTerm">${substr}</mark>`;
        });
    }
}
