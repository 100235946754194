/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CreditCardTypeApiEnum = 'AmericanExpress' | 'Discover' | 'MasterCard' | 'Visa' | 'Unknown';

export const CreditCardTypeApiEnum = {
    AmericanExpress: 'AmericanExpress' as CreditCardTypeApiEnum,
    Discover: 'Discover' as CreditCardTypeApiEnum,
    MasterCard: 'MasterCard' as CreditCardTypeApiEnum,
    Visa: 'Visa' as CreditCardTypeApiEnum,
    Unknown: 'Unknown' as CreditCardTypeApiEnum
};

