import { AppColumn } from '../../../../app-column-router/app-column';
import { SelectAppColumnAction } from '../../action-types';

export const selectAppColumn = (appColumns: AppColumn[], action: SelectAppColumnAction) => {
    return appColumns.map(appColumn => {
        if (appColumn.isSelected && appColumn.id !== action.payload.appColumnId) {
            return Object.assign(appColumn.copy(), { isSelected: false });
        } else if (!appColumn.isSelected && appColumn.id === action.payload.appColumnId) {
            return Object.assign(appColumn.copy(), { isSelected: true, dateAccessed: action.payload.dateAccessed });
        }
        return appColumn;
    });
};
