/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BillToStatusApiEnum = 'Valid' | 'Expired' | 'Suspended';

export const BillToStatusApiEnum = {
    Valid: 'Valid' as BillToStatusApiEnum,
    Expired: 'Expired' as BillToStatusApiEnum,
    Suspended: 'Suspended' as BillToStatusApiEnum
};

