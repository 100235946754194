import { Avatar } from '@center-suite/shared/utility/common';

export enum ColumnSize {
    Small = 1,
    Dynamic = 2
}

export enum AutoColumnSize {
    Small = 1,
    Medium = 2,
    Large = 3
}

export class Modal {}

export class StackedAppColumn {
    public historyUrls: string[] = [];

    constructor(public url: string) {}

    getBackUrl() {
        if (this.historyUrls.length >= 2) {
            return this.historyUrls[this.historyUrls.length - 2];
        }
        return null;
    }

    copyForSerializing() {
        return this.copy();
    }

    copy() {
        const stackedAppColumn = new StackedAppColumn(this.url);
        stackedAppColumn.historyUrls = [...this.historyUrls];
        return stackedAppColumn;
    }
}

export class AppColumn {
    public isReplacementColumn = false;
    public dateAccessed = Date.now();
    public autoSize = AutoColumnSize.Large;

    constructor(
        public id?: number,
        public url?: string,
        public routeGroupingKey?: string,
        public size: ColumnSize = ColumnSize.Dynamic,
        public isSelected = true,
        public isOnStage = true,
        public stackedAppColumns: StackedAppColumn[] = []) {}

    getTopMostStackedAppColumn() {
        if (this.stackedAppColumns.length > 0) {
            return this.stackedAppColumns[this.stackedAppColumns.length - 1];
        }
        return null;
    }

    copyForSerializing() {
        const copy = new AppColumn(
            this.id,
            this.url,
            this.routeGroupingKey,
            this.size,
            this.isSelected,
            this.isOnStage,
            this.stackedAppColumns.map(value => value.copyForSerializing())
        );
        copy.autoSize = this.autoSize;
        copy.dateAccessed = this.dateAccessed;
        return copy;
    }

    copy() {
        const copy = new AppColumn(
            this.id,
            this.url,
            this.routeGroupingKey,
            this.size,
            this.isSelected,
            this.isOnStage,
            this.stackedAppColumns.map(value => value.copy())
        );
        copy.autoSize = this.autoSize;
        copy.dateAccessed = this.dateAccessed;
        return copy;
    }
}

export interface AppColumnWithTitleAndAvatar extends AppColumn {
    title: string;
    secondaryTitle?: string;
    avatar?: Avatar
}
