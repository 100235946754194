/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Injector, Optional, Type }      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse,
         HttpEvent, HttpParameterCodec, HttpErrorResponse }  from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable, of, throwError }                        from 'rxjs';
import { map, retryWhen, catchError }                        from 'rxjs/operators';

import { AccountDetailsApiModel } from '../models/models';
import { AccountSearchPageResponseApiModel } from '../models/models';
import { BadRequestResponse } from '../models/models';
import { InternalServerErrorResponse } from '../models/models';
import { NearbyAccountsApiModel } from '../models/models';
import { ProfileSummaryApiModel } from '../models/models';
import { UnauthorizedResponse } from '../models/models';

import { AppColumnFactory }                                  from '@center-suite/shared/framework/app-column-framework';
import { Adapter, APP_COLUMN_ID, httpRequestRetryStrategy }  from '@center-suite/shared/data-access/utility';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetAccountByIdRequestParams {
    id: string | null;
}

export interface GetNearbyAccountsRequestParams {
    topLatitude: number;
    bottomLatitude: number;
    leftLongitude: number;
    rightLongitude: number;
}

export interface GetPrimaryOwnerProfileSummaryByAccountIdRequestParams {
    id: string | null;
}

export interface SearchAccountsRequestParams {
    sortBy: 'DateLastModified_Desc' | 'DateCreated_Desc' | 'Score_Desc';
    searchTerm?: string | null;
    primaryOwnerProfileId?: number | null;
    after?: string;
    before?: string;
    pageSize?: number;
}


@Injectable({
  providedIn: 'root'
})
export class AccountsService {

    protected basePath = 'https://localhost:10000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private injector: Injector, protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration, @Optional() private appColumnFactory: AppColumnFactory) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountById<TReturn, TAdapter extends Adapter<AccountDetailsApiModel, TReturn>>(requestParameters: GetAccountByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public getAccountById<TReturn, TAdapter extends Adapter<AccountDetailsApiModel, TReturn>>(requestParameters: GetAccountByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public getAccountById<TReturn, TAdapter extends Adapter<AccountDetailsApiModel, TReturn>>(requestParameters: GetAccountByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public getAccountById<TReturn, TAdapter extends Adapter<AccountDetailsApiModel, TReturn>>(requestParameters: GetAccountByIdRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAccountById.');
        }

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AccountDetailsApiModel>(`${this.configuration.basePath}/api/accounts/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<AccountDetailsApiModel> | AccountDetailsApiModel | HttpEvent<AccountDetailsApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<AccountDetailsApiModel>);
                } else {
                    return adapter.adaptItem(res as AccountDetailsApiModel);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNearbyAccounts<TReturn, TAdapter extends Adapter<Array<NearbyAccountsApiModel>, TReturn>>(requestParameters: GetNearbyAccountsRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public getNearbyAccounts<TReturn, TAdapter extends Adapter<Array<NearbyAccountsApiModel>, TReturn>>(requestParameters: GetNearbyAccountsRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public getNearbyAccounts<TReturn, TAdapter extends Adapter<Array<NearbyAccountsApiModel>, TReturn>>(requestParameters: GetNearbyAccountsRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public getNearbyAccounts<TReturn, TAdapter extends Adapter<Array<NearbyAccountsApiModel>, TReturn>>(requestParameters: GetNearbyAccountsRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const topLatitude = requestParameters.topLatitude;
        if (topLatitude === null || topLatitude === undefined) {
            throw new Error('Required parameter topLatitude was null or undefined when calling getNearbyAccounts.');
        }
        const bottomLatitude = requestParameters.bottomLatitude;
        if (bottomLatitude === null || bottomLatitude === undefined) {
            throw new Error('Required parameter bottomLatitude was null or undefined when calling getNearbyAccounts.');
        }
        const leftLongitude = requestParameters.leftLongitude;
        if (leftLongitude === null || leftLongitude === undefined) {
            throw new Error('Required parameter leftLongitude was null or undefined when calling getNearbyAccounts.');
        }
        const rightLongitude = requestParameters.rightLongitude;
        if (rightLongitude === null || rightLongitude === undefined) {
            throw new Error('Required parameter rightLongitude was null or undefined when calling getNearbyAccounts.');
        }

        const adapter = this.injector.get(tAdapter);

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (topLatitude !== undefined && topLatitude !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topLatitude, 'TopLatitude');
        }
        if (bottomLatitude !== undefined && bottomLatitude !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bottomLatitude, 'BottomLatitude');
        }
        if (leftLongitude !== undefined && leftLongitude !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leftLongitude, 'LeftLongitude');
        }
        if (rightLongitude !== undefined && rightLongitude !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rightLongitude, 'RightLongitude');
        }

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<NearbyAccountsApiModel>>(`${this.configuration.basePath}/api/accounts/nearby`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<Array<NearbyAccountsApiModel>> | Array<NearbyAccountsApiModel> | HttpEvent<Array<NearbyAccountsApiModel>> | null) => {
                if (res === null) {
                    return [];
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<Array<NearbyAccountsApiModel>>);
                } else {
                    return adapter.adaptItem(res as NearbyAccountsApiModel[]);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrimaryOwnerProfileSummaryByAccountId<TReturn, TAdapter extends Adapter<ProfileSummaryApiModel, TReturn>>(requestParameters: GetPrimaryOwnerProfileSummaryByAccountIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public getPrimaryOwnerProfileSummaryByAccountId<TReturn, TAdapter extends Adapter<ProfileSummaryApiModel, TReturn>>(requestParameters: GetPrimaryOwnerProfileSummaryByAccountIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public getPrimaryOwnerProfileSummaryByAccountId<TReturn, TAdapter extends Adapter<ProfileSummaryApiModel, TReturn>>(requestParameters: GetPrimaryOwnerProfileSummaryByAccountIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public getPrimaryOwnerProfileSummaryByAccountId<TReturn, TAdapter extends Adapter<ProfileSummaryApiModel, TReturn>>(requestParameters: GetPrimaryOwnerProfileSummaryByAccountIdRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPrimaryOwnerProfileSummaryByAccountId.');
        }

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProfileSummaryApiModel>(`${this.configuration.basePath}/api/accounts/${encodeURIComponent(String(id))}/primary-owner-profile-summary`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<ProfileSummaryApiModel> | ProfileSummaryApiModel | HttpEvent<ProfileSummaryApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<ProfileSummaryApiModel>);
                } else {
                    return adapter.adaptItem(res as ProfileSummaryApiModel);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAccounts<TReturn, TAdapter extends Adapter<AccountSearchPageResponseApiModel, TReturn>>(requestParameters: SearchAccountsRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public searchAccounts<TReturn, TAdapter extends Adapter<AccountSearchPageResponseApiModel, TReturn>>(requestParameters: SearchAccountsRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public searchAccounts<TReturn, TAdapter extends Adapter<AccountSearchPageResponseApiModel, TReturn>>(requestParameters: SearchAccountsRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public searchAccounts<TReturn, TAdapter extends Adapter<AccountSearchPageResponseApiModel, TReturn>>(requestParameters: SearchAccountsRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const sortBy = requestParameters.sortBy;
        if (sortBy === null || sortBy === undefined) {
            throw new Error('Required parameter sortBy was null or undefined when calling searchAccounts.');
        }
        const searchTerm = requestParameters.searchTerm;
        const primaryOwnerProfileId = requestParameters.primaryOwnerProfileId;
        const after = requestParameters.after;
        const before = requestParameters.before;
        const pageSize = requestParameters.pageSize;

        const adapter = this.injector.get(tAdapter);

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (searchTerm !== undefined && searchTerm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchTerm, 'SearchTerm');
        }
        if (primaryOwnerProfileId !== undefined && primaryOwnerProfileId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>primaryOwnerProfileId, 'PrimaryOwnerProfileId');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'SortBy');
        }
        if (after !== undefined && after !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>after, 'After');
        }
        if (before !== undefined && before !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>before, 'Before');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'PageSize');
        }

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AccountSearchPageResponseApiModel>(`${this.configuration.basePath}/api/accounts`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<AccountSearchPageResponseApiModel> | AccountSearchPageResponseApiModel | HttpEvent<AccountSearchPageResponseApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<AccountSearchPageResponseApiModel>);
                } else {
                    return adapter.adaptItem(res as AccountSearchPageResponseApiModel);
                }
            })
        );
    }

}
