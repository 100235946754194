import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({selector: '[accClickEnterEvent]'})
export class ClickEnterEventDirective {
    @Output() accClickEnterEvent: EventEmitter<Event> = new EventEmitter();

    // Custom omClick event for clicks inside Order Manager iframes.
    @HostListener('omClick') onOmClick(event: CustomEvent) {
        this.accClickEnterEvent.emit(event);
    }

    // Mouse up event
    // mouseup event used since prevent default is needed, if click is used
    // toggling of form controls like radio buttons doesn't work
    @HostListener('mouseup', ['$event']) onMouseup(event: MouseEvent) {
        event.preventDefault(); // Prevent focus from remaining after click
        this.accClickEnterEvent.emit(event);
    }

    // Enter keyup event
    @HostListener('keyup.Enter', ['$event']) onKeyupEnter(event: KeyboardEvent) {
        this.accClickEnterEvent.emit(event);
    }

    // Spacebar keyup event
    @HostListener('keyup.Space', ['$event']) onKeyupSpace(event: KeyboardEvent) {
        this.accClickEnterEvent.emit(event);
    }
}
