import { NgModule } from '@angular/core';
import { CmnBoldTermPipe } from './bold-term.pipe';

export { CmnBoldTermPipe } from './bold-term.pipe';

@NgModule({
    declarations: [CmnBoldTermPipe],
    exports: [CmnBoldTermPipe]
})
export class BoldTermModule {}
