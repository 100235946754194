/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSearchAddressApiModel } from './accountSearchAddressApiModel';
import { BillingMethodApiEnum } from './billingMethodApiEnum';
import { CreditCardTypeApiEnum } from './creditCardTypeApiEnum';


export interface AccountSearchBillToApiModel { 
    address: AccountSearchAddressApiModel;
    billingMethod: BillingMethodApiEnum;
    creditCardType?: CreditCardTypeApiEnum;
    creditCardLastFour?: string | null;
}

