import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnDestroy,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { MessageInfoComponent } from '../message-info.component';
import { MessageInfoIconComponent } from '../message-info-icon/message-info-icon.component';
import {
    DEFAULT_TIMING,
    expandListShowHideAnimation,
    fadeOnChangeAnimation,
    fadeScaleEnterLeaveAnimation
} from '@center-suite/shared/ui/animation';
import { animate, animateChild, AnimationEvent, query, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const enum MessageStateEnum {
    ERROR = 'comMessageInfo__state--03',
    WARNING = 'comMessageInfo__state--04',
    INFO = 'comMessageInfo__state--01'
}

interface List {
    state: string;
    items: MessageInfoListItemComponent[];
    icons?: MessageInfoIconComponent[];
    subject?: string;
    isExpanded?: boolean;
    animationState?: string;
    animationDone?: boolean;
    triggerAriaData?: AriaData;
    messagesAriaData?: AriaData;
}

interface AriaData {
    [key: string]: string | boolean | null;
}

@Component({
    selector: 'com-message-info-list-item',
    template: `<ng-template><ng-content></ng-content></ng-template>`
})
export class MessageInfoListItemComponent {
    @ViewChild(TemplateRef, { static: true }) template: TemplateRef<unknown>;
    @Input() subject: string;
}

@Component({
    selector: 'com-message-info-list',
    templateUrl: './message-info-list.component.html',
    styleUrls: ['./message-info-list.component.scss'],
    animations: [
        fadeScaleEnterLeaveAnimation,
        expandListShowHideAnimation,
        fadeOnChangeAnimation,
        trigger('updateList', [
            transition('* <=> *', [
                query('@*', animateChild(), {optional: true})
            ])
        ]),
        trigger('collapseHeightLeave', [
            transition(':leave', [
                animate(
                    DEFAULT_TIMING,
                    style({ height: 0, paddingBottom: 0, paddingTop: 0 })
                )
            ])
        ])
    ]
})
export class MessageInfoListComponent implements AfterContentInit, AfterViewInit, OnDestroy {
    @ContentChildren(MessageInfoListItemComponent) itemComponents: QueryList<MessageInfoListItemComponent>;
    @ContentChildren(MessageInfoComponent, { descendants: true }) messageComponents: QueryList<MessageInfoComponent>;
    @ContentChildren(MessageInfoIconComponent, { descendants: true }) messageIconComponents: QueryList<MessageInfoIconComponent>;
    @ViewChildren('messageListElement') messageListElements: QueryList<ElementRef>;
    @ViewChildren('subjectElement') subjectElements: QueryList<ElementRef>;
    lists: List[] = [];
    private messageStateOrder = [
        MessageStateEnum.ERROR,
        MessageStateEnum.WARNING,
        MessageStateEnum.INFO
    ];
    private ngUnsubscribe = new Subject<void>();

    constructor(private renderer: Renderer2) { }

    ngAfterContentInit() {
        this.updateAllLists();
        this.itemComponents.changes
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.updateAllLists()
            });
    }

    ngAfterViewInit() {
        // Set aria data for expandable lists
        const expandableLists = this.lists.filter(list => list.hasOwnProperty('messagesAriaData'));
        for (let i = 0; i < expandableLists.length; i++) {
            expandableLists[i].triggerAriaData = {...this.lists[i].triggerAriaData,
                controls: this.messageListElements.toArray()[i].nativeElement.id,
                labelledby: this.subjectElements.toArray()[i].nativeElement.id
            };
            expandableLists[i].messagesAriaData = {...this.lists[i].messagesAriaData, labelledby: this.subjectElements.toArray()[i].nativeElement.id};
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    updateAllLists() {
        this.messageStateOrder.forEach((state) => {
            this.generateList(state);
        });
    }

    generateList(state: string) {
        const items: MessageInfoListItemComponent[] = [];
        const subjects: string[] = [];
        const messages: MessageInfoComponent[] = [];
        const icons: MessageInfoIconComponent[] = [];

        // Add/remove items to the list
        this.messageComponents.forEach((message: MessageInfoComponent, i: number) => {
            if (MessageInfoListComponent.getMessageViewState(message.cssClassBinder) === state) {
                if (this.itemComponents.get(i)) {
                    // If the item is found, add it to the list
                    items.push(this.itemComponents.get(i));
                    messages.push(message);
                    icons.push(this.messageIconComponents.get(i));
                }
                else if (this.lists) {
                    // If the item is not found, it needs to be removed from the list
                    this.removeItemFromList(state);
                }
            }
        });

        if (items.length) {
            // Add all subjects to the list of subjects
            items.forEach(item => subjects.push(item.subject));

            // Create the list for the given state
            let list: List = {
                state,
                items
            };

            if (messages.length > 1) {
                // Force the child message info components to expand and remove their toggle
                messages.forEach((message: MessageInfoComponent) => {
                    if (message.body) {
                        message.toggle = false;
                        message.expand = true;
                    }
                })

                // Add the properties needed for an expandable list when there is more than one item in the list
                list = {
                    ...list,
                    icons,
                    subject: MessageInfoListComponent.getSubjectsMessage(state, subjects),
                    isExpanded: false,
                    animationState: 'collapsed',
                    animationDone: true,
                    triggerAriaData: {
                        expanded: 'false'
                    },
                    messagesAriaData: {}
                }
            } else {
                // if going from a multi-item list to a single-item list, make the remaining single message expandable again
                if (messages[0].body) {
                    messages[0].toggle = true;
                }
            }

            // Check if list exists
            const existingListIndex = this.lists.findIndex(l => l.state === state)
            const existingList = this.lists[existingListIndex];

            if (existingList) {
                // update existing list
                existingList.subject = list.subject
                existingList.items = list.items
                existingList.icons = list.icons

                // if going from a multi-item list to a single-item list, remove expansion aria-data
                if (existingList.items.length === 1) {
                    this.renderer.removeAttribute(this.messageListElements.get(existingListIndex).nativeElement, 'aria-labelledby');
                }
            }
            else {
                // add new list
                this.lists.push(list);
            }
        }
    }

    toggleExpandCollapse(index: number) {
        const list = this.lists[index];
        list.isExpanded = !list.isExpanded;
        list.triggerAriaData = {...list.triggerAriaData, expanded: list.isExpanded.toString()};
        if (list.animationState === 'collapsed') {
            list.animationDone = true;
            list.animationState = 'expanded';
        } else {
            list.animationDone = false;
            list.animationState = 'collapsed';
        }
    }

    onListShowHideDone(event: AnimationEvent, index: number): void {
        if (event.fromState === 'expanded') {
            this.lists[index].animationDone = true;
        }
    }

    private removeItemFromList(state: string): void {
        // Remove item components from the individual lists when they've been removed from the view
        // - This happens when the logic that displays one of the item components has changed so that
        //   the item is no longer rendered, like in the case that a "dismiss" button is clicked on
        //   the message info component
        const list = this.lists.find(list => list.state === state);
        if (list) {
            list.items.every((itemComponent, index) => {
                const matchingIndex = this.itemComponents.toArray().findIndex(match => match === itemComponent);

                // When there is no matching item component found, remove it from the list and return to
                // break the loop
                if (matchingIndex < 0) {
                    list.items.splice(index, 1);
                    return false;
                }

                return true;
            });
        }
    }

    private static getSubjectsMessage(state: string, subjects: string[]): string {
        let prefix;
        switch (state) {
            case MessageStateEnum.ERROR: {
                prefix = 'Errors: ';
                break;
            }
            case MessageStateEnum.WARNING: {
                prefix = 'Warnings: ';
                break;
            }
            default: {
                prefix = 'Messages: ';
            }
        }

        if (subjects.length === 2) {
            return prefix + subjects[0] +  ' and ' + subjects[1];
        }

        return prefix + [subjects.slice(0, -1).join(', '), subjects.slice(-1)[0]].join(', and ');
    }

    private static getMessageViewState(state: string): string {
        if (!state) {
            return MessageStateEnum.INFO;
        }

        return state;
    }
}
