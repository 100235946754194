import {
    Overlay,
    OverlayKeyboardDispatcher,
    OverlayOutsideClickDispatcher,
    ScrollStrategyOptions
} from '@angular/cdk/overlay';
import { ComponentFactoryResolver, Inject, Injectable, Injector, NgZone, Optional } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Directionality } from '@angular/cdk/bidi';
import { ScopedOverlayContainer } from './scoped-overlay-container';
import { ScopedOverlayPositionBuilder } from './scoped-overlay-position-builder';

@Injectable()
export class ScopedOverlay extends Overlay {
    constructor(public scrollStrategies: ScrollStrategyOptions,
                private scopedOverlayContainer: ScopedOverlayContainer,
                private normalComponentFactoryResolver: ComponentFactoryResolver,
                private scopedPositionBuilder: ScopedOverlayPositionBuilder,
                private normalKeyboardDispatcher: OverlayKeyboardDispatcher,
                private normalInjector: Injector,
                private normalNgZone: NgZone,
                private overlayOutsideClickDispatcher: OverlayOutsideClickDispatcher,
                @Inject(DOCUMENT) private normalDocument: Document,
                private normalDirectionality: Directionality,
                @Optional() private normalLocation?: Location
    ) {
        super(
            scrollStrategies,
            scopedOverlayContainer,
            normalComponentFactoryResolver,
            scopedPositionBuilder,
            normalKeyboardDispatcher,
            normalInjector,
            normalNgZone,
            normalDocument,
            normalDirectionality,
            normalLocation,
            overlayOutsideClickDispatcher);
    }

    setContainerElement(containerElement: HTMLElement) {
        this.scopedOverlayContainer.setContainerElement(containerElement);
    }

    setAlternateContainerElement(containerElement: HTMLElement) {
        this.scopedOverlayContainer.setAlternateContainerElement(containerElement);
    }
}
