import { EventEmitter, Injectable } from '@angular/core';
import {
    Location,
    LocationChangeEvent,
    LocationChangeListener,
    LocationStrategy,
    PlatformLocation
} from '@angular/common';
import { AppColumnsService } from './services/app-columns.service';
import { AppColumn } from './app-column';
import { Subscription } from 'rxjs';

let onPopState: (fn: LocationChangeListener) => void;

interface AppHistoryState {
    appColumns: AppColumn[];
}

@Injectable()
export class AppColumnLocation {
    locationChange = new EventEmitter();

    private location: Location;

    constructor(
        public platformStrategy: LocationStrategy,
        public platformLocation: PlatformLocation,
        private appColumnsService: AppColumnsService) {
        this.location = new Location(facadePlatformStrategy(platformStrategy), platformLocation);

        const handler = (e: LocationChangeEvent) => {
            this.locationChange.emit({
                url: this.path(true),
                state: this.getAppHistoryState(e.state),
                pop: true,
                type: e.type
            });
        };

        this.platformStrategy.onPopState = onPopState;
        this.platformStrategy.onPopState(handler);
    }

    path(includeHash = false): string {
        return this.location.path(includeHash);
    }

    isCurrentPathEqualTo(path: string, query = ''): boolean {
        return this.location.isCurrentPathEqualTo(path, query);
    }

    normalize(url: string): string {
        return this.location.normalize(url);
    }

    prepareExternalUrl(url: string): string {
        return this.location.prepareExternalUrl(url);
    }

    go(path: string, query = ''): void {
        this.platformStrategy.pushState(this.getAppStateSnapshot(), '', path, query);
    }

    replaceState(path: string, query = ''): void {
        this.platformStrategy.replaceState(this.getAppStateSnapshot(), '', path, query);
    }

    forward(): void { this.location.forward(); }

    back(): void { this.location.back(); }

    subscribe(onNext: (value: unknown) => void, onThrow: (exception: unknown) => void = null,
        onReturn: () => void = null): Subscription {
        return this.locationChange.subscribe(onNext, onThrow, onReturn);
    }

    private getAppStateSnapshot() {
        return {
            appColumns: this.appColumnsService.getSnapshot(),
            timestamp: Date.now()
        };
    }

    private getAppHistoryState(historyState: AppHistoryState): AppHistoryState {
        if (historyState) {
            return { appColumns: historyState.appColumns };
        }
        return null;
    }
}

function facadePlatformStrategy(platformStrategy: LocationStrategy) {
    onPopState = platformStrategy.onPopState;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    platformStrategy.onPopState = () => {};
    return platformStrategy;
}
