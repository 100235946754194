/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BestTimeToReachApiEnum = 'Morning' | 'MidMorning' | 'Afternoon' | 'LateAfternoon' | 'Evening';

export const BestTimeToReachApiEnum = {
    Morning: 'Morning' as BestTimeToReachApiEnum,
    MidMorning: 'MidMorning' as BestTimeToReachApiEnum,
    Afternoon: 'Afternoon' as BestTimeToReachApiEnum,
    LateAfternoon: 'LateAfternoon' as BestTimeToReachApiEnum,
    Evening: 'Evening' as BestTimeToReachApiEnum
};

