import { Document, ViewportDimensions } from '../base/document';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserDocument extends Document {
    getViewportDimensions(): ViewportDimensions {
        const { clientHeight, clientWidth } = document.documentElement;
        return { viewportHeight: clientHeight, viewportWidth: clientWidth };
    }

    appendToBody(element: Node): void {
        if (element instanceof Node) {
            document.body.appendChild(element);
        }
    }

    createScriptElement(source: string): HTMLScriptElement {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = source;
        script.async = true;
        script.defer = true;
        return script;
    }

    createElement(tagName: string): HTMLElement {
        return document.createElement(tagName);
    }

    querySelector(selector: string): Element {
        return document.querySelector(selector);
    }

    execCommand(commandName: string, arg: string): void {
        document.execCommand(commandName, false, arg);
    }

    createRange(): Range {
        return document.createRange();
    }

    getSelection(): Selection {
        return document.getSelection();
    }
}
