/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BestTimeToReachApiEnum } from './bestTimeToReachApiEnum';


export interface AddContactRequest { 
    /**
     * Name of contact.
     */
    name: string;
    /**
     * Indicates if the contact can receive text notifications. If true, PhoneNumber is required.
     */
    canReceiveText: boolean;
    /**
     * Phone number (digits only, without formatting).
     */
    phoneNumber?: string | null;
    emailAddress?: string | null;
    avatarUrl?: string | null;
    bestTimeToReach?: BestTimeToReachApiEnum;
}

