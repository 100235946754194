import { Injectable } from '@angular/core';
import { filter, map, Observable, Subject } from 'rxjs';

interface WindowManagerEvent {
    data: unknown;
    windowRef: Window
}

export interface WindowDetails {
    width?: number | null,
    height?: number | null,
    top?: number | null,
    left?: number | null
}

@Injectable({
    providedIn: 'root'
})
export class WindowManagerService {

    private openerMessages = new Subject<MessageEvent>();
    private readonly windows: { [key: string]: Window } = {};

    getWindow(windowName: string) {
        return !(this.windows[windowName] == null || this.windows[windowName].closed)
            ? this.windows[windowName]
            : null;
    }

    openWindow(url: string, windowName: string, windowDetails: WindowDetails): Observable<WindowManagerEvent>;
    openWindow(url: string, windowName: string, windowDetails: WindowDetails, openOnly?: boolean): void;
    openWindow(url: string, windowName: string, windowDetails: WindowDetails, openOnly?: boolean): Observable<WindowManagerEvent> | void {
        const existingWindow = this.getWindow(windowName);
        if (existingWindow === null)
        {
            this.windows[windowName] = window.open(
                url,
                windowName,
                this.flattenWindowDetails(windowDetails));
        } else {
            existingWindow.postMessage(url, '*');
            existingWindow.focus();
        }

        if (openOnly) {
            return;
        }

        return this.openerMessages.pipe(
            filter(e => e.source === this.windows[windowName]),
            map(({data}) => {
                return {data, windowRef: this.windows[windowName]};
            })
        );
    }

    closeWindow(windowName: string) {
        if (this.windows[windowName]) {
            this.windows[windowName].close();
            delete this.windows[windowName];
        }
    }

    onReceivedMessage(e: MessageEvent) {
        this.openerMessages.next(e);
    }

    private flattenWindowDetails(windowDetails: WindowDetails) {
        let details = '';
        Object.keys(windowDetails).forEach(function (key, i) {
            details += `${key}=${windowDetails[key]}${i < Object.keys(windowDetails).length - 1 ? ',' : ''}`;
        });
        return details;
    }
}
