import { ElementRef } from '@angular/core';
import {
    ConnectedOverlayPositionChange,
    ConnectionPositionPair,
    FlexibleConnectedPositionStrategyOrigin,
    OverlayContainer,
    PositionStrategy,
    ScrollingVisibility,
    validateHorizontalPosition,
    validateVerticalPosition
} from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { CdkScrollable, ViewportRuler, ViewportScrollPosition } from '@angular/cdk/scrolling';
import { isElementClippedByScrolling, isElementScrolledOutsideView } from './scroll-clip';
import { coerceArray, coerceCssPixelValue } from '@angular/cdk/coercion';
import { takeUntil } from 'rxjs/operators';
import { ScopedViewportRuler } from './scoped-viewport-ruler';
import { ScopedOverlayPaneAdjuster } from './scoped-overlay-pane-adjuster';
import { OverlayReference } from '@angular/cdk/overlay/overlay-reference';

/** Class to be added to the overlay bounding box. */
const boundingBoxClass = 'cdk-overlay-connected-position-bounding-box';

/** Regex used to split a string on its CSS units. */
const cssUnitPattern = /([A-Za-z%]+)$/;

/** Equivalent of `ClientRect` without some of the properties we don't care about. */
type Dimensions = Omit<ClientRect, 'x' | 'y' | 'toJSON'>;

/**
 * A strategy for positioning overlays. Using this strategy, an overlay is given an
 * implicit position relative some origin element. The relative position is defined in terms of
 * a point on the origin element that is connected to a point on the overlay element. For example,
 * a basic dropdown is connecting the bottom-left corner of the origin to the top-left corner
 * of the overlay.
 */
export class ScopedFlexibleConnectedPositionStrategy implements PositionStrategy {
    /** The overlay to which this strategy is attached. */
    private _overlayRef: OverlayReference;

    /** Whether we're performing the very first positioning of the overlay. */
    private _isInitialRender: boolean;

    /** Last size used for the bounding box. Used to avoid resizing the overlay after open. */
    private _lastBoundingBoxSize = {width: 0, height: 0};

    /** Whether the overlay was pushed in a previous positioning. */
    private _isPushed = false;

    /** Whether the overlay can be pushed on-screen on the initial open. */
    private _canPush = true;

    /** Whether the overlay can grow via flexible width/height after the initial open. */
    private _growAfterOpen = false;

    /** Whether the overlay's width and height can be constrained to fit within the viewport. */
    private _hasFlexibleDimensions = true;

    /** Whether the overlay position is locked. */
    private _positionLocked = false;

    /** Cached origin dimensions */
    private _originRect: Dimensions;

    /** Cached overlay dimensions */
    private _overlayRect: Dimensions;

    /** Cached viewport dimensions */
    private _viewportRect: Dimensions;

    /** Amount of space that must be maintained between the overlay and the edge of the viewport. */
    private _viewportMargin = 0;

    /** The Scrollable containers used to check scrollable view properties on position change. */
    private _scrollables: CdkScrollable[] = [];

    /** Ordered list of preferred positions, from most to least desirable. */
    _preferredPositions: ConnectionPositionPair[] = []; // eslint-disable-line @typescript-eslint/member-ordering

    /** The origin element against which the overlay will be positioned. */
    private _origin: FlexibleConnectedPositionStrategyOrigin;

    /** The overlay pane element. */
    private _pane: HTMLElement;

    /** Whether the strategy has been disposed of already. */
    private _isDisposed: boolean;

    /**
     * Parent element for the overlay panel used to constrain the overlay panel's size to fit
     * within the viewport.
     */
    private _boundingBox: HTMLElement | null;

    /** The last position to have been calculated as the best fit position. */
    private _lastPosition: ConnectedPosition | null;

    /** Subject that emits whenever the position changes. */
    private readonly _positionChanges = new Subject<ConnectedOverlayPositionChange>();

    /** Default offset for the overlay along the x axis. */
    private _offsetX = 0;

    /** Default offset for the overlay along the y axis. */
    private _offsetY = 0;

    /** Selector to be used when finding the elements on which to set the transform origin. */
    private _transformOriginSelector: string;

    /** Keeps track of the CSS classes that the position strategy has applied on the overlay panel. */
    private _appliedPanelClasses: string[] = [];

    /** Amount by which the overlay was pushed in each axis during the last time it was positioned. */
    private _previousPushAmount: {x: number; y: number} | null;

    /** Subject to keep track of subscriptions and handle unsubscribing. */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /** Observable sequence of position changes. */
    positionChanges: Observable<ConnectedOverlayPositionChange> = this._positionChanges; // eslint-disable-line @typescript-eslint/member-ordering

    /** Properties to properly handle positioning for virtual keyboard viewport */
    private _virtualKeyboardVisible = false;
    private _virtualKeyboardPreviouslyVisible = false;
    private _willCloseOnVirtualKeyboardToggle = false;

    /** Track amount virtual keyboard has caused the browser to scroll */
    private _previousScrollDistance = 0;
    private _virtualKeyboardScrollDistance = 0;

    // Outermost scrolling element
    private _rootScrollingElement = document.scrollingElement;

    /** Ordered list of preferred positions, from most to least desirable. */
    get positions(): ConnectionPositionPair[] {
        return this._preferredPositions;
    }

    constructor(
        connectedTo: FlexibleConnectedPositionStrategyOrigin,
        private _viewportRuler: ViewportRuler,
        private _document: Document,
        private _platform: Platform,
        private _overlayContainer: OverlayContainer,
        private scopedViewportRuler: ScopedViewportRuler,
        private scopedOverlayPaneAdjuster: ScopedOverlayPaneAdjuster) {
        this.setOrigin(connectedTo);
    }

    /** Attaches this position strategy to an overlay. */
    attach(overlayRef: OverlayReference): void {
        if (this._overlayRef && overlayRef !== this._overlayRef) {
            throw Error('This position strategy is already attached to an overlay');
        }

        this._validatePositions();

        overlayRef.hostElement.classList.add(boundingBoxClass);

        this._overlayRef = overlayRef;
        this._boundingBox = overlayRef.hostElement;
        this._pane = overlayRef.overlayElement;
        this._isDisposed = false;
        this._isInitialRender = true;
        this._lastPosition = null;

        const repositionPopup = (isFromResizeEvent = false) => {
            setTimeout(() => {
                // When the window is resized, we want to trigger the next reposition as if it
                // was an initial render, in order for the strategy to pick a new optimal position,
                // otherwise position locking will cause it to stay at the old one.
                this._isInitialRender = true;
                this.apply(isFromResizeEvent);
            });
        }

        // reposition on viewport size change
        this._viewportRuler.change()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => repositionPopup());

        // reposition on app column size change
        this.scopedViewportRuler.sizeChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => repositionPopup());

        // reposition when virtual keyboard is displayed
        window.visualViewport?.addEventListener('resize', () => {
            this._handleVirtualKeyboardOnOpen();

            if (this._virtualKeyboardVisible) {
                // Reposition overlay to fit around virtual keyboard when virtual keyboard opens
                repositionPopup(true);
            } else if (this._willCloseOnVirtualKeyboardToggle && this._virtualKeyboardPreviouslyVisible) {
                // Detach overlay when virtual keyboard closes
                this._overlayRef?.detach();
                this._handleVirtualKeyboardOnClose();
            }
        });
    }

    /**
     * Updates the position of the overlay element, using whichever preferred position relative
     * to the origin best fits on-screen.
     *
     * The selection of a position goes as follows:
     *  - If any positions fit completely within the viewport as-is,
     *      choose the first position that does so.
     *  - If flexible dimensions are enabled and at least one satifies the given minimum width/height,
     *      choose the position with the greatest available size modified by the positions' weight.
     *  - If pushing is enabled, take the position that went off-screen the least and push it
     *      on-screen.
     *  - If none of the previous criteria were met, use the position that goes off-screen the least.
     * @docs-private
     */
    apply(isFromResizeEvent = false): void {
        // Should only run on apply and not when apply is triggered by viewport resize event
        if (!isFromResizeEvent) {
            this._handleVirtualKeyboardOnOpen();
        }

        // We shouldn't do anything if the strategy was disposed or we're on the server.
        if (this._isDisposed || !this._platform.isBrowser) {
            return;
        }

        // If the position has been applied already (e.g. when the overlay was opened) and the
        // consumer opted into locking in the position, re-use the old position, in order to
        // prevent the overlay from jumping around.
        if (!this._isInitialRender && this._positionLocked && this._lastPosition) {
            this.reapplyLastPosition();
            return;
        }

        this._clearPanelClasses();
        this._resetOverlayElementStyles();
        this._resetBoundingBoxStyles();

        // We need the bounding rects for the origin and the overlay to determine how to position
        // the overlay relative to the origin.
        // We use the viewport rect to determine whether a position would go off-screen.
        this._viewportRect = this._getNarrowedViewportRect(isFromResizeEvent);
        this._originRect = this._getOriginRect();
        this._overlayRect = this._pane.getBoundingClientRect();

        const originRect = this._originRect;
        const overlayRect = this._overlayRect;
        const viewportRect = this._viewportRect;

        // Positions where the overlay will fit with flexible dimensions.
        const flexibleFits: FlexibleFit[] = [];

        // Fallback if none of the preferred positions fit within the viewport.
        let fallback: FallbackPosition | undefined;

        // Go through each of the preferred positions looking for a good fit.
        // If a good fit is found, it will be applied immediately.
        for (const pos of this._preferredPositions) {
            // Get the exact (x, y) coordinate for the point-of-origin on the origin element.
            const originPoint = this._getOriginPoint(originRect, pos);

            // From that point-of-origin, get the exact (x, y) coordinate for the top-left corner of the
            // overlay in this position. We use the top-left corner for calculations and later translate
            // this into an appropriate (top, left, bottom, right) style.
            const overlayPoint = this._getOverlayPoint(originPoint, overlayRect, pos);

            // Calculate how well the overlay would fit into the viewport with this point.
            const overlayFit = this._getOverlayFit(overlayPoint, overlayRect, viewportRect, pos);

            // If the overlay, without any further work, fits into the viewport, use this position.
            if (overlayFit.isCompletelyWithinViewport) {
                this._isPushed = false;
                this._applyPosition(pos, originPoint);
                return;
            }

            // If the overlay has flexible dimensions, we can use this position
            // so long as there's enough space for the minimum dimensions.
            if (this._canFitWithFlexibleDimensions(overlayFit, overlayPoint, viewportRect)) {
                // Save positions where the overlay will fit with flexible dimensions. We will use these
                // if none of the positions fit *without* flexible dimensions.
                flexibleFits.push({
                    position: pos,
                    origin: originPoint,
                    overlayRect,
                    boundingBoxRect: this._calculateBoundingBoxRect(originPoint, pos),
                });

                continue;
            }

            // If the current preferred position does not fit on the screen, remember the position
            // if it has more visible area on-screen than we've seen and move onto the next preferred
            // position.
            if (!fallback || fallback.overlayFit.visibleArea < overlayFit.visibleArea) {
                fallback = {overlayFit, overlayPoint, originPoint, position: pos, overlayRect};
            }
        }

        // If there are any positions where the overlay would fit with flexible dimensions, choose the
        // one that has the greatest area available modified by the position's weight
        if (flexibleFits.length) {
            let bestFit: FlexibleFit | null = null;
            let bestScore = -1;
            for (const fit of flexibleFits) {
                const score =
                    fit.boundingBoxRect.width * fit.boundingBoxRect.height * (fit.position.weight || 1);
                if (score > bestScore) {
                    bestScore = score;
                    bestFit = fit;
                }
            }

            this._isPushed = false;
            this._applyPosition(bestFit.position, bestFit.origin);
            return;
        }

        // When none of the preferred positions fit within the viewport, take the position
        // that went off-screen the least and attempt to push it on-screen.
        if (this._canPush) {
            // TODO(jelbourn): after pushing, the opening "direction" of the overlay might not make sense.
            this._isPushed = true;
            this._applyPosition(fallback.position, fallback.originPoint);
            return;
        }

        // All options for getting the overlay within the viewport have been exhausted, so go with the
        // position that went off-screen the least.
        this._applyPosition(fallback.position, fallback.originPoint);
    }

    detach(): void {
        this._clearVirtualKeyboardScroll();
        this._clearPanelClasses();
        this._lastPosition = null;
        this._previousPushAmount = null;
        this.ngUnsubscribe.next();
        window.visualViewport?.removeEventListener('resize', () => null);
    }

    /** Cleanup after the element gets destroyed. */
    dispose(): void {
        this._handleVirtualKeyboardOnClose();

        if (this._isDisposed) {
            return;
        }

        // We can't use `_resetBoundingBoxStyles` here, because it resets
        // some properties to zero, rather than removing them.
        if (this._boundingBox) {
            extendStyles(this._boundingBox.style, {
                top: '',
                left: '',
                right: '',
                bottom: '',
                height: '',
                width: '',
                alignItems: '',
                justifyContent: '',
            } as CSSStyleDeclaration);
        }

        if (this._pane) {
            this._resetOverlayElementStyles();
        }

        if (this._overlayRef) {
            this._overlayRef.hostElement.classList.remove(boundingBoxClass);
        }

        this.detach();
        this._positionChanges.complete();
        this._overlayRef = this._boundingBox = null;
        this._isDisposed = true;
    }

    /**
     * This re-aligns the overlay element with the trigger in its last calculated position,
     * even if a position higher in the "preferred positions" list would now fit. This
     * allows one to re-align the panel without changing the orientation of the panel.
     */
    reapplyLastPosition(): void {
        if (!this._isDisposed && (!this._platform || this._platform.isBrowser)) {
            this._originRect = this._getOriginRect();
            this._overlayRect = this._pane.getBoundingClientRect();
            this._viewportRect = this._getNarrowedViewportRect();

            const lastPosition = this._lastPosition || this._preferredPositions[0];
            const originPoint = this._getOriginPoint(this._originRect, lastPosition);

            this._applyPosition(lastPosition, originPoint);
        }
    }

    /**
     * Sets the list of Scrollable containers that host the origin element so that
     * on reposition we can evaluate if it or the overlay has been clipped or outside view. Every
     * Scrollable must be an ancestor element of the strategy's origin element.
     */
    withScrollableContainers(scrollables: CdkScrollable[]): this {
        this._scrollables = scrollables;
        return this;
    }

    /**
     * Adds new preferred positions.
     * @param positions List of positions options for this overlay.
     */
    withPositions(positions: ConnectedPosition[]): this {
        this._preferredPositions = positions;

        // If the last calculated position object isn't part of the positions anymore, clear
        // it in order to avoid it being picked up if the consumer tries to re-apply.
        if (positions.indexOf(this._lastPosition) === -1) {
            this._lastPosition = null;
        }

        this._validatePositions();

        return this;
    }

    /**
     * Sets a minimum distance the overlay may be positioned to the edge of the viewport.
     * @param margin Required margin between the overlay and the viewport edge in pixels.
     */
    withViewportMargin(margin: number): this {
        this._viewportMargin = margin;
        return this;
    }

    /** Sets whether the overlay's width and height can be constrained to fit within the viewport. */
    withFlexibleDimensions(flexibleDimensions = true): this {
        this._hasFlexibleDimensions = flexibleDimensions;
        return this;
    }

    /** Sets whether the overlay can grow after the initial open via flexible width/height. */
    withGrowAfterOpen(growAfterOpen = true): this {
        this._growAfterOpen = growAfterOpen;
        return this;
    }

    /** Sets whether the overlay can be pushed on-screen if none of the provided positions fit. */
    withPush(canPush = true): this {
        this._canPush = canPush;
        return this;
    }

    /**
     * Sets whether the overlay's position should be locked in after it is positioned
     * initially. When an overlay is locked in, it won't attempt to reposition itself
     * when the position is re-applied (e.g. when the user scrolls away).
     * @param isLocked Whether the overlay should locked in.
     */
    withLockedPosition(isLocked = true): this {
        this._positionLocked = isLocked;
        return this;
    }

    /**
     * Sets the origin, relative to which to position the overlay.
     * Using an element origin is useful for building components that need to be positioned
     * relatively to a trigger (e.g. dropdown menus or tooltips), whereas using a point can be
     * used for cases like contextual menus which open relative to the user's pointer.
     * @param origin Reference to the new origin.
     */
    setOrigin(origin: FlexibleConnectedPositionStrategyOrigin): this {
        this._origin = origin;
        return this;
    }

    /**
     * Sets the default offset for the overlay's connection point on the x-axis.
     * @param offset New offset in the X axis.
     */
    withDefaultOffsetX(offset: number): this {
        this._offsetX = offset;
        return this;
    }

    /**
     * Sets the default offset for the overlay's connection point on the y-axis.
     * @param offset New offset in the Y axis.
     */
    withDefaultOffsetY(offset: number): this {
        this._offsetY = offset;
        return this;
    }

    /**
     * Configures that the position strategy should set a `transform-origin` on some elements
     * inside the overlay, depending on the current position that is being applied. This is
     * useful for the cases where the origin of an animation can change depending on the
     * alignment of the overlay.
     * @param selector CSS selector that will be used to find the target
     *    elements onto which to set the transform origin.
     */
    withTransformOriginOn(selector: string): this {
        this._transformOriginSelector = selector;
        return this;
    }

    /**
     * Sets whether or not the overlay should be closed when the virtual keyboard is closed
     */
    withCloseOnVirtualKeyboardToggle(willClose = false): this {
        this._willCloseOnVirtualKeyboardToggle = willClose;
        return this;
    }

    /**
     * When the virtual keyboard is displayed we need to track that state in order to handle
     * properly positioning the overlay and properly scrolling the viewport to best accommodate
     * the overlay within the space provided
     */
    private _handleVirtualKeyboardOnOpen(): void {
        const viewportOriginalHeight = this._rootScrollingElement?.getBoundingClientRect()?.height; // getBoundingClientRect().height used instead of clientHeight to produce correct number
        const scrollBarThickness = parseInt(getComputedStyle(document.body).getPropertyValue('--app__scrollbarWidth--unStyled'), 10);
        this._virtualKeyboardVisible = viewportOriginalHeight - scrollBarThickness > window.visualViewport?.height;
        if (this._virtualKeyboardVisible) {
            this._virtualKeyboardPreviouslyVisible = true;
            this._previousScrollDistance = this._rootScrollingElement.scrollTop;
        }
    }

    /**
     * Clean up styles and properties after virtual keyboard is closed
     */
    private _handleVirtualKeyboardOnClose(): void {
        if (this._willCloseOnVirtualKeyboardToggle && this._virtualKeyboardPreviouslyVisible) {
            this._virtualKeyboardPreviouslyVisible = false;
            document.body.style.removeProperty('min-height');
        }
    }

    /**
     * Gets the (x, y) coordinate of a connection point on the origin based on a relative position.
     */
    private _getOriginPoint(originRect: Dimensions, pos: ConnectedPosition): Point {
        let x: number;
        if (pos.originX === 'center') {
            // Note: when centering we should always use the `left`
            // offset, otherwise the position will be wrong in RTL.
            x = originRect.left + originRect.width / 2;
        } else {
            const startX = this._isRtl() ? originRect.right : originRect.left;
            const endX = this._isRtl() ? originRect.left : originRect.right;
            x = pos.originX === 'start' ? startX : endX;
        }

        let y: number;
        if (pos.originY === 'center') {
            y = originRect.top + originRect.height / 2;
        } else {
            y = pos.originY === 'top' ? originRect.top : originRect.bottom;
        }

        return {x, y};
    }

    /**
     * Gets the (x, y) coordinate of the top-left corner of the overlay given a given position and
     * origin point to which the overlay should be connected.
     */
    private _getOverlayPoint(
        originPoint: Point,
        overlayRect: Dimensions,
        pos: ConnectedPosition,
    ): Point {
        // Calculate the (overlayStartX, overlayStartY), the start of the
        // potential overlay position relative to the origin point.
        let overlayStartX: number;
        if (pos.overlayX === 'center') {
            overlayStartX = -overlayRect.width / 2;
        } else if (pos.overlayX === 'start') {
            overlayStartX = this._isRtl() ? -overlayRect.width : 0;
        } else {
            overlayStartX = this._isRtl() ? 0 : -overlayRect.width;
        }

        let overlayStartY: number;
        if (pos.overlayY === 'center') {
            overlayStartY = -overlayRect.height / 2;
        } else {
            overlayStartY = pos.overlayY === 'top' ? 0 : -overlayRect.height;
        }

        // The (x, y) coordinates of the overlay.
        return {
            x: originPoint.x + overlayStartX,
            y: originPoint.y + overlayStartY,
        };
    }

    /** Gets how well an overlay at the given point will fit within the viewport. */
    private _getOverlayFit(
        point: Point,
        rawOverlayRect: Dimensions,
        viewport: Dimensions,
        position: ConnectedPosition,
    ): OverlayFit {
        // Round the overlay rect when comparing against the
        // viewport, because the viewport is always rounded.
        const overlay = getRoundedBoundingClientRect(rawOverlayRect);
        let {x, y} = point;
        const offsetX = this._getOffset(position, 'x');
        const offsetY = this._getOffset(position, 'y');

        // Account for the offsets since they could push the overlay out of the viewport.
        if (offsetX) {
            x += offsetX;
        }

        if (offsetY) {
            y += offsetY;
        }

        // How much the overlay would overflow at this position, on each side.
        const leftOverflow = 0 - x;
        const rightOverflow = x + overlay.width - viewport.width;
        const topOverflow = 0 - y;
        const bottomOverflow = y + overlay.height - viewport.height;

        // Visible parts of the element on each axis.
        const visibleWidth = this._subtractOverflows(overlay.width, leftOverflow, rightOverflow);
        const visibleHeight = this._subtractOverflows(overlay.height, topOverflow, bottomOverflow);
        const visibleArea = visibleWidth * visibleHeight;

        return {
            visibleArea,
            isCompletelyWithinViewport: overlay.width * overlay.height === visibleArea,
            fitsInViewportVertically: visibleHeight === overlay.height,
            fitsInViewportHorizontally: visibleWidth === overlay.width,
        };
    }

    /**
     * Whether the overlay can fit within the viewport when it may resize either its width or height.
     * @param fit How well the overlay fits in the viewport at some position.
     * @param point The (x, y) coordinates of the overlat at some position.
     * @param viewport The geometry of the viewport.
     */
    private _canFitWithFlexibleDimensions(fit: OverlayFit, point: Point, viewport: Dimensions) {
        if (this._hasFlexibleDimensions) {
            const availableHeight = viewport.bottom - point.y;
            const availableWidth = viewport.right - point.x;
            const minHeight = getPixelValue(this._overlayRef.getConfig().minHeight);
            const minWidth = getPixelValue(this._overlayRef.getConfig().minWidth);

            const verticalFit =
                fit.fitsInViewportVertically || (minHeight !== null && minHeight <= availableHeight);
            const horizontalFit =
                fit.fitsInViewportHorizontally || (minWidth !== null && minWidth <= availableWidth);

            return verticalFit && horizontalFit;
        }
        return false;
    }

    /**
     * Gets the point at which the overlay can be "pushed" on-screen. If the overlay is larger than
     * the viewport, the top-left corner will be pushed on-screen (with overflow occuring on the
     * right and bottom).
     *
     * @param start Starting point from which the overlay is pushed.
     * @param overlay Dimensions of the overlay.
     * @param scrollPosition Current viewport scroll position.
     * @returns The point at which to position the overlay after pushing. This is effectively a new
     *     originPoint.
     */
    private _pushOverlayOnScreen(
        start: Point,
        rawOverlayRect: Dimensions,
        scrollPosition: ViewportScrollPosition): Point {
        // If the position is locked and we've pushed the overlay already, reuse the previous push
        // amount, rather than pushing it again. If we were to continue pushing, the element would
        // remain in the viewport, which goes against the expectations when position locking is enabled.
        if (this._previousPushAmount && this._positionLocked) {
            return {
                x: start.x + this._previousPushAmount.x,
                y: start.y + this._previousPushAmount.y,
            };
        }

        // Round the overlay rect when comparing against the
        // viewport, because the viewport is always rounded.
        const overlay = getRoundedBoundingClientRect(rawOverlayRect);
        const viewport = this._viewportRect;

        // Determine how much the overlay goes outside the viewport on each
        // side, which we'll use to decide which direction to push it.
        const overflowRight = Math.max(start.x + overlay.width - viewport.width, 0);
        const overflowBottom = Math.max(start.y + overlay.height - viewport.height, 0);
        const overflowTop = Math.max(viewport.top - scrollPosition.top - start.y, 0);
        const overflowLeft = Math.max(viewport.left - scrollPosition.left - start.x, 0);

        // Amount by which to push the overlay in each axis such that it remains on-screen.
        let pushX = 0;
        let pushY = 0;

        // If the overlay fits completely within the bounds of the viewport, push it from whichever
        // direction is goes off-screen. Otherwise, push the top-left corner such that its in the
        // viewport and allow for the trailing end of the overlay to go out of bounds.
        if (overlay.width <= viewport.width) {
            pushX = overflowLeft || -overflowRight;
        } else {
            pushX = start.x < this._viewportMargin ? viewport.left - scrollPosition.left - start.x : 0;
        }

        if (overlay.height <= viewport.height) {
            pushY = overflowTop || -overflowBottom;
        } else {
            pushY = start.y < this._viewportMargin ? viewport.top - scrollPosition.top - start.y : 0;
        }

        this._previousPushAmount = {x: pushX, y: pushY};

        return {
            x: start.x + pushX,
            y: start.y + pushY,
        };
    }

    /**
     * Applies a computed position to the overlay and emits a position change.
     * @param position The position preference
     * @param originPoint The point on the origin element where the overlay is connected.
     */
    private _applyPosition(position: ConnectedPosition, originPoint: Point) {
        this._setTransformOrigin(position);
        this._setOverlayElementStyles(originPoint, position);
        this._setBoundingBoxStyles(originPoint, position);

        if (position.panelClass) {
            this._addPanelClasses(position.panelClass);
        }
        this._addPanelCustomProperties(position);

        if (this.scopedOverlayPaneAdjuster) {
            this.scopedOverlayPaneAdjuster.apply(this._pane, this._origin);
        }

        // Save the last connected position in case the position needs to be re-calculated.
        this._lastPosition = position;

        // Notify that the position has been changed along with its change properties.
        // We only emit if we've got any subscriptions, because the scroll visibility
        // calculations can be somewhat expensive.
        if (this._positionChanges.observers.length) {
            const scrollableViewProperties = this._getScrollVisibility();
            const changeEvent = new ConnectedOverlayPositionChange(position, scrollableViewProperties);
            this._positionChanges.next(changeEvent);
        }

        this._isInitialRender = false;
    }

    /** Sets the transform origin based on the configured selector and the passed-in position.  */
    private _setTransformOrigin(position: ConnectedPosition) {
        if (!this._transformOriginSelector) {
            return;
        }

        const elements: NodeListOf<HTMLElement> = this._boundingBox.querySelectorAll(
            this._transformOriginSelector,
        );
        let xOrigin: 'left' | 'right' | 'center';
        const yOrigin: 'top' | 'bottom' | 'center' = position.overlayY;

        if (position.overlayX === 'center') {
            xOrigin = 'center';
        } else if (this._isRtl()) {
            xOrigin = position.overlayX === 'start' ? 'right' : 'left';
        } else {
            xOrigin = position.overlayX === 'start' ? 'left' : 'right';
        }

        for (let i = 0; i < elements.length; i++) {
            elements[i].style.transformOrigin = `${xOrigin} ${yOrigin}`;
        }
    }

    /**
     * Gets the position and size of the overlay's sizing container.
     *
     * This method does no measuring and applies no styles so that we can cheaply compute the
     * bounds for all positions and choose the best fit based on these results.
     */
    private _calculateBoundingBoxRect(origin: Point, position: ConnectedPosition): BoundingBoxRect {
        const viewport = this._viewportRect;
        const isRtl = this._isRtl();
        const offsetTop = origin.y + viewport.top;
        const isTop = position.overlayY === 'top';
        const isBottom = position.overlayY === 'bottom';
        let height: number, top: number, bottom: number;

        if (isTop || isBottom) {
            if (!this._virtualKeyboardVisible) {
                if (isTop) {
                    // Overlay is opening "downward" and thus is bound by the bottom viewport edge.
                    top = offsetTop;
                    height = viewport.height - origin.y - this._viewportMargin;
                } else if (isBottom) {
                    // Overlay is opening "upward" and thus is bound by the top viewport edge. We need to add
                    // the viewport margin back in, because the viewport rect is narrowed down to remove the
                    // margin, whereas the `origin` position is calculated based on its `ClientRect`.
                    bottom = viewport.height - offsetTop + this._viewportMargin * 2;
                    height = origin.y + this._viewportMargin;
                }
            } else {
                const visualViewportHeight = Math.round(window.visualViewport?.height);
                const visualViewportSpacing = 14;
                let scrollDistance;
                if (isTop) {
                    top = origin.y;

                    // Reduce the height from the original container to fit within the reduced visual viewport
                    // The element will be scrolled to the top of the viewport so it's height will be based on the entire viewport height
                    height = visualViewportHeight - (visualViewportSpacing * 2) - this._viewportMargin;

                    // Scroll so that the top of the origin is at the top of the visual viewport
                    scrollDistance = origin.y + visualViewportSpacing;
                } else if (isBottom) {
                    // Calculate the bottom position based on the bottom of the container element instead of using height comparisons
                    bottom = this._overlayContainer.getContainerElement().scrollHeight - origin.y;

                    // Reduce the height from the original container to fit within the reduced visual viewport
                    height = visualViewportHeight - visualViewportSpacing - this._viewportMargin;

                    // Scroll so that the bottom of the origin is at the bottom of the visual viewport
                    const originElement = this._origin as HTMLElement;
                    const originBottom = origin.y + originElement.clientHeight;
                    scrollDistance = originBottom - visualViewportHeight + visualViewportSpacing;
                }

                this._virtualKeyboardScrollDistance = scrollDistance > 0 ? scrollDistance : 0;
                if (scrollDistance > 0) {
                    // Ensure smooth scrolling is disabled before scrolling the container
                    const rootScrollingElement = this._rootScrollingElement as HTMLElement;
                    rootScrollingElement.style.scrollBehavior = 'auto';

                    // Scroll origin to the top when opening downward or to the bottom when opening upward
                    document.body.style.minHeight = `${this._rootScrollingElement.scrollHeight + scrollDistance}px`;
                    this._rootScrollingElement.scrollTop = scrollDistance;
                }
            }
        } else {
            // If neither top nor bottom, it means that the overlay is vertically centered on the
            // origin point. Note that we want the position relative to the viewport, rather than
            // the page, which is why we don't use something like `viewport.bottom - origin.y` and
            // `origin.y - viewport.top`.
            const smallestDistanceToViewportEdge = Math.min(
                viewport.bottom - origin.y + viewport.top,
                origin.y,
            );

            const previousHeight = this._lastBoundingBoxSize.height;

            height = smallestDistanceToViewportEdge * 2;
            top = origin.y - smallestDistanceToViewportEdge;

            if (height > previousHeight && !this._isInitialRender && !this._growAfterOpen) {
                top = origin.y - previousHeight / 2;
            }
        }

        // The overlay is opening 'right-ward' (the content flows to the right).
        const isBoundedByRightViewportEdge =
            (position.overlayX === 'start' && !isRtl) || (position.overlayX === 'end' && isRtl);

        // The overlay is opening 'left-ward' (the content flows to the left).
        const isBoundedByLeftViewportEdge =
            (position.overlayX === 'end' && !isRtl) || (position.overlayX === 'start' && isRtl);

        let width: number, left: number, right: number;

        if (isBoundedByLeftViewportEdge) {
            right = viewport.width - origin.x + this._viewportMargin;
            width = origin.x - this._viewportMargin;
        } else if (isBoundedByRightViewportEdge) {
            left = origin.x;
            width = viewport.right - origin.x;
        } else {
            // If neither start nor end, it means that the overlay is horizontally centered on the
            // origin point. Note that we want the position relative to the viewport, rather than
            // the page, which is why we don't use something like `viewport.right - origin.x` and
            // `origin.x - viewport.left`.
            const smallestDistanceToViewportEdge = Math.min(
                viewport.right - origin.x + viewport.left,
                origin.x,
            );
            const previousWidth = this._lastBoundingBoxSize.width;

            width = smallestDistanceToViewportEdge * 2;
            left = origin.x - smallestDistanceToViewportEdge;

            if (width > previousWidth && !this._isInitialRender && !this._growAfterOpen) {
                left = origin.x - previousWidth / 2;
            }
        }

        return {top: top, left: left, bottom: bottom, right: right, width, height};
    }

    /**
     * Sets the position and size of the overlay's sizing wrapper. The wrapper is positioned on the
     * origin's connection point and stetches to the bounds of the viewport.
     *
     * @param origin The point on the origin element where the overlay is connected.
     * @param position The position preference
     */
    private _setBoundingBoxStyles(origin: Point, position: ConnectedPosition): void {
        const boundingBoxRect = this._calculateBoundingBoxRect(origin, position);

        // It's weird if the overlay *grows* while scrolling, so we take the last size into account
        // when applying a new size.
        if (!this._isInitialRender && !this._growAfterOpen) {
            boundingBoxRect.height = Math.min(boundingBoxRect.height, this._lastBoundingBoxSize.height);
            boundingBoxRect.width = Math.min(boundingBoxRect.width, this._lastBoundingBoxSize.width);
        }

        const styles = {} as CSSStyleDeclaration;

        if (this._hasExactPosition()) {
            styles.top = styles.left = '0';
            styles.bottom = styles.right = styles.maxHeight = styles.maxWidth = '';
            styles.width = styles.height = '100%';
        } else {
            const maxHeight = this._overlayRef.getConfig().maxHeight;
            const maxWidth = this._overlayRef.getConfig().maxWidth;

            styles.height = coerceCssPixelValue(boundingBoxRect.height);
            styles.top = coerceCssPixelValue(boundingBoxRect.top);
            styles.bottom = coerceCssPixelValue(boundingBoxRect.bottom);
            styles.width = coerceCssPixelValue(boundingBoxRect.width);
            styles.left = coerceCssPixelValue(boundingBoxRect.left);
            styles.right = coerceCssPixelValue(boundingBoxRect.right);

            // Push the pane content towards the proper direction.
            if (position.overlayX === 'center') {
                styles.alignItems = 'center';
            } else {
                styles.alignItems = position.overlayX === 'end' ? 'flex-end' : 'flex-start';
            }

            if (position.overlayY === 'center') {
                styles.justifyContent = 'center';
            } else {
                styles.justifyContent = position.overlayY === 'bottom' ? 'flex-end' : 'flex-start';
            }

            if (maxHeight) {
                styles.maxHeight = coerceCssPixelValue(maxHeight);
            }

            if (maxWidth) {
                styles.maxWidth = coerceCssPixelValue(maxWidth);
            }
        }

        this._lastBoundingBoxSize = boundingBoxRect;

        extendStyles(this._boundingBox.style, styles);
    }

    /** Resets the styles for the bounding box so that a new positioning can be computed. */
    private _resetBoundingBoxStyles() {
        extendStyles(this._boundingBox.style, {
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            height: '',
            width: '',
            alignItems: '',
            justifyContent: '',
        } as CSSStyleDeclaration);
    }

    /** Resets the styles for the overlay pane so that a new positioning can be computed. */
    private _resetOverlayElementStyles() {
        extendStyles(this._pane.style, {
            top: '',
            left: '',
            bottom: '',
            right: '',
            position: '',
            transform: '',
        } as CSSStyleDeclaration);
    }

    /** Sets positioning styles to the overlay element. */
    private _setOverlayElementStyles(originPoint: Point, position: ConnectedPosition): void {
        const styles = {} as CSSStyleDeclaration;
        const hasExactPosition = this._hasExactPosition();
        const hasFlexibleDimensions = this._hasFlexibleDimensions;
        const config = this._overlayRef.getConfig();

        if (hasExactPosition) {
            const scrollPosition = getContainerScrollPosition(this._overlayContainer.getContainerElement());
            extendStyles(styles, this._getExactOverlayY(position, originPoint, scrollPosition));
            extendStyles(styles, this._getExactOverlayX(position, originPoint, scrollPosition));
        } else {
            styles.position = 'static';
        }

        // Use a transform to apply the offsets. We do this because the `center` positions rely on
        // being in the normal flex flow and setting a `top` / `left` at all will completely throw
        // off the position. We also can't use margins, because they won't have an effect in some
        // cases where the element doesn't have anything to "push off of". Finally, this works
        // better both with flexible and non-flexible positioning.
        let transformString = '';
        const offsetX = this._getOffset(position, 'x');
        const offsetY = this._getOffset(position, 'y');

        if (offsetX) {
            transformString += `translateX(${offsetX}px) `;
        }

        if (offsetY) {
            transformString += `translateY(${offsetY}px)`;
        }

        styles.transform = transformString.trim();

        // If a maxWidth or maxHeight is specified on the overlay, we remove them. We do this because
        // we need these values to both be set to "100%" for the automatic flexible sizing to work.
        // The maxHeight and maxWidth are set on the boundingBox in order to enforce the constraint.
        // Note that this doesn't apply when we have an exact position, in which case we do want to
        // apply them because they'll be cleared from the bounding box.
        if (config.maxHeight) {
            if (hasExactPosition) {
                styles.maxHeight = coerceCssPixelValue(config.maxHeight);
            } else if (hasFlexibleDimensions) {
                styles.maxHeight = '';
            }
        }

        if (config.maxWidth) {
            if (hasExactPosition) {
                styles.maxWidth = coerceCssPixelValue(config.maxWidth);
            } else if (hasFlexibleDimensions) {
                styles.maxWidth = '';
            }
        }

        extendStyles(this._pane.style, styles);
    }

    /** Gets the exact top/bottom for the overlay when not using flexible sizing or when pushing. */
    private _getExactOverlayY(
        position: ConnectedPosition,
        originPoint: Point,
        scrollPosition: ViewportScrollPosition,
    ) {
        // Reset any existing styles. This is necessary in case the
        // preferred position has changed since the last `apply`.
        const styles = {top: null, bottom: null} as CSSStyleDeclaration;
        let overlayPoint = this._getOverlayPoint(originPoint, this._overlayRect, position);

        if (this._isPushed) {
            overlayPoint = this._pushOverlayOnScreen(overlayPoint, this._overlayRect, scrollPosition);
        }

        const virtualKeyboardOffset = this._overlayContainer
            .getContainerElement()
            .getBoundingClientRect().top;

        // Normally this would be zero, however when the overlay is attached to an input (e.g. in an
        // autocomplete), mobile browsers will shift everything in order to put the input in the middle
        // of the screen and to make space for the virtual keyboard. We need to account for this offset,
        // otherwise our positioning will be thrown off.
        overlayPoint.y -= virtualKeyboardOffset;

        // We want to set either `top` or `bottom` based on whether the overlay wants to appear
        // above or below the origin and the direction in which the element will expand.
        if (position.overlayY === 'bottom') {
            // When using `bottom`, we adjust the y position such that it is the distance
            // from the bottom of the viewport rather than the top.
            const documentHeight = this._document.documentElement.clientHeight;
            styles.bottom = `${documentHeight - (overlayPoint.y + this._overlayRect.height)}px`;
        } else {
            styles.top = coerceCssPixelValue(overlayPoint.y);
        }

        return styles;
    }

    /** Gets the exact left/right for the overlay when not using flexible sizing or when pushing. */
    private _getExactOverlayX(
        position: ConnectedPosition,
        originPoint: Point,
        scrollPosition: ViewportScrollPosition,
    ) {
        // Reset any existing styles. This is necessary in case the preferred position has
        // changed since the last `apply`.
        const styles = {left: null, right: null} as CSSStyleDeclaration;
        let overlayPoint = this._getOverlayPoint(originPoint, this._overlayRect, position);

        if (this._isPushed) {
            overlayPoint = this._pushOverlayOnScreen(overlayPoint, this._overlayRect, scrollPosition);
        }

        // We want to set either `left` or `right` based on whether the overlay wants to appear "before"
        // or "after" the origin, which determines the direction in which the element will expand.
        // For the horizontal axis, the meaning of "before" and "after" change based on whether the
        // page is in RTL or LTR.
        let horizontalStyleProperty: 'left' | 'right';

        if (this._isRtl()) {
            horizontalStyleProperty = position.overlayX === 'end' ? 'left' : 'right';
        } else {
            horizontalStyleProperty = position.overlayX === 'end' ? 'right' : 'left';
        }

        // When we're setting `right`, we adjust the x position such that it is the distance
        // from the right edge of the viewport rather than the left edge.
        if (horizontalStyleProperty === 'right') {
            const documentWidth = this._document.documentElement.clientWidth;
            styles.right = `${documentWidth - (overlayPoint.x + this._overlayRect.width)}px`;
        } else {
            styles.left = coerceCssPixelValue(overlayPoint.x);
        }

        return styles;
    }

    /**
     * Gets the view properties of the trigger and overlay, including whether they are clipped
     * or completely outside the view of any of the strategy's scrollables.
     */
    private _getScrollVisibility(): ScrollingVisibility {
        // Note: needs fresh rects since the position could've changed.
        const originBounds = this._getOriginRect();
        const overlayBounds = this._pane.getBoundingClientRect();

        // TODO(jelbourn): instead of needing all of the client rects for these scrolling containers
        // every time, we should be able to use the scrollTop of the containers if the size of those
        // containers hasn't changed.
        const scrollContainerBounds = this._scrollables.map(scrollable => {
            return scrollable.getElementRef().nativeElement.getBoundingClientRect();
        });

        return {
            isOriginClipped: isElementClippedByScrolling(originBounds, scrollContainerBounds),
            isOriginOutsideView: isElementScrolledOutsideView(originBounds, scrollContainerBounds),
            isOverlayClipped: isElementClippedByScrolling(overlayBounds, scrollContainerBounds),
            isOverlayOutsideView: isElementScrolledOutsideView(overlayBounds, scrollContainerBounds),
        };
    }

    /** Subtracts the amount that an element is overflowing on an axis from its length. */
    private _subtractOverflows(length: number, ...overflows: number[]): number {
        return overflows.reduce((currentValue: number, currentOverflow: number) => {
            return currentValue - Math.max(currentOverflow, 0);
        }, length);
    }

    /** Narrows the given viewport rect by the current _viewportMargin. */
    private _getNarrowedViewportRect(isFromResizeEvent?: boolean): Dimensions {
        // We recalculate the viewport rect here ourselves, rather than using the ViewportRuler,
        // because we want to use the `clientWidth` and `clientHeight` as the base. The difference
        // being that the client properties don't include the scrollbar, as opposed to `innerWidth`
        // and `innerHeight` that do. This is necessary, because the overlay container uses
        // 100% `width` and `height` which don't include the scrollbar either.
        const containerElement = this._overlayContainer.getContainerElement();
        const width = containerElement.clientWidth;
        const scrollPosition = getContainerScrollPosition(containerElement);
        let top;
        let bottom;
        let height;

        if (!isFromResizeEvent && this._virtualKeyboardVisible) {
            // Set properties based on reduced viewport size due to virtual keyboard when the overlay is opened
            // after the keyboard has already been displayed. This type of event occurs for things like an autocomplete
            // where focusing the text box opens the virtual keyboard but the overlay is not opened until there are
            // matching results and enough characters have been typed.
            const viewportTop = window.visualViewport?.pageTop;
            const viewportHeight = window.visualViewport?.height;
            top = viewportTop + this._viewportMargin;
            bottom = viewportTop + viewportHeight - this._viewportMargin;
            height = viewportHeight - 2 * this._viewportMargin;
        } else {
            top = scrollPosition.top + this._viewportMargin;
            bottom = scrollPosition.top + containerElement.clientHeight - this._viewportMargin;
            height = containerElement.clientHeight - 2 * this._viewportMargin;
        }

        return {
            top,
            left: scrollPosition.left + this._viewportMargin,
            right: scrollPosition.left + width - this._viewportMargin,
            bottom,
            width: width - 2 * this._viewportMargin,
            height,
        };
    }

    /** Whether the we're dealing with an RTL context */
    private _isRtl() {
        return this._overlayRef.getDirection() === 'rtl';
    }

    /** Determines whether the overlay uses exact or flexible positioning. */
    private _hasExactPosition() {
        return !this._hasFlexibleDimensions || this._isPushed;
    }

    /** Retrieves the offset of a position along the x or y axis. */
    private _getOffset(position: ConnectedPosition, axis: 'x' | 'y') {
        if (axis === 'x') {
            // We don't do something like `position['offset' + axis]` in
            // order to avoid breking minifiers that rename properties.
            return position.offsetX === null ? this._offsetX : position.offsetX;
        }

        return position.offsetY === null ? this._offsetY : position.offsetY;
    }

    /** Validates that the current position match the expected values. */
    private _validatePositions(): void {
        if (!this._preferredPositions.length) {
            throw Error('FlexibleConnectedPositionStrategy: At least one position is required.');
        }

        // TODO(crisbeto): remove these once Angular's template type
        // checking is advanced enough to catch these cases.
        this._preferredPositions.forEach(pair => {
            validateHorizontalPosition('originX', pair.originX);
            validateVerticalPosition('originY', pair.originY);
            validateHorizontalPosition('overlayX', pair.overlayX);
            validateVerticalPosition('overlayY', pair.overlayY);
        });
    }

    /** Adds custom properties to be used for pointer position on the overlay panel. */
    private _addPanelCustomProperties(position: ConnectedPosition) {
        if (this._pane && this._originRect) {
            // Add the width of the overlay content
            this._addPanelCustomPropertiesContentWidths();

            // Set width properties to help position the pointer
            this._addPanelCustomPropertiesDimensions(this._originRect.width, this._pane.clientWidth, '--soScopedOverlay__pointerContainerWidth');

            // Set height properties to help position the pointer
            this._addPanelCustomPropertiesDimensions(this._originRect.height, this._pane.clientHeight, '--soScopedOverlay__pointerContainerHeight');

            // If vertically centered midpoints aren't aligned, adjust the pointer position
            this._addAdjustedPanelCustomProperties();

            // Add X and Y offset as custom propeties
            this._addPanelCustomPropertiesOffset(position, 'x');
            this._addPanelCustomPropertiesOffset(position, 'y');
        }
    }

    private _addPanelCustomPropertiesDimensions(originValue: number, paneValue: number, customProperty: string) {
        const pointerContainerValue = paneValue > originValue ? originValue : paneValue;
        this._pane.style.setProperty(customProperty, `${Math.round(pointerContainerValue)}px`);
    }

    private _addPanelCustomPropertiesOffset(position: ConnectedPosition, axis: 'x' | 'y') {
        const axisFormatted = axis.toUpperCase();
        const cssPropertyName = `soScopedOverlay__offset${axisFormatted}`;
        const offset = this._getOffset(position, axis);
        let offsetValue = offset ? offset : 0;
        if (offset < 0) {
            offsetValue = (offset * -1);
            this._pane.classList.add(`${cssPropertyName}--negative`);
        } else {
            this._pane.classList.add(`${cssPropertyName}--positive`);
        }

        this._pane.style.setProperty(`--${cssPropertyName}`, `${offsetValue}px`);
    }

    private _addAdjustedPanelCustomProperties() {
        if (this._pane.classList.contains('soScopedOverlay__rightCenter') ||
            this._pane.classList.contains('soScopedOverlay__leftCenter')) {
            const boundingBoxRect = this._boundingBox.getBoundingClientRect();
            const boundingRectHalfHeight = Math.round(boundingBoxRect.height / 2);
            const boundingRectMidpoint = boundingBoxRect.top + boundingRectHalfHeight;
            const paneMidpoint = Math.round(boundingBoxRect.top + (this._pane.scrollHeight / 2));
            if (paneMidpoint > boundingRectMidpoint) {
                this._pane.classList.add('soScopedOverlay__verticalCenterAdjusted');
                this._pane.style.setProperty('--soScopedOverlay__pointerContainerHeight', `${boundingRectHalfHeight}px`);
            }
        }
    }

    private _addPanelCustomPropertiesContentWidths() {
        if (this._overlayRef.getConfig().maxWidth) {
            this._customPropertiesContentWidths('Max');
        }

        if (this._overlayRef.getConfig().minWidth) {
            this._customPropertiesContentWidths('Min');
        }
    }

    private _customPropertiesContentWidths(type: 'Max' | 'Min') {
        const cssPropertyName = `--soScopedOverlay__contentWidth${type}`;
        const width = type === 'Min' ?
            `${this._overlayRef.getConfig().minWidth}px` :
            `${this._overlayRef.getConfig().maxWidth}px`;
        this._pane.style.setProperty(cssPropertyName, width);
    }

    /** Adds a single CSS class or an array of classes on the overlay panel. */
    private _addPanelClasses(cssClasses: string | string[]) {
        if (this._pane) {
            coerceArray(cssClasses).forEach(cssClass => {
                if (cssClass !== '' && this._appliedPanelClasses.indexOf(cssClass) === -1) {
                    this._appliedPanelClasses.push(cssClass);
                    this._pane.classList.add(cssClass);
                }
            });
        }
    }

    /** Clears the classes that the position strategy has applied from the overlay panel. */
    private _clearPanelClasses() {
        if (this._pane) {
            this._appliedPanelClasses.forEach(cssClass => {
                this._pane.classList.remove(cssClass);
            });
            this._appliedPanelClasses = [];
        }
    }

    /** Removes scroll vor virtual keyboard */
    private _clearVirtualKeyboardScroll(): void {
        if (this._virtualKeyboardScrollDistance > 0) {
            this._rootScrollingElement.scrollTop = this._previousScrollDistance;
            this._previousScrollDistance = 0;
            this._virtualKeyboardScrollDistance = 0;
        }
    }

    /** Returns the ClientRect of the current origin. */
    private _getOriginRect(): Dimensions {
        const origin = this._origin;
        const container = this._overlayContainer.getContainerElement();

        if (origin instanceof ElementRef) {
            return this.boundingBoxRespectToParent(origin.nativeElement, container);
        }

        // Check for Element so SVG elements are also supported.
        if (origin instanceof Element) {
            const originElement = origin as HTMLElement;
            return this.boundingBoxRespectToParent(originElement, container);
        }

        const width = origin.width || 0;
        const height = origin.height || 0;

        // If the origin is a point, return a client rect as if it was a 0x0 element at the point.
        return {
            top: origin.y,
            bottom: origin.y + height,
            left: origin.x,
            right: origin.x + width,
            height,
            width,
        };
    }

    private boundingBoxRespectToParent(elem: HTMLElement, container: HTMLElement) {
        const elemRect = elem.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        return {
            top: Math.floor(elemRect.top - containerRect.top),
            bottom: Math.floor(elemRect.top - containerRect.top + elemRect.height),
            left: Math.floor(elemRect.left - containerRect.left),
            right: Math.floor(elemRect.left - containerRect.left + elemRect.width),
            height: Math.floor(elemRect.height),
            width: Math.floor(elemRect.width),
        };
    }
}

/** A simple (x, y) coordinate. */
interface Point {
    x: number;
    y: number;
}

/** Record of measurements for how an overlay (at a given position) fits into the viewport. */
interface OverlayFit {
    /** Whether the overlay fits completely in the viewport. */
    isCompletelyWithinViewport: boolean;

    /** Whether the overlay fits in the viewport on the y-axis. */
    fitsInViewportVertically: boolean;

    /** Whether the overlay fits in the viewport on the x-axis. */
    fitsInViewportHorizontally: boolean;

    /** The total visible area (in px^2) of the overlay inside the viewport. */
    visibleArea: number;
}

/** Record of the measurments determining whether an overlay will fit in a specific position. */
interface FallbackPosition {
    position: ConnectedPosition;
    originPoint: Point;
    overlayPoint: Point;
    overlayFit: OverlayFit;
    overlayRect: Dimensions;
}

/** Position and size of the overlay sizing wrapper for a specific position. */
interface BoundingBoxRect {
    top: number;
    left: number;
    bottom: number;
    right: number;
    height: number;
    width: number;
}

/** Record of measures determining how well a given position will fit with flexible dimensions. */
interface FlexibleFit {
    position: ConnectedPosition;
    origin: Point;
    overlayRect: Dimensions;
    boundingBoxRect: BoundingBoxRect;
}

/** A connected position as specified by the user. */
export interface ConnectedPosition {
    originX: 'start' | 'center' | 'end';
    originY: 'top' | 'center' | 'bottom';

    overlayX: 'start' | 'center' | 'end';
    overlayY: 'top' | 'center' | 'bottom';

    weight?: number;
    offsetX?: number;
    offsetY?: number;
    panelClass?: string | string[];
}

/** Shallow-extends a stylesheet object with another stylesheet object. */
function extendStyles(
    destination: CSSStyleDeclaration,
    source: CSSStyleDeclaration,
): CSSStyleDeclaration {
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            destination[key] = source[key];
        }
    }

    return destination;
}

/** Gets the (top, left) scroll position of the viewport. */
function getContainerScrollPosition(container: HTMLElement): ViewportScrollPosition {
    const top = container.scrollTop || 0;
    const left = container.scrollLeft || 0;

    return { top, left };
}

/**
 * Extracts the pixel value as a number from a value, if it's a number
 * or a CSS pixel string (e.g. `1337px`). Otherwise returns null.
 */
function getPixelValue(input: number | string | null | undefined): number | null {
    if (typeof input !== 'number' && input != null) {
        const [value, units] = input.split(cssUnitPattern);
        return !units || units === 'px' ? parseFloat(value) : null;
    }

    return input as number || null;
}

/**
 * Gets a version of an element's bounding `ClientRect` where all the values are rounded down to
 * the nearest pixel. This allows us to account for the cases where there may be sub-pixel
 * deviations in the `ClientRect` returned by the browser (e.g. when zoomed in with a percentage
 * size, see #21350).
 */
function getRoundedBoundingClientRect(clientRect: Dimensions): Dimensions {
    return {
        top: Math.floor(clientRect.top),
        right: Math.floor(clientRect.right),
        bottom: Math.floor(clientRect.bottom),
        left: Math.floor(clientRect.left),
        width: Math.floor(clientRect.width),
        height: Math.floor(clientRect.height),
    };
}
