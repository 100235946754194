/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CreditCardProcessingTermsApiEnum = 'AuthorizeAtCheckoutCaptureOnFulfillment' | 'ProcessOnFulfillment' | 'ProcessDaysInAdvanceOfFulfillment';

export const CreditCardProcessingTermsApiEnum = {
    AuthorizeAtCheckoutCaptureOnFulfillment: 'AuthorizeAtCheckoutCaptureOnFulfillment' as CreditCardProcessingTermsApiEnum,
    ProcessOnFulfillment: 'ProcessOnFulfillment' as CreditCardProcessingTermsApiEnum,
    ProcessDaysInAdvanceOfFulfillment: 'ProcessDaysInAdvanceOfFulfillment' as CreditCardProcessingTermsApiEnum
};

