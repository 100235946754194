export const BATCH = 'BATCH';
export const ADD_APP_COLUMN = 'ADD_APP_COLUMN';
export const REMOVE_APP_COLUMN = 'REMOVE_APP_COLUMN';
export const SELECT_APP_COLUMN = 'SELECT_APP_COLUMN';
export const RESIZE_APP_COLUMN = 'RESIZE_APP_COLUMN';
export const MOVE_APP_COLUMN = 'MOVE_APP_COLUMN';
export const MOVE_APP_COLUMN_ONSTAGE = 'MOVE_APP_COLUMN_ONSTAGE';
export const ADD_STACKED_APP_COLUMN = 'ADD_STACKED_APP_COLUMN';
export const REMOVE_STACKED_APP_COLUMN = 'REMOVE_STACKED_APP_COLUMN';
export const ADJUST_SIZING_AND_OFFSTAGE_STATUS = 'ADJUST_SIZING_AND_OFFSTAGE_STATUS';
export const RESTORE_APP = 'RESTORE_APP';
export const ADD_APP_COLUMN_URL_CHANGE = 'ADD_APP_COLUMN_URL_CHANGE';
export const ADD_STACKED_APP_COLUMN_URL_CHANGE = 'ADD_STACKED_APP_COLUMN_URL_CHANGE';
export const REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE = 'REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE';
export const SET_APP_COLUMN_TITLE_AND_AVATAR = 'SET_APP_COLUMN_TITLE_AND_AVATAR';
export const SHOW_MAX_COLUMN_WARNING = 'SHOW_MAX_COLUMN_WARNING';
export const SET_REPLACEMENT_APP_COLUMN = 'SET_REPLACEMENT_APP_COLUMN';
export const UNSET_REPLACEMENT_APP_COLUMN = 'UNSET_REPLACEMENT_APP_COLUMN';
export const UPDATE_APP_COLUMN_UNSAVED_DATA = 'UPDATE_APP_COLUMN_UNSAVED_DATA';
export const REMOVE_APP_COLUMN_UNSAVED_DATA = 'REMOVE_APP_COLUMN_UNSAVED_DATA';
export const SLIDE_TO_APP_COLUMN = 'SLIDE_TO_APP_COLUMN';
