import { Component, ContentChild, Directive, Input, OnInit } from '@angular/core';
import { Task } from '../shared/task.type';
import { TaskStatus } from '../shared/task-status.type';
import { TaskStatusService } from '../shared/task-status.service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-md-media' })
export class TaskStatusIconMdMediaDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-md-subject' })
export class TaskStatusIconMdSubjectDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-md-body' })
export class TaskStatusIconMdBodyDirective {}

@Component({
    selector: 'tsk-task-status-icon-md',
    templateUrl: './task-status-icon-md.component.html',
    styleUrls: ['./task-status-icon-md.component.scss'],
    providers: [TaskStatusService]
})
export class TaskStatusIconMdComponent implements OnInit {
    @Input() tasks: Task[];
    @ContentChild(TaskStatusIconMdSubjectDirective) subject: TaskStatusIconMdSubjectDirective;
    status: TaskStatus;
    statusClass: string;
    earliestDueDate: number;
    TaskStatusEnum = TaskStatus;

    constructor(private taskStatusService: TaskStatusService) {
    }

    ngOnInit() {
        if (this.tasks && this.tasks.length > 0) {
            this.earliestDueDate = this.tasks[0].dueDate;
            this.tasks.forEach((task) => {
                if (task.dueDate < this.earliestDueDate) {
                    this.earliestDueDate = task.dueDate;
                }
            });
        }
        this.status = this.taskStatusService.getStatus(this.earliestDueDate);
        this.statusClass = this.taskStatusService.getStatusClass(this.status);
    }
}
