/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BestTimeToReachApiEnum } from './bestTimeToReachApiEnum';


export interface ContactApiModel { 
    contactId: number;
    accountId: string;
    name: string;
    canReceiveText: boolean;
    phoneNumber?: string | null;
    emailAddress?: string | null;
    avatarUrl?: string | null;
    bestTimeToReach?: BestTimeToReachApiEnum;
    isEmailUndeliverable: boolean;
    isPhoneNumberInvalid: boolean;
}

