/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CommunicationTypeApiEnum = 'Email' | 'Text' | 'Voice' | 'PostMail';

export const CommunicationTypeApiEnum = {
    Email: 'Email' as CommunicationTypeApiEnum,
    Text: 'Text' as CommunicationTypeApiEnum,
    Voice: 'Voice' as CommunicationTypeApiEnum,
    PostMail: 'PostMail' as CommunicationTypeApiEnum
};

