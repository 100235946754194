/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileApiModel } from './profileApiModel';
import { NotificationTypeApiEnum } from './notificationTypeApiEnum';
import { ContactApiModel } from './contactApiModel';
import { CommunicationTypeApiEnum } from './communicationTypeApiEnum';
import { AddressApiModel } from './addressApiModel';


export interface NotificationPreferenceApiModel { 
    notificationPreferenceId: number;
    accountId: string;
    communicationType: CommunicationTypeApiEnum;
    notificationType: NotificationTypeApiEnum;
    profile?: ProfileApiModel;
    address?: AddressApiModel;
    contact?: ContactApiModel;
}

