import { HttpEvent, HttpResponse } from '@angular/common/http';

/**
 * This class is for adapting TInputs that can be objects or arrays and are being adapted to
 * a single object.
 */
export abstract class Adapter<TInput, TOutput> {
    public adaptHttpEvent(event: HttpEvent<TInput> | HttpEvent<Array<TInput>>): HttpEvent<TOutput>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public adaptHttpEvent(event: HttpEvent<any>): HttpEvent<any> {
        if (event instanceof HttpResponse) {
            const adaptedResponse = this.adaptHttpResponse(event);
            return adaptedResponse as HttpEvent<TOutput>;
        }

        // Note: HttpUserEvent<TInput> will not be adapted to HttUserEvent<TOutput>
        // If we need to adapt custom events then we can figure that out when we have these events.
        return event;
    }

    public adaptHttpResponse(response: HttpResponse<TInput> | HttpResponse<Array<TInput>>): HttpResponse<TOutput>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public adaptHttpResponse(response: HttpResponse<any>): HttpResponse<any> {
        const body: TOutput = this.adapt(response.body);
        return response.clone({ body });
    }

    public adaptItem(item: TInput | Array<TInput>): TOutput;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public adaptItem(item: any): any {
        return this.adapt(item);
    }

    protected abstract adapt(item: TInput): TOutput;
}
