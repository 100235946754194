import { Component, Directive, Input, OnInit } from '@angular/core';
import { TaskStatus } from '../shared/task-status.type';
import { Task } from '../shared/task.type'
import { TaskStatusService } from '../shared/task-status.service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-sm-media' })
export class TaskStatusIconSmMediaDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-sm-body' })
export class TaskStatusIconSmBodyDirective {}

@Component({
    selector: 'tsk-task-status-icon-sm',
    templateUrl: './task-status-icon-sm.component.html',
    styleUrls: ['./task-status-icon-sm.component.scss'],
    providers: [TaskStatusService]
})
export class TaskStatusIconSmComponent implements OnInit {
    @Input() tasks: Task[];
    status: TaskStatus;
    statusClass: string;
    earliestDueDate: number;
    TaskStatusEnum = TaskStatus;

    constructor(private taskStatusService: TaskStatusService) {}

    ngOnInit() {
        if (this.tasks && this.tasks.length > 0) {
            this.earliestDueDate = this.tasks[0].dueDate;
            this.tasks.forEach((task) => {
                if (task.dueDate < this.earliestDueDate) {
                    this.earliestDueDate = task.dueDate;
                }
            });
        }
        this.status = this.taskStatusService.getStatus(this.earliestDueDate);
        this.statusClass = this.taskStatusService.getStatusClass(this.status);
    }
}
