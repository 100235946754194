import { NgModule } from '@angular/core';
import { CmnFormatDateToCurrentProfileLocalTime } from './format-date-to-current-profile-timezone.pipe';

export { CmnFormatDateToCurrentProfileLocalTime } from './format-date-to-current-profile-timezone.pipe';

@NgModule({
    declarations: [CmnFormatDateToCurrentProfileLocalTime],
    exports: [CmnFormatDateToCurrentProfileLocalTime]
})
export class FormatDateToCurrentProfileTimezoneModule {}
