import { Component, Input } from '@angular/core';
import { TaskStatus } from '../task-status.type';
import { TimeDiffService } from '../time-diff.service';
import { TaskStatusService } from '../task-status.service';

@Component({
    selector: 'tsk-task-status-message',
    templateUrl: './task-status-message.component.html',
    styleUrls: ['./task-status-message.component.scss'],
    providers: [TimeDiffService, TaskStatusService]
})
export class TaskStatusMessageComponent {
    @Input() numberOfTasks: number;
    @Input() objectType = 'item';
    status: TaskStatus;
    dueDateStr = '';
    durationUntilDueDate = '';
    TaskStatusEnum = TaskStatus;

    @Input() set dueDate(date: number) {
        this.status = this.taskStatusService.getStatus(date);
        const timeDiff = this.timeDiffService.getTimeDiff(date);
        this.dueDateStr = timeDiff.dueDate;
        this.durationUntilDueDate = timeDiff.durationUntilDueDate;
    }

    constructor(
        private timeDiffService: TimeDiffService,
        private taskStatusService: TaskStatusService) {}
}
