import { Inject, Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Platform, _isTestEnvironment } from '@angular/cdk/platform';

@Injectable()
export class ScopedOverlayContainer extends OverlayContainer {

    private _scopedContainerElement: HTMLElement; // Don't use this._containerElement from OverlayContainer because it gets destroyed which can cause issues with animations
    private _alternateContainerElement: HTMLElement;

    constructor(@Inject(DOCUMENT) normalDocument: Document, platform: Platform) {
        super(normalDocument, platform);
    }

    setContainerElement(containerElement: HTMLElement) {
        this._scopedContainerElement = containerElement;
    }

    setAlternateContainerElement(containerElement: HTMLElement) {
        this._alternateContainerElement = containerElement;
    }

    getContainerElement(): HTMLElement {
        if (this._alternateContainerElement &&
            this._scopedContainerElement.scrollHeight <= this._scopedContainerElement.clientHeight) {
            return this._alternateContainerElement;
        }

        // Provide support for scoped overlay in unit tests
        if (_isTestEnvironment()) {
            return super.getContainerElement();
        }

        return this._scopedContainerElement;
    }
}
