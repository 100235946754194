import { Component, Input } from '@angular/core';

@Component({
    selector: 'hdr-header-section',
    templateUrl: './header-section.component.html',
    styleUrls: ['./header-section.component.scss']
})
export class HeaderSectionComponent {
    @Input() count: number;
}
