export const environment = {
    production: true,
    apiBaseUrl: '',
    serverBaseUrl: 'https://operationcenter.spaldingproducts.com',
    appInsights: {
        instrumentationKey: '7ac859aa-c8f9-4e6c-8ca0-369ba06d4cd8'
    },
    orderManagerUrl: 'https://ordermanager.spaldingproducts.com',
    appVersion: 'ProductionAppVersion',
    appInsightsRoleName: 'operation-center',
    azureMapClientId: '3ddc1b9e-c4b3-411b-a309-3e44622f19f0',
    logoutTimeoutWarningInMinutes: 30
};
