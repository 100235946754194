import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'comInitials'
})
export class InitialsPipe implements PipeTransform {
    transform(value: string) {
        if (!value) {
            return value;
        }
        if (typeof value !== 'string') {
            throw new Error(`comInitials: Invalid value "${value}"`);
        }

        const names = value.trim().split(' ');
        let initials = names[0].charAt(0).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].charAt(0).toUpperCase();
        } else {
            initials += names[0].charAt(1);
        }
        return initials;
    }
}
