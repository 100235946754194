import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PlaygroundModule } from 'angular-playground';
import { SandboxesDefined } from './sandboxes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../apps/operation-center/src/environments/environment';
import { enableProdMode } from '@angular/core';
import { ScrollbarWidthCalcModule } from '@center-suite/shared/ui/common';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(PlaygroundModule
    .configure({
        selector: 'ap-root',
        overlay: false,
        modules: [
            BrowserAnimationsModule,
            ScrollbarWidthCalcModule,
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
        ],
        sandboxesDefined: SandboxesDefined
    }))
    .catch(err => console.error(err));
