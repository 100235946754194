import { NgModule } from '@angular/core';
import {
    ToggleLayoutComponent,
    ToggleLayoutContentDirective,
} from './toggle-layout.component';
import { ToggleLayoutFooterComponent } from './toggle-layout-footer/toggle-layout-footer.component';

@NgModule({
    declarations: [
        ToggleLayoutComponent,
        ToggleLayoutContentDirective,
        ToggleLayoutFooterComponent
    ],
    exports: [
        ToggleLayoutComponent,
        ToggleLayoutContentDirective,
        ToggleLayoutFooterComponent
    ]
})
export class ToggleLayoutModule {}
