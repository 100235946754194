import {
    Directive,
    DoCheck,
    ElementRef,
    Input,
    KeyValueChangeRecord,
    KeyValueChanges,
    KeyValueDiffer,
    KeyValueDiffers,
    Renderer2
} from '@angular/core';

@Directive({
    selector: '[accAria]'
})
export class AriaDirective implements DoCheck {
    private attributeList: {[key: string]: string} = {};
    private keyValueDiffer: KeyValueDiffer<string, string>;

    constructor(
        private keyValueDiffers: KeyValueDiffers,
        private elementRef: ElementRef,
        private renderer: Renderer2) {}

    @Input() set accAria(attributeList: { [key: string]: string | boolean | null }) {
        this.keyValueDiffer = null;
        if (attributeList) {
            this.attributeList = this.buildAttributeList(attributeList);
            this.keyValueDiffer = this.keyValueDiffers.find(this.attributeList).create();
        }
    }

    ngDoCheck(): void {
        const changes = this.keyValueDiffer.diff(this.attributeList);
        if (changes) {
            this.applyKeyValueChanges(changes);
        }
    }

    private applyKeyValueChanges(changes: KeyValueChanges<string, string>): void {
        changes.forEachAddedItem((record: KeyValueChangeRecord<string, string>) => {
            this.updateAttribute(record.key, record.currentValue);
        });
        changes.forEachChangedItem((record: KeyValueChangeRecord<string, string>) => {
            this.updateAttribute(record.key, record.currentValue);
        });
        changes.forEachRemovedItem((record: KeyValueChangeRecord<string, string>) => {
            if (record.previousValue) {
                this.updateAttribute(record.key, null);
            }
        });
    }

    private updateAttribute(name: string, value: string): void {
        this.renderer.setAttribute(this.elementRef.nativeElement, `aria-${name}`, value);
    }

    private buildAttributeList(attributeList: { [key: string]: string | boolean }) {
        const list = {};
        for (const key in attributeList) {
          if(attributeList.hasOwnProperty(key)) {
            const value = attributeList[key] || '';
            list[key] = value.toString();
          }
        }
        return list;
    }
}
