import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'comStripHtml'
})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string) {
        if (!value) {
            return value;
        }
        if (typeof value !== 'string') {
            throw new Error(`comStripHtml: invalid value "${value}"`);
        }

        return value.replace(/<(?:.|\n)*?>/gm, '');
    }
}
