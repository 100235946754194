import { NgModule } from '@angular/core';
import { CurrentTimeDirective } from './current-time.directive';

export { CurrentTimeDirective } from './current-time.directive';

@NgModule({
    declarations: [CurrentTimeDirective],
    exports: [CurrentTimeDirective],
})
export class CurrentTimeModule { }
