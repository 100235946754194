/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Injector, Optional, Type }      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse,
         HttpEvent, HttpParameterCodec, HttpErrorResponse }  from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable, of, throwError }                        from 'rxjs';
import { map, retryWhen, catchError }                        from 'rxjs/operators';

import { AddUpdateNoteRequest } from '../models/models';
import { BadRequestResponse } from '../models/models';
import { InternalServerErrorResponse } from '../models/models';
import { NoteApiModel } from '../models/models';
import { NoteSearchPageResponseApiModel } from '../models/models';
import { UnauthorizedResponse } from '../models/models';

import { AppColumnFactory }                                  from '@center-suite/shared/framework/app-column-framework';
import { Adapter, APP_COLUMN_ID, httpRequestRetryStrategy }  from '@center-suite/shared/data-access/utility';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AddNoteRequestParams {
    accountId: string | null;
    addUpdateNoteRequest?: AddUpdateNoteRequest;
}

export interface GetNoteByIdRequestParams {
    id: number;
}

export interface SearchNotesRequestParams {
    accountId: string | null;
    sortBy: 'DateLastModified_Desc' | 'DateCreated_Desc';
    after?: string;
    before?: string;
    pageSize?: number;
}


@Injectable({
  providedIn: 'root'
})
export class NotesService {

    protected basePath = 'https://localhost:10000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(private injector: Injector, protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration, @Optional() private appColumnFactory: AppColumnFactory) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addNote<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: AddNoteRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public addNote<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: AddNoteRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public addNote<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: AddNoteRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public addNote<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: AddNoteRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const accountId = requestParameters.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addNote.');
        }
        const addUpdateNoteRequest = requestParameters.addUpdateNoteRequest;

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<NoteApiModel>(`${this.configuration.basePath}/api/accounts/${encodeURIComponent(String(accountId))}/notes`,
            addUpdateNoteRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('post' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<NoteApiModel> | NoteApiModel | HttpEvent<NoteApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<NoteApiModel>);
                } else {
                    return adapter.adaptItem(res as NoteApiModel);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNoteById<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: GetNoteByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public getNoteById<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: GetNoteByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public getNoteById<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: GetNoteByIdRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public getNoteById<TReturn, TAdapter extends Adapter<NoteApiModel, TReturn>>(requestParameters: GetNoteByIdRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getNoteById.');
        }

        const adapter = this.injector.get(tAdapter);

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<NoteApiModel>(`${this.configuration.basePath}/api/accounts/notes/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<NoteApiModel> | NoteApiModel | HttpEvent<NoteApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<NoteApiModel>);
                } else {
                    return adapter.adaptItem(res as NoteApiModel);
                }
            })
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchNotes<TReturn, TAdapter extends Adapter<NoteSearchPageResponseApiModel, TReturn>>(requestParameters: SearchNotesRequestParams, tAdapter: Type<TAdapter>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TReturn>;
    public searchNotes<TReturn, TAdapter extends Adapter<NoteSearchPageResponseApiModel, TReturn>>(requestParameters: SearchNotesRequestParams, tAdapter: Type<TAdapter>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TReturn>>;
    public searchNotes<TReturn, TAdapter extends Adapter<NoteSearchPageResponseApiModel, TReturn>>(requestParameters: SearchNotesRequestParams, tAdapter: Type<TAdapter>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TReturn>>;
    public searchNotes<TReturn, TAdapter extends Adapter<NoteSearchPageResponseApiModel, TReturn>>(requestParameters: SearchNotesRequestParams, tAdapter: Type<TAdapter>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const accountId = requestParameters.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling searchNotes.');
        }
        const sortBy = requestParameters.sortBy;
        if (sortBy === null || sortBy === undefined) {
            throw new Error('Required parameter sortBy was null or undefined when calling searchNotes.');
        }
        const after = requestParameters.after;
        const before = requestParameters.before;
        const pageSize = requestParameters.pageSize;

        const adapter = this.injector.get(tAdapter);

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'SortBy');
        }
        if (after !== undefined && after !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>after, 'After');
        }
        if (before !== undefined && before !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>before, 'Before');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'PageSize');
        }

        let headers = this.defaultHeaders;

        // Apply App Column ID for barber pole animation (per-column loading indicator)
        if (this.appColumnFactory) {
            headers = headers.set(APP_COLUMN_ID, this.appColumnFactory.appColumnId.toString());
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<NoteSearchPageResponseApiModel>(`${this.configuration.basePath}/api/accounts/${encodeURIComponent(String(accountId))}/notes`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            retryWhen(httpRequestRetryStrategy()),
            catchError((error: HttpErrorResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if ('get' === 'get' && error.status === 404) {
                    return of(null);
                }
                return throwError(() => error);
            }),
            map((res: HttpResponse<NoteSearchPageResponseApiModel> | NoteSearchPageResponseApiModel | HttpEvent<NoteSearchPageResponseApiModel> | null) => {
                if (res === null) {
                    return null;
                }

                if (res instanceof HttpResponse) {
                    return adapter.adaptHttpResponse(res);
                } else if (observe === 'event') {
                    return adapter.adaptHttpEvent(res as HttpEvent<NoteSearchPageResponseApiModel>);
                } else {
                    return adapter.adaptItem(res as NoteSearchPageResponseApiModel);
                }
            })
        );
    }

}
