import { Injectable } from '@angular/core';
import { BATCH, MOVE_APP_COLUMN_ONSTAGE, SELECT_APP_COLUMN, SLIDE_TO_APP_COLUMN } from '../actions';
import { AppColumn } from '../../../app-column-router/app-column';
import { AppColumnAction, BatchAction } from '../action-types';

@Injectable({
    providedIn: 'root'
})
export class SlideToAppColumnCreator {
    slideTo(appColumnId: number, viewportWidth: number, appColumns: AppColumn[]): BatchAction {
        let actions: AppColumnAction[] = [{
            type: SELECT_APP_COLUMN,
            payload: {
                appColumnId,
                dateAccessed: Date.now()
            }
        }];

        if (appColumns.length > 1) {
            actions = [
                ...actions,
                {
                    type: SLIDE_TO_APP_COLUMN,
                    payload: { appColumnId }
                },
                {
                    type: MOVE_APP_COLUMN_ONSTAGE,
                    payload: { viewportWidth }
                }
            ];
        }

        return {
            type: BATCH,
            payload: actions
        };
    }
}
