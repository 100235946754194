export interface Address {
    addressId?: number;
    attentionTo?: string;
    street1: string;
    street2?: string;
    city?: string;
    stateRegion?: StateRegionCode;
    postalCode: string;
    country: CountryCode;
    timezoneOffsetInSeconds?: number;
    latitude?: number;
    longitude?: number;
    isInvalid?: boolean;
}

export type CountryCode = 'US' | 'CA' | 'MX' | 'FR';
export const CountryCode = {
    US: 'US' as CountryCode,
    CA: 'CA' as CountryCode,
    MX: 'MX' as CountryCode,
    FR: 'FR' as CountryCode
};

export type StateRegionCode = 'AL' | 'AK' | 'AZ' | 'AR' | 'CA' | 'CO' | 'CT' | 'DE' | 'FL' | 'GA' | 'HI' | 'ID' | 'IL' | 'IN' | 'IA' | 'KS' | 'KY' | 'LA' | 'ME' | 'MD' | 'MA' | 'MI' | 'MN' | 'MS' | 'MO' | 'MT' | 'NE' | 'NV' | 'NH' | 'NJ' | 'NM' | 'NY' | 'NC' | 'ND' | 'OH' | 'OK' | 'OR' | 'PA' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VT' | 'VA' | 'WA' | 'WV' | 'WI' | 'WY' | 'DC' | 'AA' | 'AP' | 'AE';
export const StateRegionCode = {
    AL: 'AL' as StateRegionCode,
    AK: 'AK' as StateRegionCode,
    AR: 'AR' as StateRegionCode,
    AZ: 'AZ' as StateRegionCode,
    CA: 'CA' as StateRegionCode,
    CO: 'CO' as StateRegionCode,
    CT: 'CT' as StateRegionCode,
    DE: 'DE' as StateRegionCode,
    FL: 'FL' as StateRegionCode,
    GA: 'GA' as StateRegionCode,
    HI: 'HI' as StateRegionCode,
    IA: 'IA' as StateRegionCode,
    ID: 'ID' as StateRegionCode,
    IL: 'IL' as StateRegionCode,
    IN: 'IN' as StateRegionCode,
    KS: 'KS' as StateRegionCode,
    KY: 'KY' as StateRegionCode,
    LA: 'LA' as StateRegionCode,
    MA: 'MA' as StateRegionCode,
    MD: 'MD' as StateRegionCode,
    ME: 'ME' as StateRegionCode,
    MI: 'MI' as StateRegionCode,
    MN: 'MN' as StateRegionCode,
    MO: 'MO' as StateRegionCode,
    MS: 'MS' as StateRegionCode,
    MT: 'MT' as StateRegionCode,
    NC: 'NC' as StateRegionCode,
    ND: 'ND' as StateRegionCode,
    NE: 'NE' as StateRegionCode,
    NH: 'NH' as StateRegionCode,
    NJ: 'NJ' as StateRegionCode,
    NM: 'NM' as StateRegionCode,
    NV: 'NV' as StateRegionCode,
    NY: 'NY' as StateRegionCode,
    OH: 'OH' as StateRegionCode,
    OK: 'OK' as StateRegionCode,
    OR: 'OR' as StateRegionCode,
    PA: 'PA' as StateRegionCode,
    RI: 'RI' as StateRegionCode,
    SC: 'SC' as StateRegionCode,
    SD: 'SD' as StateRegionCode,
    TN: 'TN' as StateRegionCode,
    TX: 'TX' as StateRegionCode,
    UT: 'UT' as StateRegionCode,
    VA: 'VA' as StateRegionCode,
    VT: 'VT' as StateRegionCode,
    WA: 'WA' as StateRegionCode,
    WI: 'WI' as StateRegionCode,
    WV: 'WV' as StateRegionCode,
    WY: 'WY' as StateRegionCode,
    AA: 'AA' as StateRegionCode,
    AE: 'AE' as StateRegionCode,
    AP: 'AP' as StateRegionCode,
    DC: 'DC' as StateRegionCode
};
