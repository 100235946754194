/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StateRegionCodeApiEnum = 'AL' | 'AK' | 'AZ' | 'AR' | 'CA' | 'CO' | 'CT' | 'DE' | 'FL' | 'GA' | 'HI' | 'ID' | 'IL' | 'IN' | 'IA' | 'KS' | 'KY' | 'LA' | 'ME' | 'MD' | 'MA' | 'MI' | 'MN' | 'MS' | 'MO' | 'MT' | 'NE' | 'NV' | 'NH' | 'NJ' | 'NM' | 'NY' | 'NC' | 'ND' | 'OH' | 'OK' | 'OR' | 'PA' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VT' | 'VA' | 'WA' | 'WV' | 'WI' | 'WY' | 'AP' | 'AE' | 'AA' | 'DC';

export const StateRegionCodeApiEnum = {
    AL: 'AL' as StateRegionCodeApiEnum,
    AK: 'AK' as StateRegionCodeApiEnum,
    AZ: 'AZ' as StateRegionCodeApiEnum,
    AR: 'AR' as StateRegionCodeApiEnum,
    CA: 'CA' as StateRegionCodeApiEnum,
    CO: 'CO' as StateRegionCodeApiEnum,
    CT: 'CT' as StateRegionCodeApiEnum,
    DE: 'DE' as StateRegionCodeApiEnum,
    FL: 'FL' as StateRegionCodeApiEnum,
    GA: 'GA' as StateRegionCodeApiEnum,
    HI: 'HI' as StateRegionCodeApiEnum,
    ID: 'ID' as StateRegionCodeApiEnum,
    IL: 'IL' as StateRegionCodeApiEnum,
    IN: 'IN' as StateRegionCodeApiEnum,
    IA: 'IA' as StateRegionCodeApiEnum,
    KS: 'KS' as StateRegionCodeApiEnum,
    KY: 'KY' as StateRegionCodeApiEnum,
    LA: 'LA' as StateRegionCodeApiEnum,
    ME: 'ME' as StateRegionCodeApiEnum,
    MD: 'MD' as StateRegionCodeApiEnum,
    MA: 'MA' as StateRegionCodeApiEnum,
    MI: 'MI' as StateRegionCodeApiEnum,
    MN: 'MN' as StateRegionCodeApiEnum,
    MS: 'MS' as StateRegionCodeApiEnum,
    MO: 'MO' as StateRegionCodeApiEnum,
    MT: 'MT' as StateRegionCodeApiEnum,
    NE: 'NE' as StateRegionCodeApiEnum,
    NV: 'NV' as StateRegionCodeApiEnum,
    NH: 'NH' as StateRegionCodeApiEnum,
    NJ: 'NJ' as StateRegionCodeApiEnum,
    NM: 'NM' as StateRegionCodeApiEnum,
    NY: 'NY' as StateRegionCodeApiEnum,
    NC: 'NC' as StateRegionCodeApiEnum,
    ND: 'ND' as StateRegionCodeApiEnum,
    OH: 'OH' as StateRegionCodeApiEnum,
    OK: 'OK' as StateRegionCodeApiEnum,
    OR: 'OR' as StateRegionCodeApiEnum,
    PA: 'PA' as StateRegionCodeApiEnum,
    RI: 'RI' as StateRegionCodeApiEnum,
    SC: 'SC' as StateRegionCodeApiEnum,
    SD: 'SD' as StateRegionCodeApiEnum,
    TN: 'TN' as StateRegionCodeApiEnum,
    TX: 'TX' as StateRegionCodeApiEnum,
    UT: 'UT' as StateRegionCodeApiEnum,
    VT: 'VT' as StateRegionCodeApiEnum,
    VA: 'VA' as StateRegionCodeApiEnum,
    WA: 'WA' as StateRegionCodeApiEnum,
    WV: 'WV' as StateRegionCodeApiEnum,
    WI: 'WI' as StateRegionCodeApiEnum,
    WY: 'WY' as StateRegionCodeApiEnum,
    AP: 'AP' as StateRegionCodeApiEnum,
    AE: 'AE' as StateRegionCodeApiEnum,
    AA: 'AA' as StateRegionCodeApiEnum,
    DC: 'DC' as StateRegionCodeApiEnum
};

