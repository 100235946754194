import { FlexibleConnectedPositionStrategyOrigin, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { ScopedOverlayContainer } from './scoped-overlay-container';
import { ScopedFlexibleConnectedPositionStrategy } from './scoped-flexible-connected-position-strategy';
import { ScopedViewportRuler } from './scoped-viewport-ruler';
import { ScopedOverlayPaneAdjuster } from './scoped-overlay-pane-adjuster';
import { ViewportRuler } from '@angular/cdk/scrolling';

@Injectable()
export class ScopedOverlayPositionBuilder extends OverlayPositionBuilder {
    constructor(private normalViewportRuler: ViewportRuler,
                @Inject(DOCUMENT) private normalDocument: unknown,
                private normalPlatform: Platform,
                private scopedOverlayContainer: ScopedOverlayContainer,
                private injector: Injector,
                @Optional() private scopedOverlayPaneAdjuster?: ScopedOverlayPaneAdjuster) {
        super(
            normalViewportRuler,
            normalDocument,
            normalPlatform,
            scopedOverlayContainer);
    }

    /**
     * Creates a scoped flexible position strategy.
     * @param origin Origin relative to which to position the overlay.
     */
    scopedFlexibleConnectedTo(origin: FlexibleConnectedPositionStrategyOrigin): ScopedFlexibleConnectedPositionStrategy {
        const scopedViewportRuler: ScopedViewportRuler = this.injector.get(ScopedViewportRuler);
        return new ScopedFlexibleConnectedPositionStrategy(
            origin,
            this.normalViewportRuler,
            this.normalDocument as Document,
            this.normalPlatform,
            this.scopedOverlayContainer,
            scopedViewportRuler,
            this.scopedOverlayPaneAdjuster);
    }
}
