import { NgModule } from '@angular/core';
import { CmnHighlightTermPipe } from './highlight-term.pipe';

export { CmnHighlightTermPipe } from './highlight-term.pipe';

@NgModule({
    declarations: [CmnHighlightTermPipe],
    exports: [CmnHighlightTermPipe]
})
export class HighlightTermModule {}
