/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillToInvoiceApiModel } from './billToInvoiceApiModel';
import { BillingMethodApiEnum } from './billingMethodApiEnum';
import { BillToStatusApiEnum } from './billToStatusApiEnum';
import { NoticeSeverityApiEnum } from './noticeSeverityApiEnum';
import { BillToCreditCardApiModel } from './billToCreditCardApiModel';
import { AddressApiModel } from './addressApiModel';


export interface BillToSearchApiModel { 
    billToId: number;
    isDefault: boolean;
    dateCreated: string;
    dateLastModified: string;
    billingMethod: BillingMethodApiEnum;
    address: AddressApiModel;
    status: BillToStatusApiEnum;
    noticeSeverity?: NoticeSeverityApiEnum;
    readonly noticeMessage?: string | null;
    readonly isPreventingFulfillment: boolean;
    readonly dateSuspensionEnds?: string | null;
    creditCardDetails?: BillToCreditCardApiModel;
    invoiceDetails?: BillToInvoiceApiModel;
    matchedOn?: { [key: string]: Array<string>; } | null;
}

