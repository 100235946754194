/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSearchAddressApiModel } from './accountSearchAddressApiModel';


export interface AccountSearchDetailsApiModel { 
    profileId?: number | null;
    name?: string | null;
    organizationName?: string | null;
    avatar?: string | null;
    accountId?: string | null;
    accountTypeId?: number | null;
    signsInWith?: string | null;
    phoneNumber?: string | null;
    emailAddress?: string | null;
    primaryBillingAddress?: AccountSearchAddressApiModel;
}

