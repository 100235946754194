import { AppColumn } from '../../../../app-column-router/app-column';
import { AddAppColumnAction } from '../../action-types';

export const addAppColumn = (appColumns: AppColumn[], action: AddAppColumnAction) => {
    const newAppColumn = new AppColumn(
        action.payload.appColumnId,
        action.payload.url,
        action.payload.routeGroupingKey);

    // TODO: Commented out per DEV-4535 app column changes, replaced with logic below.
    // return [...appColumns, newAppColumn];

    if (appColumns.length > 0) {
        const appColumnsCopy = [...appColumns];
        const firstColumnOnStageIndex = appColumns.findIndex(appColumn => appColumn.isOnStage);
        // Split the array and insert the new column at the start of the columns on stage.
        const left = appColumnsCopy.slice(0, firstColumnOnStageIndex);
        const right = appColumnsCopy.slice(firstColumnOnStageIndex);
        return [...left, newAppColumn, ...right];

    } else {
        return [newAppColumn];
    }
};
