import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Header01Component } from './header-01.component';

export { Header01Component } from './header-01.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        Header01Component
    ],
    exports: [
        Header01Component
    ]
})
export class Header01Module {}
