import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'comNumericSeparated'
})
export class NumericSeparatedPipe implements PipeTransform {
    transform(value: unknown[] | string[], propertyName?: string, numberTrailer= ')') {
        if (!Array.isArray(value)) {
            throw new Error(`comNumericSeparated: Invalid value "${value}"`);
        }

        const items = propertyName ? (value as unknown[]).map(item => item[propertyName]) : value;
        return items
            .map((item, index) => `${index + 1}${numberTrailer} ${item}`)
            .join(' ');
    }
}
