import { style, trigger, transition, animate } from '@angular/animations';
import { DEFAULT_TIMING } from './constants/animation-constants';

export const expandHeightEnterLeaveAnimation = trigger('expandHeightEnterLeave', [
    transition(':enter', [
        style({ height: 0 }),
        animate(
            DEFAULT_TIMING,
            style({ height: '*' })
        )
    ]),
    transition(':leave', [
        animate(
            DEFAULT_TIMING,
            style({ height: 0 })
        )
    ])
]);
