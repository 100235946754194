import { actionRunner } from '../action-runner';
import { REMOVE_APP_COLUMN_UNSAVED_DATA, UPDATE_APP_COLUMN_UNSAVED_DATA } from '../actions';
import {
    RemoveAppColumnUnsavedFormDataAction,
    UpdateAppColumnUnsavedFormDataAction
} from '../action-types';
import { AppColumnUnsavedFormData } from '../state';

export const appColumnUnsavedFormData = actionRunner<AppColumnUnsavedFormData>({
    [UPDATE_APP_COLUMN_UNSAVED_DATA]: (state: AppColumnUnsavedFormData, action: UpdateAppColumnUnsavedFormDataAction) =>
        Object.assign({ ...state }, {
            [action.payload.appColumnId]: {
                value: action.payload.value,
            }
        }),
    [REMOVE_APP_COLUMN_UNSAVED_DATA]: (state: AppColumnUnsavedFormData, action: RemoveAppColumnUnsavedFormDataAction) => {
        const newState = { ...state };
        delete newState[action.payload.appColumnId];
        return newState;
    }
}, {});
