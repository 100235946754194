import { NgModule } from '@angular/core';
import { Document } from './base/document';
import { Window } from './base/window';
import { SessionService } from './base/session.service';
import { StorageService } from './base/storage.service';
import { BrowserSessionService } from './browser/browser-session.service';
import { BrowserStorageService } from './browser/browser-storage.service';
import { BrowserWindow } from './browser/browser-window';
import { BrowserDocument } from './browser/browser-document';

@NgModule({
    providers: [
        { provide: SessionService, useClass: BrowserSessionService },
        { provide: StorageService, useClass: BrowserStorageService },
        { provide: Window, useClass: BrowserWindow },
        { provide: Document, useClass: BrowserDocument }
    ]
})
export class SharedFrameworkPlatformModule {}
