import { Component, Input } from '@angular/core';

@Component({
    selector: 'hdr-header-02-last-word-and-id',
    templateUrl: './header-02-last-word-and-id.component.html',
    styleUrls: ['./header-02-last-word-and-id.component.scss']
})
export class Header02LastWordAndIdComponent {
    @Input() lastWord: string;
    @Input() headerId: number;
}
