import { Component } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[comTotalSummaryLabel]',
    templateUrl: 'total-summary-label.component.html',
    styleUrls: ['total-summary-label.component.scss']
})
export class TotalSummaryLabelComponent {
}
