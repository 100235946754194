import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    TaskStatusIconSmBodyDirective,
    TaskStatusIconSmComponent,
    TaskStatusIconSmMediaDirective
} from './task-status-icon-sm.component';

@NgModule({
    imports: [ CommonModule ],
    declarations: [
        TaskStatusIconSmComponent,
        TaskStatusIconSmMediaDirective,
        TaskStatusIconSmBodyDirective
    ],
    exports: [
        TaskStatusIconSmComponent,
        TaskStatusIconSmMediaDirective,
        TaskStatusIconSmBodyDirective
    ]
})
export class TaskStatusIconSmModule {
}
