import { style, trigger, transition, animate, state } from '@angular/animations';
import { DEFAULT_TIMING } from './constants/animation-constants';

//
// By default using this animation will expand from a collapsed height of zero.
// To change this you can pass it params.
//
// To expand from a height of 4em for example:
// [@expandHeightShowHide]="{
//     value: animationState,
//     params: {
//         collapsedHeight: '4em'
//     }
// }"
//

export const expandHeightShowHideAnimation = trigger('expandHeightShowHide', [
    state('expanded', style({
        height: '*'
    })),
    state('collapsed', style({
        height: '{{ collapsedHeight }}'
    }), {
        params: {
            collapsedHeight: 0
        }
    }),
    transition('expanded <=> collapsed', [
        animate(DEFAULT_TIMING)
    ])
]);
