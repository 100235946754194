import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { DEFAULT_TIMING } from '@center-suite/shared/ui/animation';

export const paneSlideAnimation = trigger('slide', [
    transition('false => true', [
        group([
            query(':enter', [
                style({
                    transform: 'translateX({{ start }}%)',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    opacity: 0
                }),
                group([
                    animate(DEFAULT_TIMING, style({ transform: 'translateX(0%)' })),
                    animate(DEFAULT_TIMING, style({ opacity: 1 }))
                ])
            ]),
            query(':leave', [
                group([
                    animate(DEFAULT_TIMING, style({ transform: 'translateX({{ leave }}%)' })),
                    animate(DEFAULT_TIMING, style({ opacity: 0 }))
                ])
            ])
        ])
    ], { params: { start: '100', leave: '-100' } })
]);
