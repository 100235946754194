import { style, trigger, transition, animate } from '@angular/animations';
import { DEFAULT_TIMING } from './constants/animation-constants';

const scaledOut = 'scale3d(0, 0, 0)';

export const fadeScaleEnterLeaveAnimation = trigger('fadeScaleEnterLeave', [
    transition(':enter', [
        style({
            height: 0,
            opacity: 0,
            transform: scaledOut
        }),
        animate(
            DEFAULT_TIMING,
            style({
                height: '*',
                opacity: 1,
                transform: 'scale3d(1, 1, 1)'
            })
        )
    ]),
    transition(':leave', [
        animate(
            DEFAULT_TIMING,
            style({
                height: 0,
                opacity: 0,
                transform: scaledOut
            })
        )
    ])
]);
