import { Component, ContentChild, Directive } from '@angular/core';
import { CssClassBinderDirective } from '@center-suite/shared/utility/common';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'com-total-summary-header' })
export class TotalSummaryHeaderDirective {}

@Component({
    selector: 'com-total-summary',
    templateUrl: './total-summary.component.html',
    styleUrls: ['./total-summary.component.scss']
})
export class TotalSummaryComponent extends CssClassBinderDirective {
    @ContentChild(TotalSummaryHeaderDirective) header: TotalSummaryHeaderDirective;
}
