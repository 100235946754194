import { Injectable } from '@angular/core';
import { EnumAdapter } from '@center-suite/shared/data-access/utility';
import { DataAccessTypeApiEnum } from '@center-suite/operation-center/shared/data-access/accounts';
import { DataAccessType } from '@center-suite/shared/utility/authorization';

@Injectable({
    providedIn: 'root'
})
export class DataAccessTypeEnumAdapter implements EnumAdapter<DataAccessTypeApiEnum, DataAccessType> {
    apiEnumToViewEnum(apiModel: DataAccessTypeApiEnum): DataAccessType {
        switch (apiModel) {
            case DataAccessTypeApiEnum.Account:
                return DataAccessType.ACCOUNT;
            case DataAccessTypeApiEnum.Tenant:
                return DataAccessType.TENANT;
            case DataAccessTypeApiEnum.AllTenants:
                return DataAccessType.ALL_TENANTS;
            case DataAccessTypeApiEnum.Profile:
                return DataAccessType.PROFILE;
            default:
                return DataAccessType.ANONYMOUS;
        }
    }

    viewEnumToApiEnum(viewModel: DataAccessType): DataAccessTypeApiEnum {
        switch (viewModel) {
            case DataAccessType.ACCOUNT:
                return DataAccessTypeApiEnum.Account;
            case DataAccessType.TENANT:
                return DataAccessTypeApiEnum.Tenant;
            case DataAccessType.ALL_TENANTS:
                return DataAccessTypeApiEnum.AllTenants;
            case DataAccessType.PROFILE:
                return DataAccessTypeApiEnum.Profile;
            default:
                return DataAccessTypeApiEnum.Anonymous;
        }
    }
}
