/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FinancialItemTypeApiEnum = 'Invoice' | 'RefundReceipt' | 'CommissionStatement' | 'BalanceOverride' | 'BalanceRefund' | 'CashPayment' | 'CheckBouncedFee' | 'CheckPayment' | 'CommissionEarned' | 'CommissionEarnedFromDistributees' | 'CommissionLostForRefund' | 'CommissionPayment' | 'CreditCardPayment' | 'Shipment' | 'TaxAdjustment' | 'TransferCredit';

export const FinancialItemTypeApiEnum = {
    Invoice: 'Invoice' as FinancialItemTypeApiEnum,
    RefundReceipt: 'RefundReceipt' as FinancialItemTypeApiEnum,
    CommissionStatement: 'CommissionStatement' as FinancialItemTypeApiEnum,
    BalanceOverride: 'BalanceOverride' as FinancialItemTypeApiEnum,
    BalanceRefund: 'BalanceRefund' as FinancialItemTypeApiEnum,
    CashPayment: 'CashPayment' as FinancialItemTypeApiEnum,
    CheckBouncedFee: 'CheckBouncedFee' as FinancialItemTypeApiEnum,
    CheckPayment: 'CheckPayment' as FinancialItemTypeApiEnum,
    CommissionEarned: 'CommissionEarned' as FinancialItemTypeApiEnum,
    CommissionEarnedFromDistributees: 'CommissionEarnedFromDistributees' as FinancialItemTypeApiEnum,
    CommissionLostForRefund: 'CommissionLostForRefund' as FinancialItemTypeApiEnum,
    CommissionPayment: 'CommissionPayment' as FinancialItemTypeApiEnum,
    CreditCardPayment: 'CreditCardPayment' as FinancialItemTypeApiEnum,
    Shipment: 'Shipment' as FinancialItemTypeApiEnum,
    TaxAdjustment: 'TaxAdjustment' as FinancialItemTypeApiEnum,
    TransferCredit: 'TransferCredit' as FinancialItemTypeApiEnum
};

