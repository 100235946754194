import { Pipe, PipeTransform } from '@angular/core';
import { StateRegionCode } from '../address.type';

@Pipe({
    name: 'comFullStateName'
})
export class FullStateNamePipe implements PipeTransform {
    transform(stateRegionCode: string) {
        switch (stateRegionCode) {
            case StateRegionCode.AL:
                return 'Alabama';
            case StateRegionCode.AK:
                return 'Alaska';
            case StateRegionCode.AZ:
                return 'Arizona';
            case StateRegionCode.AR:
                return 'Arkansas';
            case StateRegionCode.CA:
                return 'California';
            case StateRegionCode.CO:
                return 'Colorado';
            case StateRegionCode.CT:
                return 'Connecticut';
            case StateRegionCode.DE:
                return 'Delaware';
            case StateRegionCode.FL:
                return 'Florida';
            case StateRegionCode.GA:
                return 'Georgia';
            case StateRegionCode.HI:
                return 'Hawaii';
            case StateRegionCode.ID:
                return 'Idaho';
            case StateRegionCode.IL:
                return 'Illinois';
            case StateRegionCode.IN:
                return 'Indiana';
            case StateRegionCode.IA:
                return 'Iowa';
            case StateRegionCode.KS:
                return 'Kansas';
            case StateRegionCode.KY:
                return 'Kentucky';
            case StateRegionCode.LA:
                return 'Louisiana';
            case StateRegionCode.ME:
                return 'Maine';
            case StateRegionCode.MD:
                return 'Maryland';
            case StateRegionCode.MA:
                return 'Massachusetts';
            case StateRegionCode.MI:
                return 'Michigan';
            case StateRegionCode.MN:
                return 'Minnesota';
            case StateRegionCode.MS:
                return 'Mississippi';
            case StateRegionCode.MO:
                return 'Missouri';
            case StateRegionCode.MT:
                return 'Montana';
            case StateRegionCode.NE:
                return 'Nebraska';
            case StateRegionCode.NV:
                return 'Nevada';
            case StateRegionCode.NH:
                return 'New Hampshire';
            case StateRegionCode.NJ:
                return 'New Jersey';
            case StateRegionCode.NM:
                return 'New Mexico';
            case StateRegionCode.NY:
                return 'New York';
            case StateRegionCode.NC:
                return 'North Carolina';
            case StateRegionCode.ND:
                return 'North Dakota';
            case StateRegionCode.OH:
                return 'Ohio';
            case StateRegionCode.OK:
                return 'Oklahoma';
            case StateRegionCode.OR:
                return 'Oregon';
            case StateRegionCode.PA:
                return 'Pennsylvania';
            case StateRegionCode.RI:
                return 'Rhode Island';
            case StateRegionCode.SC:
                return 'South Carolina';
            case StateRegionCode.SD:
                return 'South Dakota';
            case StateRegionCode.TN:
                return 'Tennessee';
            case StateRegionCode.TX:
                return 'Texas';
            case StateRegionCode.UT:
                return 'Utah';
            case StateRegionCode.VT:
                return 'Vermont';
            case StateRegionCode.VA:
                return 'Virginia';
            case StateRegionCode.WA:
                return 'Washington';
            case StateRegionCode.WV:
                return 'West Virginia';
            case StateRegionCode.WI:
                return 'Wisconsin';
            case StateRegionCode.WY:
                return 'Wyoming';
            case StateRegionCode.AA:
                return 'Armed Forces Americas';
            case StateRegionCode.AE:
                return 'Armed Forces Europe';
            case StateRegionCode.AP:
                return 'Armed Forces Pacific';
            case StateRegionCode.DC:
                return 'District of Columbia';
        }
    }
}
