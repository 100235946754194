/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataAccessTypeApiEnum } from './dataAccessTypeApiEnum';
import { RoleApiModel } from './roleApiModel';


export interface AccessContextApiModel { 
    accessContextId: number;
    tenantId: number;
    name: string;
    isPrimaryOwner: boolean;
    roles: Array<RoleApiModel>;
    accessPolicies: Array<string>;
    dataAccessType: DataAccessTypeApiEnum;
}

