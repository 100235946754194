import { Adapter } from '@center-suite/shared/data-access/utility';
import { Injectable } from '@angular/core';
import { CurrentProfileApiModel } from '@center-suite/operation-center/shared/data-access/accounts';
import { AccessContext, CurrentProfile } from '@center-suite/shared/utility/authorization';
import { AccessContextArrayAdapter } from './access-context-array.adapter';
import { getTimezoneOffsetInSeconds } from '@center-suite/shared/utility/common';

@Injectable({
    providedIn: 'root'
})export class CurrentProfileAdapter extends Adapter<CurrentProfileApiModel, CurrentProfile> {
    constructor(private accessContextAdapter: AccessContextArrayAdapter) {
        super();
    }

    protected adapt(currentProfileApiModel: CurrentProfileApiModel): CurrentProfile {
        const accessContexts: Array<AccessContext> = this.accessContextAdapter.adaptItem(currentProfileApiModel.accessContexts);

        return {
            profileId: currentProfileApiModel.profileId,
            name: currentProfileApiModel.name,
            avatarUrl: currentProfileApiModel.avatarUrl,
            timeZoneOffsetInSeconds: getTimezoneOffsetInSeconds(currentProfileApiModel.timezoneOffset),
            accessContexts
        };
    }
}
