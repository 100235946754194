import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdModule } from '@center-suite/shared/utility/common';
import { TaskBulletinComponent } from './task-bulletin.component';
import { TaskStatusMessageModule } from '../shared/task-status-message/task-status-message.module';
import { AvatarModule } from '@center-suite/shared/ui/avatar';
import { TaskStatusModule } from '../task-status/task-status.module';
import { Header01Module, Header02Module } from '@center-suite/shared/ui/header';
import { AriaModule } from '@center-suite/shared/ui/accessibility';

@NgModule({
    imports: [
        CommonModule,
        TaskStatusMessageModule,
        AvatarModule,
        TaskStatusModule,
        Header01Module,
        AriaModule,
        IdModule,
        Header02Module
    ],
    declarations: [TaskBulletinComponent],
    exports: [TaskBulletinComponent]
})
export class TaskBulletinModule {}
