import { AppColumn } from '../../app-column-router';
import { Avatar } from '@center-suite/shared/utility/common';

export interface AppColumnTitlesAndAvatars {
    [appColumnId: number]: { title: string, secondaryTitle?: string, avatar?: Avatar };
}

export interface AppColumnUnsavedFormData {
    [appColumnId: number]: { value: {[key: string]: unknown} };
}

// TODO: make private to core
export interface Core {
    appColumns: AppColumn[];
    appColumnTitlesAndAvatars: AppColumnTitlesAndAvatars;
    showMaxAppColumnWarning: boolean;
    appColumnUnsavedFormData: AppColumnUnsavedFormData;
}

export interface AppState {
    core: Core;
}
