import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    TaskStatusIconLgBodyDirective,
    TaskStatusIconLgComponent,
    TaskStatusIconLgDateDirective,
    TaskStatusIconLgMediaContentDirective,
    TaskStatusIconLgMediaIconDirective,
    TaskStatusIconLgMessageDirective,
    TaskStatusIconLgSubjectDirective
} from './task-status-icon-lg.component';
import { TaskStatusMessageModule } from '../shared/task-status-message/task-status-message.module';
import { Header02Module } from '@center-suite/shared/ui/header';

@NgModule({
    imports: [
        CommonModule,
        TaskStatusMessageModule,
        Header02Module
    ],
    declarations: [
        TaskStatusIconLgComponent,
        TaskStatusIconLgMediaIconDirective,
        TaskStatusIconLgMediaContentDirective,
        TaskStatusIconLgSubjectDirective,
        TaskStatusIconLgDateDirective,
        TaskStatusIconLgMessageDirective,
        TaskStatusIconLgBodyDirective
    ],
    exports: [
        TaskStatusIconLgComponent,
        TaskStatusIconLgMediaIconDirective,
        TaskStatusIconLgMediaContentDirective,
        TaskStatusIconLgSubjectDirective,
        TaskStatusIconLgDateDirective,
        TaskStatusIconLgMessageDirective,
        TaskStatusIconLgBodyDirective
    ]
})
export class TaskStatusIconLgModule {
}
