import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskStatusComponent } from './task-status.component';
import { TaskStatusMessageModule } from '../shared/task-status-message/task-status-message.module';
import { Header02Module } from '@center-suite/shared/ui/header';

@NgModule({
    imports: [
        CommonModule,
        TaskStatusMessageModule,
        Header02Module
    ],
    declarations: [TaskStatusComponent],
    exports: [TaskStatusComponent]
})
export class TaskStatusModule {}
