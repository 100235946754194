import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { sanitizeHTML } from '@center-suite/shared/utility/common';
import { expandHeightShowHideAnimation } from '@center-suite/shared/ui/animation';
import { AnimationEvent } from '@angular/animations';

@Component({
    selector: 'com-truncated-text',
    templateUrl: './truncated-text.component.html',
    styleUrls: ['./truncated-text.component.scss'],
    animations: [expandHeightShowHideAnimation]
})
export class TruncatedTextComponent implements AfterViewInit, OnChanges {
    @Input() moreAriaLabel: string;
    @Input() lessAriaLabel: string;
    @Output() toggle = new EventEmitter<boolean>();
    @ViewChild('toggleElement') private toggleElement: ElementRef;
    @ViewChild('containerElement') private containerElement: ElementRef;
    html: string;
    needsToggle: boolean;
    isExpanded = false;
    animationState = 'collapsed';
    animationParams = {};
    collapsedHeight = 60;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2) {}

    @Input() set htmlContent(html: string) {
        this.html = html;
    }

    ngAfterViewInit() {
        this.updateAriaExpanded(this.isExpanded.toString());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.htmlContent.currentValue !== changes.htmlContent.previousValue) {
            this.html = sanitizeHTML(changes.htmlContent.currentValue);
        }
    }

    toggleExpandCollapse() {
        this.isExpanded = !this.isExpanded;
        this.updateAriaExpanded(this.isExpanded.toString());
        this.animationState = this.isExpanded ? 'expanded' : 'collapsed';
        this.toggle.emit(this.isExpanded);
    }

    expandHeightShowHideDone(e: AnimationEvent) {
        // Set animation params on first load to avoid expression changed after check error
        if (e.fromState === 'void') {
            this.animationParams = { collapsedHeight: e.element.scrollHeight > this.collapsedHeight ? this.collapsedHeight + 'px' : 'auto' };
            this.needsToggle = this.containerElement.nativeElement.scrollHeight > this.collapsedHeight;
        }
    }

    private updateAriaExpanded(val: string) {
        if (this.toggleElement) {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'aria-expanded', val);
        }
    }
}
