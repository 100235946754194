import { style, trigger, transition, animate } from '@angular/animations';
import { DEFAULT_TIMING } from './constants/animation-constants';

//
// By default using this animation will slide in from the right.
// To change this you can pass it params.
//
// To slide in from the left for example:
// [@slideEnterLeave]="{ value: '*', params: { startX: '-100%' } }"
//
const startX = '110%';

export const slideEnterLeaveAnimation = trigger('slideEnterLeave', [
    transition(':enter', [
        style({ transform: 'translate({{ startX }}, {{ startY }})' }),
        animate(
            DEFAULT_TIMING,
            style({ transform: 'translate({{ endX }}, {{ endY }})' })
        )
    ], {
        params: {
            startX,
            startY: '0',
            endX: '0',
            endY: '0'
        }
    }),
    transition(':leave', [
        animate(
            DEFAULT_TIMING,
            style({ transform: 'translate({{ startX }}, {{ startY }})' })
        )
    ], {
        params: {
            startX,
            startY: '0'
        }
    })
]);
