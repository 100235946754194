import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepPanesComponent } from './step-panes.component';
import { StepPaneComponent, StepPaneContentDirective, StepPaneTabDirective } from './step-pane/step-pane.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { Header01Module } from '@center-suite/shared/ui/header';
import { ScopedOverlayModule } from "@center-suite/shared/framework/scoped-overlay";

export { StepPanesComponent } from './step-panes.component';
export { StepPaneComponent, StepPaneContentDirective, StepPaneTabDirective } from './step-pane/step-pane.component';

@NgModule({
    imports: [
        CommonModule,
        Header01Module,
        OverlayModule,
        ScopedOverlayModule
    ],
    declarations: [
        StepPanesComponent,
        StepPaneComponent,
        StepPaneContentDirective,
        StepPaneTabDirective
    ],
    exports: [
        StepPanesComponent,
        StepPaneComponent,
        StepPaneContentDirective,
        StepPaneTabDirective
    ]
})
export class StepPanesModule { }
