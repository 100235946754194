import { Pipe, PipeTransform } from '@angular/core';
import { cmnFormatDate } from './format-date';

@Pipe({
    name: 'cmnFormatDate',
    pure: true
})
export class CmnFormatDatePipe implements PipeTransform {
    transform(value: string | number | Date, format: string, locale = 'en-US', timezone = 'UTC') {
        return value !== undefined ? cmnFormatDate(value, format, locale, timezone) : '';
    }
}
