import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export interface RetryStrategyConfiguration {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludedStatusCodes?: number[]
}

const defaultRetryStrategyConfiguration: RetryStrategyConfiguration = {
    maxRetryAttempts: 2,
    scalingDuration: 300,
    excludedStatusCodes: [400, 401, 404]
}

export const httpRequestRetryStrategy = (configuration: Partial<RetryStrategyConfiguration> = {}) => (attempts: Observable<HttpErrorResponse>) => {
    configuration = {
        ...defaultRetryStrategyConfiguration,
        ...configuration
    };

    return attempts.pipe(
        mergeMap((error: HttpErrorResponse, i: number) => {
            const retryAttempt = i + 1;
            // if maximum number of retries have been met
            // or response is a status code we don't wish to retry, throw error
            if (
                retryAttempt > configuration.maxRetryAttempts ||
                configuration.excludedStatusCodes.find(e => e === error.status)
            ) {
                return throwError(() => error);
            }

            // retry after 1s, 2s, etc...
            return timer(retryAttempt * configuration.scalingDuration);
        })
    );
}
