import { Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[hdrHeader02Id]',
    templateUrl: './header-02-id.component.html',
    styleUrls: ['./header-02-id.component.scss']
})
export class Header02IdComponent {
    @Input() headerId: number;
}
