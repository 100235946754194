export abstract class Document {
    abstract getViewportDimensions(): ViewportDimensions;
    abstract appendToBody(element: Node): void;
    abstract createScriptElement(source: string): HTMLScriptElement;
    abstract createElement(tagName: string): HTMLElement;
    abstract querySelector(selector: string): Element;
    abstract execCommand(commandName: string, arg: string): void;
    abstract createRange(): Range;
    abstract getSelection(): Selection;
}

export interface ViewportDimensions {
    viewportHeight: number;
    viewportWidth: number;
}
