import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { AvatarComponent } from '@center-suite/shared/ui/avatar';

@Component({
    selector: 'com-message-info-icon',
    templateUrl: './message-info-icon.component.html',
    styleUrls: ['./message-info-icon.component.scss']
})
export class MessageInfoIconComponent implements OnInit {
    @Input() viewBox: string;
    @Input() iconPath: string;
    @ContentChild(AvatarComponent) avatar: AvatarComponent;
    isAvatar = false;

    ngOnInit() {
        if (!this.viewBox && !this.iconPath) {
            this.isAvatar = true;
        }
    }
}
