import {
    AfterContentInit,
    Component,
    ContentChildren,
    DoCheck,
    Input,
    OnDestroy,
    QueryList,
    TemplateRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepPaneComponent, StepPaneContentDirective, StepPaneTabDirective } from './step-pane/step-pane.component';
import { paneSlideAnimation } from './step-panes.component.animation';

@Component({
  selector: 'com-step-panes',
  templateUrl: './step-panes.component.html',
  styleUrls: ['./step-panes.component.scss'],
  animations: [ paneSlideAnimation ]
})
export class StepPanesComponent implements OnDestroy, DoCheck, AfterContentInit {
    @ContentChildren(StepPaneComponent) stepPanes: QueryList<StepPaneComponent>;
    @Input() showTabsAndCounter = true;
    tabs: TemplateRef<StepPaneTabDirective>[] = [];
    panes: TemplateRef<StepPaneContentDirective>[] = [];
    activePaneIndex = 0;
    activePaneIndexChanged = false;
    animationParams = {
        start: '100',
        leave: '-100'
    };
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngDoCheck() {
        // Update panes if they exist but the tabs and panes arrays are empty
        // Running this in ngAfterContentInit results in expression changed after checked error
        if (this.stepPanes && !this.tabs.length && !this.panes.length) {
            this.updateTabsAndPanes();
        }
    }

    ngAfterContentInit() {
        this.stepPanes.changes
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.tabs = [];
                this.panes = [];
                this.updateTabsAndPanes();
            });
    }

    updateTabsAndPanes() {
        this.stepPanes.forEach((pane: StepPaneComponent) => {
            this.tabs.push(pane.tab);
            this.panes.push(pane.content);
        });
    }

    next() {
        if (this.activePaneIndex !== this.stepPanes.length - 1 && !this.activePaneIndexChanged) {
            this.activePaneIndexChanged = true;
            this.activePaneIndex++;

            // set animations params to switch directions
            this.animationParams.start = '100';
            this.animationParams.leave = '-100';
        }
    }

    previous() {
        if (this.activePaneIndex !== 0 && !this.activePaneIndexChanged) {
            this.activePaneIndexChanged = true;
            this.activePaneIndex--;

            // set animations params to switch directions
            this.animationParams.start = '-100';
            this.animationParams.leave = '100';
        }
    }

    slideDone() {
        this.activePaneIndexChanged = false;
    }
}
