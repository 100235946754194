export interface KeyValueMap {
    [key: string]: unknown;
}

export interface StringMap {
    [key: string]: string;
}

export interface NumberMap {
    [key: string]: number;
}
