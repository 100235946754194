import { Component, ContentChildren, QueryList } from '@angular/core';
import { ActionIconComponent } from '../../action-icon/action-icon.component';

@Component({
  selector: 'com-toggle-layout-footer',
  templateUrl: './toggle-layout-footer.component.html',
  styleUrls: ['./toggle-layout-footer.component.scss']
})
export class ToggleLayoutFooterComponent {
    @ContentChildren(ActionIconComponent) actionIcons: QueryList<ActionIconComponent>;
}
