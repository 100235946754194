export const arrayEquals = <T>(arr1: T[], arr2: T[]): boolean => {
    if (!arr1 || !arr2 || arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        for (const prop in arr1[i]) {
            if (arr1[i][prop] !== arr2[i][prop]) {
                return false;
            }
        }
    }

    return true;
};
