import { createSelector, select } from '@ngrx/store';
import { AppState, Core } from './state';
import { pipe } from 'rxjs';
import { AppColumn, AppColumnWithTitleAndAvatar } from '../../app-column-router/app-column';
import { filter } from 'rxjs/operators';

export const selectAppCore = (state: AppState) => state.core;
export const selectAppColumns = createSelector(
    selectAppCore,
    (state: Core) => state.appColumns
);

export const selectAppColumnTitlesAndAvatars = createSelector(
    selectAppCore,
    (state: Core) => state.appColumnTitlesAndAvatars
);

export const selectShowMaxAppColumnWarning = createSelector(
    selectAppCore,
    (state: Core) => state.showMaxAppColumnWarning
);

export const selectAppColumnUnsavedFormData = createSelector(
    selectAppCore,
    (state: Core) => state.appColumnUnsavedFormData
);

const selectAppColumnsPlusTitlesAndAvatars = createSelector(
    selectAppColumns,
    selectAppColumnTitlesAndAvatars,
    (appColumns, appColumnTitlesAndAvatars) => {
        return appColumns.map((appColumn: AppColumn): AppColumnWithTitleAndAvatar => {
            return {
                ...appColumn,
                ...appColumnTitlesAndAvatars[appColumn.id]
            } as AppColumnWithTitleAndAvatar;
        });
    });

export const selectAppColumnsWithTitlesAndAvatars = pipe(
    select(selectAppColumnsPlusTitlesAndAvatars),
    filter(appColumns => !appColumns.some(ac => ac.title === undefined)) // stop observable from firing when title has not been set yet
);
