import { AppColumn } from '../../../../app-column-router/app-column';
import { SlideToAppColumn } from '../../action-types';

export const slideToAppColumn = (appColumns: AppColumn[], action: SlideToAppColumn) => {
    return appColumns.map((appColumn: AppColumn) => {
        const appColumnCopy = appColumn.copy();
        appColumnCopy.isOnStage = appColumnCopy.id === action.payload.appColumnId;
        return appColumnCopy;
    });
};
