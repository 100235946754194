import { StorageService } from '../base/storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserStorageService extends StorageService {
    setItem(key: string, value: string, replacer: (string | number)[] = null): void {
        if (typeof value === 'object') {
            window.localStorage.setItem(key, JSON.stringify(value, replacer));
        } else {
            window.localStorage.setItem(key, value);
        }
    }

    getItem(key: string): unknown {
        const value = window.localStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    removeItem(key: string): void {
        window.localStorage.removeItem(key);
    }

    clear(): void {
        window.localStorage.clear();
    }
}
