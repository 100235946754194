/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaxIdentificationNumberTypeApiEnum = 'SocialSecurityNumber' | 'EmployerIdentificationNumber' | 'SocialSecurityNumberOrEmployerIdentificationNumber';

export const TaxIdentificationNumberTypeApiEnum = {
    SocialSecurityNumber: 'SocialSecurityNumber' as TaxIdentificationNumberTypeApiEnum,
    EmployerIdentificationNumber: 'EmployerIdentificationNumber' as TaxIdentificationNumberTypeApiEnum,
    SocialSecurityNumberOrEmployerIdentificationNumber: 'SocialSecurityNumberOrEmployerIdentificationNumber' as TaxIdentificationNumberTypeApiEnum
};

