import { style, trigger, transition, animate, query, stagger } from '@angular/animations';
import { DEFAULT_TIMING } from './constants/animation-constants';

//
// By default using this animation will scale in from the center.
// To change this you will need to add a transform-origin in CSS.
//
// To slide in from the top right for example:
// .item {
//     transform-origin: top right;
// }
//

export const expandListEnterLeaveAnimation = trigger('expandListEnterLeave', [
    transition('* => *', [
        query(':enter',
            style({
                height: 0,
                opacity: 0,
                transform: 'scaleY(0)'
            }), { optional: true }
        ),
        query(':enter',
            stagger(100, [
                animate(
                    DEFAULT_TIMING,
                    style({
                        height: '*',
                        opacity: 1,
                        transform: 'scaleY(1)'
                    })
                )
            ]), { optional: true }),
        query(':leave', [
            stagger(100, [
                animate(
                    DEFAULT_TIMING,
                    style({
                        height: 0,
                        opacity: 0,
                        transform: 'scaleY(0)'
                    })
                )
            ])
        ], { optional: true })
    ])
]);
