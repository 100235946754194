import { ROUTE_GROUPINGS } from '../route-groupings';

export class RouteGroupingService {
    getRouteGroupingKeyForUrl(url: string): string {
        for (const urlPattern in ROUTE_GROUPINGS) {
          if(ROUTE_GROUPINGS.hasOwnProperty(urlPattern)) {
            const matches = url.match(urlPattern);
            if (matches && matches.length > 0) {
              let groupKey = ROUTE_GROUPINGS[urlPattern];
              if (matches.length > 1) {
                for (let matchIndex = 1; matchIndex <= matches.length; matchIndex++) {
                  groupKey = groupKey.replace(`{R:${matchIndex}}`, matches[matchIndex]);
                }
              }
              return groupKey;
            }
          }
        }
        return null;
    }
}
