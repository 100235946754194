import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TaskService, TasksResponse } from '../shared/task.service';
import { TaskStatusService } from '../shared/task-status.service';
import { Subject } from 'rxjs';
import { TaskStatus } from '../shared/task-status.type';
import { Task } from '../shared/task.type';
import { takeUntil } from 'rxjs/operators';
import { expandListShowHideAnimation, fadeScaleEnterLeaveAnimation } from '@center-suite/shared/ui/animation';
import { AnimationEvent } from '@angular/animations';

interface AriaData {
    [key: string]: string | boolean | null;
}

@Component({
    selector: 'tsk-task-bulletin',
    templateUrl: './task-bulletin.component.html',
    styleUrls: ['./task-bulletin.component.scss'],
    providers: [TaskService, TaskStatusService],
    animations: [
        fadeScaleEnterLeaveAnimation,
        expandListShowHideAnimation
    ]
})
export class TaskBulletinComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('attr.aria-expanded') isExpanded = false;
    @ViewChild('taskListElement') taskListElement: ElementRef;
    @Input() objectId: number;
    @Input() objectType = '';
    taskList: Task[] = [];
    status: TaskStatus;
    statusVersion: number;
    earliestDueDate: number;
    moreCountSmall: string;
    moreCountLarge: string;
    avatarUrls: string[] = [];
    containerClass: string;
    animationState = 'collapsed';
    animationDone = true;
    ariaData: AriaData = {};

    private ngUnsubscribe: Subject<void> = new Subject();

    constructor(private taskService: TaskService,
                private taskStatusService: TaskStatusService) {}

    ngOnInit() {
        this.taskService
            .getSampleData()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(({ data }: TasksResponse) => {
                this.taskList = data;
                const names: { [key: string]: boolean } = {};
                if (this.taskList[0]) {
                    this.earliestDueDate = this.taskList[0].dueDate;
                }
                this.taskList.forEach((task) => {

                    // Update names list to count total unique assignees for all tasks in the list
                    task.assignedTo.forEach(assignee => {
                        if (!names[assignee.name] && this.avatarUrls.length < this.MAX_ICONS_LARGE) {
                            this.avatarUrls.push(assignee.avatarUrl);
                        }
                        names[assignee.name] = true;
                    });

                    // Update data for this list item
                    if (task.assignedTo.length > this.MAX_ICONS_SMALL) {
                        const moreItems = task.assignedTo.length - this.MAX_ICONS_SMALL;
                        task.moreCountSmall = `${moreItems > 99 ? '99+' : moreItems}`;
                    }

                    if (task.assignedTo.length > this.MAX_ICONS_LARGE) {
                        const moreItems = task.assignedTo.length - this.MAX_ICONS_LARGE;
                        task.moreCountLarge = `${moreItems > 99 ? '99+' : moreItems}`;
                    }

                    // Find most urgent task (earliest due date)
                    if (task.dueDate < this.earliestDueDate) {
                        this.earliestDueDate = task.dueDate;
                    }
                });

                // Total count of excluded avatars
                const namesLength = Object.keys(names).length;
                if (namesLength > this.MAX_ICONS_SMALL) {
                    const moreItems = namesLength - this.MAX_ICONS_SMALL;
                    this.moreCountSmall = `${moreItems > 99 ? '99+' : moreItems}`;
                }

                if (namesLength > this.MAX_ICONS_LARGE) {
                    const moreItems = namesLength - this.MAX_ICONS_LARGE;
                    this.moreCountLarge = `${moreItems > 99 ? '99+' : moreItems}`;
                }

                // Set status based on earliest due date
                this.status = this.taskStatusService.getStatus(this.earliestDueDate);

                // Set appropriate container class based on status
                if (this.status === TaskStatus.HAS_TASK) {
                    this.containerClass = 'csColorContainer--13';
                } else if (this.status === TaskStatus.DUE_SOON) {
                    this.containerClass = 'csColorContainer--09';
                } else if (this.status === TaskStatus.PAST_DUE) {
                    this.containerClass = 'csColorContainer--08';
                } else {
                    this.containerClass = 'csColorContainer--13';
                }
            });
    }

    ngAfterViewInit() {
        if (this.taskListElement) {
            this.ariaData = { expanded: 'false', controls: this.taskListElement.nativeElement.id };
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
    }

    toggleExpandCollapse() {
        this.isExpanded = !this.isExpanded;
        this.ariaData = {...this.ariaData, expanded: this.isExpanded.toString() };
        if (this.animationState === 'collapsed') {
            this.animationDone = true;
            this.animationState = 'expanded';
        } else {
            this.animationDone = false;
            this.animationState = 'collapsed';
        }
    }

    onListShowHideDone(event: AnimationEvent) {
        if (event.fromState === 'expanded') {
            this.animationDone = true;
        }
    }

    setAriaLabelledBy(subject: string) {
        this.ariaData = {...this.ariaData, labelledby: subject }
    }

    getIconClass(index: number) {
        const prefix = 'message__assignee';
        let classes = prefix;

        if (index > 0) {
            classes += ` ${prefix}--hideSmall`;
        }

        return classes;
    }

    get MAX_ICONS_SMALL() {
        return 1;
    }

    get MAX_ICONS_LARGE() {
        return 3;
    }
}
