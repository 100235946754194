import { Component, ContentChild, Directive, Input, OnInit } from '@angular/core';
import { TaskStatus } from '../shared/task-status.type';
import { Task } from '../shared/task.type';
import { TaskStatusService } from '../shared/task-status.service';
import { CssClassBinderDirective } from '@center-suite/shared/utility/common';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-lg-media-icon' })
export class TaskStatusIconLgMediaIconDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-lg-media-content' })
export class TaskStatusIconLgMediaContentDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-lg-subject' })
export class TaskStatusIconLgSubjectDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-lg-date' })
export class TaskStatusIconLgDateDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-lg-message' })
export class TaskStatusIconLgMessageDirective {}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tsk-task-status-icon-lg-body' })
export class TaskStatusIconLgBodyDirective {}

@Component({
    selector: 'tsk-task-status-icon-lg',
    templateUrl: './task-status-icon-lg.component.html',
    styleUrls: ['./task-status-icon-lg.component.scss'],
    providers: [TaskStatusService]
})
export class TaskStatusIconLgComponent extends CssClassBinderDirective implements OnInit {
    @Input() tasks: Task[];
    @ContentChild(TaskStatusIconLgMediaContentDirective) mediaContent: TaskStatusIconLgMediaContentDirective;
    @ContentChild(TaskStatusIconLgSubjectDirective) subject: TaskStatusIconLgSubjectDirective;
    @ContentChild(TaskStatusIconLgDateDirective) date: TaskStatusIconLgDateDirective;
    @ContentChild(TaskStatusIconLgMessageDirective) message: TaskStatusIconLgMessageDirective;
    status: TaskStatus;
    statusClass: string;
    earliestDueDate: number;
    TaskStatusEnum = TaskStatus;

    constructor(private taskStatusService: TaskStatusService) {
        super();
    }

    ngOnInit() {
        if (this.tasks && this.tasks.length > 0) {
            this.earliestDueDate = this.tasks[0].dueDate;
            this.tasks.forEach((task) => {
                if (task.dueDate < this.earliestDueDate) {
                    this.earliestDueDate = task.dueDate;
                }
            });
        }
        this.status = this.taskStatusService.getStatus(this.earliestDueDate);
        this.statusClass = this.taskStatusService.getStatusClass(this.status);
    }
}
