import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[comScrollbarWidthCalc]'
})
export class ScrollbarWidthCalcDirective implements OnInit {
    // This directive is used to set custom property based on scrollbar width since it can vary by browser
    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef) {}

    ngOnInit(): void {
        // Styled Scroll Bar
        this.setScrollBarProp('--app__scrollbarWidth');

        // Un-styled Scroll Bar
        this.setScrollBarProp('--app__scrollbarWidth--unStyled', 'global__scrollBarUnStyled');
    }

    private setScrollBarProp(propName: string, additionalClass?: string): void {
        // Create a div with a scrollbar and add it to the DOM for calculations
        const div = this.renderer.createElement('div');
        if (additionalClass) {
            this.renderer.addClass(div, additionalClass);
        }
        div.style.overflow = 'scroll';
        this.renderer.appendChild(this.elementRef.nativeElement, div);

        // Set global scrollbar width custom property based off the computed width of the scrollbar
        const scrollbarWidth = div.offsetWidth - div.clientWidth;
        document.body.style.setProperty(propName, ` ${scrollbarWidth}px`);

        // Remove the scrollbar div after the property has been set on the body element
        this.renderer.removeChild(this.elementRef.nativeElement, div);
    }
}
