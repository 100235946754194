/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileApiModel } from './profileApiModel';
import { ShipToApiModel } from './shipToApiModel';
import { BillToApiModel } from './billToApiModel';
import { NoteApiModel } from './noteApiModel';
import { LegacyAutoRenewPreferenceApiEnum } from './legacyAutoRenewPreferenceApiEnum';
import { NotificationPreferenceApiModel } from './notificationPreferenceApiModel';


export interface AccountDetailsApiModel { 
    accountId: string;
    tenantId: number;
    referringAccountId?: string | null;
    referringAccountName?: string | null;
    accountTypeId: number;
    legacyCustomerId?: number | null;
    legacyPartnerId?: number | null;
    dateCreated: string;
    isTaxExempt: boolean;
    specialAccountDetails?: string | null;
    legacyIsWholesaleBuyer: boolean;
    legacyAutoRenewPreference: LegacyAutoRenewPreferenceApiEnum;
    name?: string | null;
    primaryOwnerProfile?: ProfileApiModel;
    defaultBillTo?: BillToApiModel;
    defaultShipTo?: ShipToApiModel;
    mostRecentNote?: NoteApiModel;
    notificationPreferences: Array<NotificationPreferenceApiModel>;
    totalBillTos: number;
    totalShipTos: number;
}

