export interface TaskAssignee {
    name: string;
    avatarUrl: string;
}

export interface Task {
    dueDate: number;
    assignedTo: TaskAssignee[];
    title: string;
    description: string;
    createdBy: string; // TODO should this be number? and use profile id?
    dateCreated: number;
    object: ObjectType;
    moreCountSmall?: string;
    moreCountLarge?: string;
}

export enum ObjectType {
    ACCOUNT = 'account',
    INVOICE = 'invoice',
    REFUND_RECEIPT = 'refund receipt',
    ORDER = 'order',
    ORDER_SHIPMENT = 'order shipment',
    PAYMENT = 'payment',
}
