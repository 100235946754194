import { NgModule } from '@angular/core';
import { IdDirective } from './id.directive';

export { IdDirective } from './id.directive';
export { IdService } from './id.service';

@NgModule({
    declarations: [IdDirective],
    exports: [IdDirective],
})
export class IdModule { }
