import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode } from '@center-suite/shared/utility/common';

@Pipe({
    name: 'comFullCountryName'
})
export class FullCountryNamePipe implements PipeTransform {
    transform(countryCode: string): string {
        switch (countryCode) {
            case CountryCode.US:
                return 'United States';
            case CountryCode.CA:
                return 'Canada';
            case CountryCode.MX:
                return 'Mexico';
            case CountryCode.FR:
                return 'France';
        }
    }
}
