import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'comFormatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {
    transform(value: number, hideMinusSignIfNegative = true, showDollarSign = true): string {
        if (value === undefined) {
            return '';
        }
        if (typeof value !== 'number') {
            throw new Error(`comFormatCurrency: Invalid value "${value}"`);
        }

        const isNegative = value < 0;
        return (isNegative && !hideMinusSignIfNegative ? '- ' : '')
            + (showDollarSign ? '$' : '')
            + Math.abs(value).toFixed(2);
    }
}
