import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Header02Component } from './header-02.component';
import { Header02LastWordAndIdComponent } from './header-02-last-word-and-id/header-02-last-word-and-id.component';
import { Header02IdComponent } from './header-02-id/header-02-id.component';

export { Header02Component } from './header-02.component';
export { Header02LastWordAndIdComponent } from './header-02-last-word-and-id/header-02-last-word-and-id.component';
export { Header02IdComponent } from './header-02-id/header-02-id.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        Header02Component,
        Header02LastWordAndIdComponent,
        Header02IdComponent
    ],
    exports: [
        Header02Component,
        Header02LastWordAndIdComponent,
        Header02IdComponent
    ]
})
export class Header02Module {}
