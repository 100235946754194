import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { formatLocalUTC } from '../format-local-utc/format-local-utc';
import { cmnFormatDate } from '../format-date/format-date';

@Directive({
    selector: '[cmnCurrentTime]'
})
export class CurrentTimeDirective implements OnInit, OnDestroy {
    @Input() timezoneOffsetInSeconds: number;

    timerId: number;

    constructor(
        private elementRef: ElementRef,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.setTime();
        this.ngZone.runOutsideAngular(() => {
            this.timerId = setInterval(() => {
                this.setTime();
            }, 60000) as unknown as number;
        });
    }

    ngOnDestroy() {
        clearInterval(this.timerId);
    }

    private setTime() {
        const datetime = formatLocalUTC(Date.now(), this.timezoneOffsetInSeconds);
        this.elementRef.nativeElement.innerHTML = cmnFormatDate(datetime, 'h:mm a');
        datetime.setSeconds(0, 0);
        this.elementRef.nativeElement.setAttribute('datetime', datetime.toISOString());
    }
}
