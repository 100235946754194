/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CountryCodeApiEnum } from './countryCodeApiEnum';
import { StateRegionCodeApiEnum } from './stateRegionCodeApiEnum';


export interface AddressRequest { 
    attentionTo?: string | null;
    street1?: string | null;
    street2?: string | null;
    city?: string | null;
    stateRegion?: StateRegionCodeApiEnum;
    country?: CountryCodeApiEnum;
    postalCode?: string | null;
    skipAddressCorrection: boolean;
}

