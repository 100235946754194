import { AppColumn } from '../../../../app-column-router/app-column';
import { MoveAppColumnAction } from '../../action-types';

export const moveAppColumn = (appColumns: AppColumn[], action: MoveAppColumnAction) => {
    if (action.payload.appColumnId === action.payload.targetId) {
        return appColumns;
    }

    // remove app column to move from array
    const appColumnToMoveIndex = appColumns.findIndex(appColumn => appColumn.id === action.payload.appColumnId);
    const targetAppColumnIndex = appColumns.findIndex(appColumn => appColumn.id === action.payload.targetId);
    const appColumnsCopy = [...appColumns];
    const appColumnToMove = appColumnsCopy.splice(appColumnToMoveIndex, 1)[0];

    // split the array and insert the moved column in the target location
    const left = appColumnsCopy.slice(0, targetAppColumnIndex);
    const right = appColumnsCopy.slice(targetAppColumnIndex);
    return [...left, appColumnToMove, ...right];
};
