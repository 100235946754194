import { formatDate } from '@angular/common';

export function cmnFormatDate(value: string | number | Date, format: string, locale = 'en-US', timeZone?: string): string {
    // Use default if no formatting is provided
    if (!format) {
        return `${formatDate(value, 'mediumDate', locale, timeZone)} at ${formatDate(value, 'shortTime', locale, timeZone)}`;
    } else {
        return formatDate(value, format, locale, timeZone);
    }
}
