import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CurrentProfileContext } from '../current-profile/current-profile-context';

@Directive({
    selector: '[autCanAccess]'
})
export class CanAccessDirective {
    constructor(
        private currentProfileContext: CurrentProfileContext,
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef) {}

    @Input() set autCanAccess(accessPolicy: string) {
        if (this.currentProfileContext.hasAccessPolicy(accessPolicy)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
