import { Window, WindowDimensions } from '../base/window';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserWindow extends Window {
    on(type: string, listener: (ev: UIEvent) => void, debounceTime: number = null) {
        const handler = debounceTime ? this.debounce(listener, debounceTime) : listener;
        window.addEventListener(type, handler, false);
        return () => { window.removeEventListener(type, handler, false); };
    }

    matchMedia(mediaQuery: string): MediaQueryList {
        return window.matchMedia(mediaQuery);
    }

    getWindowDimensions(): WindowDimensions {
        const { innerHeight, innerWidth } = window;
        return { windowHeight: innerHeight, windowWidth: innerWidth };
    }

    addProperty(name: string, value: unknown): void {
        window[name] = value;
    }

    private debounce(func: (ev: UIEvent) => void, wait: number, immediate?: () => unknown) {
        let timeoutId: number;
        return function () {
            // eslint-disable-next-line @typescript-eslint/no-this-alias,prefer-rest-params
            const context = this, args = arguments;
            const later = () => {
                timeoutId = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeoutId;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(later, wait) as unknown as number;
            if (callNow) {
                func.apply(context, args);
            }
        };
    }
}
