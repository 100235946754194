import { APP_INITIALIZER, InjectionToken } from '@angular/core';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { APP_STATE_KEY, AppStateService } from './app-state.service';
import { batch } from './meta-reducers/batch';
import { sizeAndSetOffStageStatus } from './meta-reducers/size-and-set-offstage-status';
import { StorageService } from '@center-suite/shared/framework/platform';
import { appColumnsTitlesAndAvatars } from './reducers/app-column-titles-and-avatars.reducer';
import { appColumns } from './reducers/app-columns.reducer';
import { showMaxAppColumnWarning } from './reducers/show-max-app-column-warning.reducer';
import { appColumnUnsavedFormData } from './reducers/update-before-remove-map.reducer';
import { AppState } from './state';
import { AppColumn } from '../../app-column-router';

export * from './actions';
export * from './app-state.service';
export * from './selectors';
export * from './state';

export function initialize(appStateService: AppStateService, storageService: StorageService) {
    return () => appStateService.restoreAppState(storageService.getItem(APP_STATE_KEY) as AppColumn[]);
}

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('App State Reducers');

export function reducerFactory(): ActionReducerMap<AppState> {
    return {
        core: batch(combineReducers({
            appColumns: sizeAndSetOffStageStatus(appColumns),
            appColumnTitlesAndAvatars: appColumnsTitlesAndAvatars,
            showMaxAppColumnWarning: showMaxAppColumnWarning,
            appColumnUnsavedFormData: appColumnUnsavedFormData
        }))
    };
}

export const STATE_MANAGEMENT_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: initialize,
        deps: [AppStateService, StorageService]
    },
    { provide: REDUCERS_TOKEN, useFactory: reducerFactory }
];
