import { AppColumn } from '../../../../app-column-router/app-column';
import { RemoveStackedAppColumnAction } from '../../action-types';

export const removeStackedAppColumn = (appColumns: AppColumn[], action: RemoveStackedAppColumnAction) => {
    return appColumns.map(appColumn => {
        if (appColumn.id === action.payload.appColumnId) {
            const newEndIndex = appColumn.stackedAppColumns.length - 1;
            const newAppColumn = appColumn.copy();
            newAppColumn.stackedAppColumns = appColumn.stackedAppColumns.slice(0, newEndIndex);
            return newAppColumn;
        }
        return appColumn;
    });
};
