import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppColumn, ColumnSize } from '../app-column';
import {
    ADD_APP_COLUMN_URL_CHANGE,
    ADD_STACKED_APP_COLUMN,
    ADD_STACKED_APP_COLUMN_URL_CHANGE,
    MOVE_APP_COLUMN,
    REMOVE_STACKED_APP_COLUMN,
    REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE,
    RESIZE_APP_COLUMN,
    SELECT_APP_COLUMN
} from '../../state/state-management/actions';
import { AppState } from '../../state/state-management/state';
import { AddAppColumnCreator } from '../../state/state-management/action-creators/add-app-column.creator';
import { MoveAppColumnOnStageCreator } from '../../state/state-management/action-creators/move-app-column-onstage.creator';
import { RemoveAppColumnCreator } from '../../state/state-management/action-creators/remove-app-column.creator';
import { ShowMaxColumnsWarningCreator } from '../../state/state-management/action-creators/show-max-columns-warning.creator';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectAppColumns } from '../../state/state-management/selectors';
import { AppColumnAction } from '../../state/state-management/action-types';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { SlideToAppColumnCreator } from '../../state/state-management/action-creators/slide-to-app-column.creator';

const MaxOpenColumns = 15;

@Injectable()
export class AppColumnsService implements OnDestroy {
    public appColumnsChanged = new ReplaySubject<AppColumn[]>(1);
    private appColumns: AppColumn[] = [];
    private lastAddedAppColumn: Partial<AppColumn>;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private viewportRuler: ViewportRuler,
        private addAppColumnCreator: AddAppColumnCreator,
        private moveAppColumnOnStageCreator: MoveAppColumnOnStageCreator,
        private slideToAppColumnCreator: SlideToAppColumnCreator,
        private removeAppColumnCreator: RemoveAppColumnCreator,
        private showMaxColumnsWarningCreator: ShowMaxColumnsWarningCreator
    ) {
        this.store
            .pipe(
                select(selectAppColumns),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((appColumns: AppColumn[]) => {
                this.appColumns = appColumns;
                this.appColumnsChanged.next(appColumns);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
    }

    getAll() {
        return this.appColumns;
    }

    getById(id: number) {
        return this.appColumns.find((appColumn) => appColumn.id === id);
    }

    getByRouteGroupingKey(routeGroupingKey: string) {
        return this.appColumns.find((appColumn) => appColumn.routeGroupingKey === routeGroupingKey);
    }

    add(url: string, routeGroupingKey: string) {
        if (this.appColumns.length < MaxOpenColumns) {
            const id = this.getNewId();
            this.store.dispatch<AppColumnAction>(this.addAppColumnCreator.addAppColumn(
                id,
                url,
                routeGroupingKey,
                this.viewportRuler.getViewportSize().width,
                this.appColumns
            ));
        } else {
            this.lastAddedAppColumn = { url, routeGroupingKey };
            this.store.dispatch<AppColumnAction>(this.showMaxColumnsWarningCreator.show(this.getOldestAccessedAppColumnId()));
        }
    }

    closeMaxAppColumnWarning() {
        this.store.dispatch<AppColumnAction>(this.showMaxColumnsWarningCreator.hide());
    }

    closeMaxAppColumnWarningAndAddNewApp() {
        this.closeMaxAppColumnWarning();
        this.remove(this.getOldestAccessedAppColumnId());
        this.add(this.lastAddedAppColumn.url, this.lastAddedAppColumn.routeGroupingKey);
    }

    addStackedAppColumn(appColumnId: number, url: string, isModal = false) {
        this.store.dispatch<AppColumnAction>({
            type: ADD_STACKED_APP_COLUMN,
            payload: {
                appColumnId,
                url,
                isModal
            }
        });
    }

    move(appColumnId: number, targetAppColumnId: number) {
        this.store.dispatch<AppColumnAction>({
            type: MOVE_APP_COLUMN,
            payload: {
                appColumnId,
                targetId: targetAppColumnId
            }
        });
    }

    remove(appColumnId: number) {
        this.store.dispatch<AppColumnAction>(this.removeAppColumnCreator.removeAppColumn(
            appColumnId,
            this.viewportRuler.getViewportSize().width
        ));
    }

    removeStackedAppColumn(appColumnId: number) {
        this.store.dispatch<AppColumnAction>({
            type: REMOVE_STACKED_APP_COLUMN,
            payload: { appColumnId }
        });
    }

    setSelected(appColumn: AppColumn) {
        this.store.dispatch<AppColumnAction>({
            type: SELECT_APP_COLUMN,
            payload: {
                appColumnId: appColumn.id,
                dateAccessed: Date.now()
            }
        });
    }

    setAppColumnSize(appColumnId: number, size: ColumnSize) {
        this.store.dispatch<AppColumnAction>({
            type: RESIZE_APP_COLUMN,
            payload: {
                appColumnId,
                size,
                viewportWidth: this.viewportRuler.getViewportSize().width
            }
        });
    }

    moveAppColumnOnStage(appColumn: AppColumn) {
        this.store.dispatch<AppColumnAction>(this.moveAppColumnOnStageCreator.move(
            appColumn.id,
            this.viewportRuler.getViewportSize().width,
            this.appColumns
        ));
    }

    slideToAppColumn(appColumn: AppColumn) {
        this.store.dispatch<AppColumnAction>(this.slideToAppColumnCreator.slideTo(
            appColumn.id,
            this.viewportRuler.getViewportSize().width,
            this.appColumns
        ));
    }

    addAppColumnUrlChange(appColumnId: number, url: string) {
        this.store.dispatch<AppColumnAction>({
            type: ADD_APP_COLUMN_URL_CHANGE,
            payload: {
                appColumnId,
                url
            }
        });
    }

    addStackedAppColumnUrlChange(appColumnId: number, url: string) {
        this.store.dispatch<AppColumnAction>({
            type: ADD_STACKED_APP_COLUMN_URL_CHANGE,
            payload: {
                appColumnId,
                url
            }
        });
    }

    removeStackAppColumnLastUrlChange(appColumnId: number) {
        this.store.dispatch<AppColumnAction>({
            type: REMOVE_STACKED_APP_COLUMN_LAST_URL_CHANGE,
            payload: { appColumnId }
        });
    }

    setStackedAppUrl(appColumnId: number, url: string) {
        const appColumn = this.getById(appColumnId);
        const stackedAppColumn = appColumn.getTopMostStackedAppColumn();
        if (!stackedAppColumn) {
            throw new Error(`Stacked app columns do not exist for appColumnId: ${appColumnId}`);
        }
        stackedAppColumn.url = url;
    }

    getSnapshot() {
        return this.appColumns.map((appColumn) => appColumn.copyForSerializing());
    }

    serialize() {
        return JSON.stringify(this.getSnapshot());
    }

    private getOldestAccessedAppColumnId() {
        const oldestAccessedAppColumn = this.appColumns.reduce((prev, curr) => {
            return prev.dateAccessed < curr.dateAccessed ? prev : curr;
        });

        return oldestAccessedAppColumn.id;
    }

    private getNewId() {
        const id = this.appColumns.map(ac => ac.id).reduce((prev, curr) => Math.max(prev, curr), 0);
        return id + 1;
    }
}
