import { Pipe, PipeTransform } from '@angular/core';
import { CurrentProfileContext } from '../current-profile/current-profile-context';
import { cmnFormatDate } from '@center-suite/shared/utility/common';


@Pipe({
    name: 'cmnFormatDateToCurrentProfileLocalTime',
    pure: true
})
export class CmnFormatDateToCurrentProfileLocalTime implements PipeTransform{
    constructor(private currentUser: CurrentProfileContext) {
    }

    transform(value: string | number | Date, format: string, locale = 'en-US') {
        return value !== undefined ? cmnFormatDate(value, format, locale, this.getCurrentUserTimeZoneOffset()) : '';
    }

    private getCurrentUserTimeZoneOffset(): string {
        const absoluteOffset = Math.abs(this.currentUser.timeZoneOffset);
        const hour = Math.floor(absoluteOffset / 3600);
        const minute = absoluteOffset - (hour * 3600);

        let formattedOffset = '';
        formattedOffset += this.currentUser.timeZoneOffset > 0 ? '+' : '-';
        formattedOffset += hour < 10 ? '0' + hour : hour;
        formattedOffset += minute < 10 ? '0' + minute : minute;
        return formattedOffset;
    }
}

