import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'comPhoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
    transform(phoneNumber: string): string {
        if (typeof phoneNumber !== 'string') {
            throw new Error(`comPhoneNumber: invalid value "${phoneNumber}"`);
        }

        const phoneNumberRegex = /(\d{3})?(\d{3})(\d{4})(\d*)/;
        const containsValidPhoneNumber = phoneNumberRegex.test(phoneNumber);
        if (!containsValidPhoneNumber) {
            throw new Error(`comPhoneNumber: Too few digits in "${phoneNumber}"`);
        }

        const phoneNumberFormatter = (_, areaCode, part1, part2, extension) =>
            `${areaCode ? `(${areaCode}) ` : ''}${part1}-${part2}${extension ? ` x${extension}` : ''}`
        return phoneNumber.replace(phoneNumberRegex, phoneNumberFormatter);
    }
}
