/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountCreditApiModel } from './accountCreditApiModel';
import { InvoiceFinancialItemListApiModel } from './invoiceFinancialItemListApiModel';
import { FinancialItemListApiModel } from './financialItemListApiModel';
import { UpcomingFinancialActivityApiModel } from './upcomingFinancialActivityApiModel';
import { OwedToAccountApiModel } from './owedToAccountApiModel';


export interface FinancialOverviewDataApiModel { 
    invoicesDue: InvoiceFinancialItemListApiModel;
    unInvoicedCharges: FinancialItemListApiModel;
    accountCredit: AccountCreditApiModel;
    owedToAccount: OwedToAccountApiModel;
    commissionCreditsEarned: FinancialItemListApiModel;
    currentBalance: number;
    upcomingFinancialActivity: UpcomingFinancialActivityApiModel;
}

