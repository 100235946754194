import { Adapter } from '@center-suite/shared/data-access/utility';
import { Injectable } from '@angular/core';
import { AccountAccessEnumAdapter } from './account-access-enum.adapter';
import { AccessContextApiModel, RoleApiModel } from '@center-suite/operation-center/shared/data-access/accounts';
import { AccessContext, AccountAccess } from '@center-suite/shared/utility/authorization';
import { DataAccessTypeEnumAdapter } from './data-access-type-enum.adapter';

@Injectable({
    providedIn: 'root'
})
export class AccessContextArrayAdapter extends Adapter<AccessContextApiModel[], AccessContext[]> {

    constructor(
        private accountAccessEnumAdapter: AccountAccessEnumAdapter,
        private dataAccessTypeEnumAdapter: DataAccessTypeEnumAdapter) {
        super();
    }

    protected adapt(accessContextApiModels: AccessContextApiModel[]): AccessContext[] {
        return accessContextApiModels.map(accessContextApiModel => {
            const accessContext: AccessContext = {
                accessContextId: accessContextApiModel.accessContextId,
                tenantId: accessContextApiModel.tenantId,
                name: accessContextApiModel.name,
                accessPolicies: accessContextApiModel.accessPolicies,
                levelOfAccess: this.getPrimaryOwnerProfileAccountAccessLevel(accessContextApiModel.roles),
                dataAccessType: this.dataAccessTypeEnumAdapter.apiEnumToViewEnum(accessContextApiModel.dataAccessType)
            };

            return accessContext;
        });
    }

    private getPrimaryOwnerProfileAccountAccessLevel(roleApiModels: Array<RoleApiModel>): AccountAccess {
        // TODO Support multiple levels of access
        if (roleApiModels.length > 0) {
            const role = roleApiModels[0];
            return this.accountAccessEnumAdapter.apiEnumToViewEnum(role.name);
        }
        return null;
    }
}
