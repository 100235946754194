import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Window } from '@center-suite/shared/framework/platform';
import { StorageService } from '@center-suite/shared/framework/platform';
import { AppColumn, StackedAppColumn } from '../../app-column-router/app-column';
import { RESTORE_APP } from './actions';
import { AppState } from './state';
import { ViewportRuler } from '@angular/cdk/scrolling';

export const APP_STATE_KEY = 'AppState';

@Injectable({
    providedIn: 'root'
})
export class AppStateService {
    private cancelHandler: () => unknown;
    private getAppState: () => string;

    constructor(
        private storageService: StorageService,
        private window: Window,
        private store: Store<AppState>,
        private viewportRuler: ViewportRuler) {}

    registerGetAppState(getAppState: () => string): void {
        this.getAppState = getAppState;
    }

    monitorAppStateChanges(): void {
        if (!this.getAppState) {
            throw new Error('No getAppState function registered. Call registerGetAppState() before calling monitorAppStateChanges().');
        }
        this.cancelHandler = this.window.on('unload', (): void => {
            this.storageService.setItem(APP_STATE_KEY, this.getAppState());
        });
    }

    cancelMonitorAppStateChanges(): void {
        if (this.cancelHandler) {
            this.cancelHandler();
        }
    }

    restoreAppState(appColumns: AppColumn[] = []): void {
        if (appColumns) {
            const setStackedAppColumns = (appColumn: AppColumn) => {
                return appColumn.stackedAppColumns
                    .map(stackedAppColumn => {
                        const stackedColumn = new StackedAppColumn(stackedAppColumn.url);
                        stackedColumn.historyUrls = stackedAppColumn.historyUrls;
                        return stackedColumn;
                    });
            };
            const appColumnsList = appColumns.map(appColumn => {
                return Object.assign(new AppColumn(), appColumn, {
                    stackedAppColumns: setStackedAppColumns(appColumn)
                });
            });

            this.store.dispatch({
                type: RESTORE_APP,
                payload: {
                    viewportWidth: this.viewportRuler.getViewportSize().width,
                    appColumns: appColumnsList
                }
            });
        }
    }

    getCurrentAppState(): string {
        return this.getAppState ? this.getAppState() : null;
    }
}
