import { Directive, HostBinding, HostListener, Input } from '@angular/core';

import { AppColumnRouterService } from '../../services/app-column-router.service';
import { AppColumnFactory } from '../../app-column.factory';

@Directive({
    selector: '[acfStackedRouterLink]'
})
export class StackedRouterLinkDirective {
    @HostBinding('attr.href') visibleHref: string;
    @Input() set acfStackedRouterLink(path: string) {
        this.visibleHref = path;
        this.routePath = path;
    }
    private routePath: string;

    constructor(
        private appColumnRouterService: AppColumnRouterService,
        private appColumnFactory: AppColumnFactory) {}

    @HostListener('click', ['$event'])
    onClick(e: Event) {
        e.preventDefault();
        if (this.appColumnFactory.getAppColumn().stackedAppColumns.length === 0) {
            this.appColumnRouterService.navigateAndStack(this.appColumnFactory.appColumnId, this.routePath);
        } else {
            this.appColumnRouterService.routeStackedAppColumnToUrl(this.appColumnFactory.appColumnId, this.routePath);
        }
    }
}
