import {
    APP_BASE_HREF,
    HashLocationStrategy,
    Location,
    LocationStrategy,
    PathLocationStrategy,
    PlatformLocation
} from '@angular/common';
import {
    ApplicationRef,
    Compiler,
    Inject,
    InjectionToken,
    Injector,
    ModuleWithProviders,
    NgModule,
    Optional,
    Provider
} from '@angular/core';
import {
    ActivatedRoute,
    ChildrenOutletContexts,
    DefaultUrlSerializer,
    ExtraOptions,
    provideRoutes,
    Route,
    Router,
    RouteReuseStrategy,
    RouterModule,
    Routes,
    ROUTES,
    UrlHandlingStrategy,
    UrlSerializer,
    UrlTree,
    ɵflatten as flatten
} from '@angular/router';
import { AppColumnLocation } from './app-column-location';
import { AppColumnRouterModalMatcherService } from './services/app-column-router-modal-matcher.service';
import { AppColumnRouterService } from './services/app-column-router.service';
import { AppColumnsService } from './services/app-columns.service';
import { AppColumnRouterLinkDirective } from './directives/app-column-router-link/app-column-router-link.directive';
import { StackedRouterLinkDirective } from './directives/stacked-router-link/stacked-router-link.directive';
import { RouteGroupingService } from './services/route-grouping.service';
import { AppColumnTitleAndAvatarService } from './services/app-column-title-and-avatar.service';

export const APP_COLUMN_ROUTER_DIRECTIVES = [
    AppColumnRouterLinkDirective,
    StackedRouterLinkDirective
];
export const APP_COLUMN_PROVIDERS: Provider[] = [
    { provide: APP_BASE_HREF, useValue: '/' },
    AppColumnsService,
    AppColumnTitleAndAvatarService,
    AppColumnRouterService,
    RouteGroupingService,
    AppColumnRouterModalMatcherService,
    { provide: Location, useClass: AppColumnLocation }
];

export const ROUTER_CONFIGURATION = new InjectionToken<unknown>('ROUTER_CONFIGURATION');

export function rootRoute(router: Router): ActivatedRoute {
    return router.routerState.root;
}

export function provideLocationStrategy(
    platformLocationStrategy: PlatformLocation, baseHref: string, options: ExtraOptions = {}) {
    return options.useHash ? new HashLocationStrategy(platformLocationStrategy, baseHref) :
        new PathLocationStrategy(platformLocationStrategy, baseHref);
}

export function setupRouter(
    ref: ApplicationRef, urlSerializer: UrlSerializer, contexts: ChildrenOutletContexts,
    location: Location, injector: Injector, compiler: Compiler, config: Route[][],
    opts: ExtraOptions = {}, urlHandlingStrategy?: UrlHandlingStrategy,
    routeReuseStrategy?: RouteReuseStrategy) {
    const router = new Router(null, urlSerializer, contexts, location, injector, compiler, flatten(config));

    if (urlHandlingStrategy) {
        router.urlHandlingStrategy = urlHandlingStrategy;
    }

    if (routeReuseStrategy) {
        router.routeReuseStrategy = routeReuseStrategy;
    }

    if (opts.errorHandler) {
        router.errorHandler = opts.errorHandler;
    }

    if (opts.enableTracing) {
        router.events.subscribe(e => {
            // eslint-disable-next-line no-console
            console.group(`Router Event: ${e.constructor.name}`);
            // eslint-disable-next-line no-console
            console.log(e.toString());
            // eslint-disable-next-line no-console
            console.log(e);
            // eslint-disable-next-line no-console
            console.groupEnd();
        });
    }
    // add an error handler for malformed urls; it redirects to the app root (dashboard)
    router.malformedUriErrorHandler = (error: URIError, serializer: UrlSerializer, url: string): UrlTree => {
        alert(`Malformed URL: ${url}\nRedirecting you to the dashboard.`);
        return serializer.parse('/');
    };

    return router;
}

@NgModule({
    imports: [
        RouterModule
    ],
    declarations: [
        APP_COLUMN_ROUTER_DIRECTIVES
    ],
    exports: [
        RouterModule,
        APP_COLUMN_ROUTER_DIRECTIVES
    ]
})
export class AppColumnRouterModule {
    static forRoot(routes: Routes, config?: ExtraOptions): ModuleWithProviders<AppColumnRouterModule> {
        return {
            ngModule: AppColumnRouterModule,
            providers: [
                APP_COLUMN_PROVIDERS,
                { provide: UrlSerializer, useClass: DefaultUrlSerializer },
                provideRoutes(routes),
                { provide: ROUTER_CONFIGURATION, useValue: config ? config : {} },
                {
                    provide: LocationStrategy,
                    useFactory: provideLocationStrategy,
                    deps: [
                        PlatformLocation, [new Inject(APP_BASE_HREF), new Optional()], ROUTER_CONFIGURATION
                    ]
                },
                // TODO (SL-3253) uncomment these once RouterScroll and createRouterScroller
                // are available from @angular/router
                /*{
                    provide: RouterScroller,
                    useFactory: createRouterScroller,
                    deps: [Router, ViewportScroller, ROUTER_CONFIGURATION]
                }*/
            ]
        };
    }

    static forChild(routes: Routes): ModuleWithProviders<AppColumnRouterModule> {
        return { ngModule: AppColumnRouterModule, providers: [provideRoutes(routes)] };
    }

    static forAppColumnHostComponent() {
        return [
            {
                provide: Router,
                useFactory: setupRouter,
                deps: [
                    ApplicationRef, UrlSerializer, ChildrenOutletContexts, Location, Injector,
                    Compiler, ROUTES, ROUTER_CONFIGURATION
                ]
            },
            ChildrenOutletContexts,
            { provide: ActivatedRoute, useFactory: rootRoute, deps: [Router] },
        ];
    }
}
