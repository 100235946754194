import { animate, style, transition, trigger } from '@angular/animations';
import { DEFAULT_TIMING } from './constants/animation-constants';

export const fadeScaleLeaveAnimation = trigger('fadeScaleLeave', [
    transition(':leave', [
        animate(DEFAULT_TIMING,
            style({
                height: 0,
                opacity: 0,
                transform: 'scale3d(0, 0, 0)'
            })
        )
    ])
]);
