/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccessContextApiModel } from './accessContextApiModel';


export interface CurrentProfileApiModel { 
    profileId: number;
    name: string;
    avatarUrl: string;
    timezoneIdentifier: string;
    /**
     * TimezoneOffset has the format (-)hh:mm:ss (Ex -07:00:00)
     */
    timezoneOffset: string;
    accessContexts: Array<AccessContextApiModel>;
}

