/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DataAccessTypeApiEnum = 'Account' | 'Tenant' | 'Profile' | 'AllTenants' | 'Anonymous';

export const DataAccessTypeApiEnum = {
    Account: 'Account' as DataAccessTypeApiEnum,
    Tenant: 'Tenant' as DataAccessTypeApiEnum,
    Profile: 'Profile' as DataAccessTypeApiEnum,
    AllTenants: 'AllTenants' as DataAccessTypeApiEnum,
    Anonymous: 'Anonymous' as DataAccessTypeApiEnum
};

