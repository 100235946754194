import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppColumnRouterLinkOriginService {
    private subject: Subject<DOMRect>;

    constructor() {
        this.subject = new Subject<DOMRect>();
    }

    onNewOrigin() {
        return this.subject.asObservable();
    }

    emitNewOrigin(originBoundingBox: DOMRect) {
        this.subject.next(originBoundingBox);
    }
}
