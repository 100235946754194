import { Injectable } from '@angular/core';
import { AccessContext, CurrentProfile } from './current-profile.type';
import { CookieHelperService } from '@center-suite/shared/utility/common';

@Injectable({
    providedIn: 'root'
})
export class CurrentProfileContext {
    id: number;
    name: string;
    avatarUrl: string;
    accessContexts: AccessContext[];
    currentAccessContext: AccessContext;
    timeZoneOffset: number;

    public static ACCESS_CONTEXT_ID = 'Access-Context-Id';

    constructor(private cookieHelperService: CookieHelperService) {
    }

    setCurrentProfile(currentProfile: CurrentProfile) {
        this.id = currentProfile.profileId;
        this.name = currentProfile.name;
        this.avatarUrl = currentProfile.avatarUrl;
        this.accessContexts = currentProfile.accessContexts;
        this.timeZoneOffset = currentProfile.timeZoneOffsetInSeconds;

        // TODO: Have a different way to set the default access context.
        this.currentAccessContext = this.accessContexts && this.accessContexts.find(ac => ac.accessPolicies.indexOf('AccessOperationCenter'));

        if (this.currentAccessContext) {
            this.cookieHelperService.set(
                CurrentProfileContext.ACCESS_CONTEXT_ID,
                this.currentAccessContext.accessContextId.toString(),
                {
                    expires: 365,
                    sameSite: 'Lax'
                });
        }
    }

    hasAccessPolicy(policyName: string) {
        if (this.currentAccessContext === null || this.currentAccessContext === undefined) {
            return false;
        }

        return Array.isArray(this.currentAccessContext.accessPolicies) && this.currentAccessContext.accessPolicies.indexOf(policyName) !== -1;
    }

    getTenantId() {
        if (this.currentAccessContext === null || this.currentAccessContext === undefined) {
            return null;
        }

        return this.currentAccessContext.tenantId;
    }
}
