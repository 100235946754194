import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskStatusMessageModule } from '../shared/task-status-message/task-status-message.module';
import {
    TaskStatusIconMdSmBodyDirective,
    TaskStatusIconMdSmComponent,
    TaskStatusIconMdSmMediaDirective,
    TaskStatusIconMdSmSubjectDirective
} from './task-status-icon-md-sm.component';
import { Header02Module } from '@center-suite/shared/ui/header';

@NgModule({
    imports: [
        CommonModule,
        TaskStatusMessageModule,
        Header02Module
    ],
    declarations: [
        TaskStatusIconMdSmComponent,
        TaskStatusIconMdSmMediaDirective,
        TaskStatusIconMdSmSubjectDirective,
        TaskStatusIconMdSmBodyDirective
    ],
    exports: [
        TaskStatusIconMdSmComponent,
        TaskStatusIconMdSmMediaDirective,
        TaskStatusIconMdSmSubjectDirective,
        TaskStatusIconMdSmBodyDirective
    ]
})
export class TaskStatusIconMdSmModule {
}
