const allowed = 'b|strong|i|em|u|ol|ul|li|div|br|p';
const blocked = 'script|style';
const map = { b: 'strong', i: 'em' }; // html tag replacement mapping

const allowedTags = allowed.split('|');
const blockedRegex = new RegExp(`<(${blocked}).*?>.*?<\\/\\1>`, 'gi');
const htmlRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;

function removeAttributes(element: string, tag: string) {
    return element.replace(/<(\w+)(.|[\r\n])*?>/, `<${tag.toLowerCase()}>`);
}

export function sanitizeHTML(html: string) {
    return html
        // remove blocked tags and their content
        .replace(blockedRegex, '')
        // clean attributes from allowed tags, remove non-allowed tags
        .replace(htmlRegex, (element, tag) =>
            allowedTags.includes(tag.toLowerCase()) ? removeAttributes(element, tag) : '')
        // remove html comments
        .replace(/<!--[\s\S]*?-->/g, '')
        // remove empty tags (only have whitespace, no text/html content)
        .replace(/<[^/>]+>\s*<\/[^>]+>/, '')
        // remove &nbsp;
        .replace(/&nbsp;/g, ' ')
        // map allowed html tags
        .replace(/<(\/?)(\w+)>/g, (element, slash, tag) =>
            map.hasOwnProperty(tag) ? `<${slash}${map[tag]}>` : element.toLowerCase());
}
