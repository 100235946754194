import { Component, Input, OnInit } from '@angular/core';
import { CssClassBinderDirective } from '@center-suite/shared/utility/common';
import { Avatar} from '@center-suite/shared/utility/common';
import { LoginStatus } from '@center-suite/shared/utility/authorization';

@Component({
    selector: 'ava-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent extends CssClassBinderDirective implements OnInit {
    @Input() avatar: Avatar;
    LoginStatusEnum = LoginStatus;
    isNested = true;
    // TODO (DEV-2060) Add login status logic

    ngOnInit() {
        if (this.cssClassBinder && (this.cssClassBinder.includes('avaAvatar__layout--04') || this.cssClassBinder.includes('avaAvatar__layout--05'))) {
            this.isNested = false;
        }
    }

}
