import { NgModule } from '@angular/core';
import { TaskBulletinModule } from './task-bulletin/task-bulletin.module';
import { TaskStatusModule } from './task-status/task-status.module';
import { TaskStatusIconLgModule } from './task-status-icon-lg/task-status-icon-lg.module';
import { TaskStatusIconMdModule } from './task-status-icon-md/task-status-icon-md.module';
import { TaskStatusIconSmModule } from './task-status-icon-sm/task-status-icon-sm.module';

@NgModule({
    exports: [
        TaskBulletinModule,
        TaskStatusModule,
        TaskStatusIconLgModule,
        TaskStatusIconMdModule,
        TaskStatusIconSmModule
    ]
})
export class TasksModule {}
