import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { InitialsModule } from '@center-suite/shared/utility/common';

export { AvatarComponent } from './avatar.component';

@NgModule({
    imports: [
        CommonModule,
        InitialsModule
    ],
    declarations: [AvatarComponent],
    exports: [AvatarComponent]
})
export class AvatarModule {}
