/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NotificationTypeApiEnum = 'AccountAndBilling' | 'OrdersAndShipping' | 'PromotionsAndDiscounts' | 'ThirdParty';

export const NotificationTypeApiEnum = {
    AccountAndBilling: 'AccountAndBilling' as NotificationTypeApiEnum,
    OrdersAndShipping: 'OrdersAndShipping' as NotificationTypeApiEnum,
    PromotionsAndDiscounts: 'PromotionsAndDiscounts' as NotificationTypeApiEnum,
    ThirdParty: 'ThirdParty' as NotificationTypeApiEnum
};

