import { NgModule } from '@angular/core';
import { CmnFormatDatePipe } from './format-date.pipe';

export { CmnFormatDatePipe } from './format-date.pipe';
export { cmnFormatDate } from './format-date';

@NgModule({
    declarations: [CmnFormatDatePipe],
    exports: [CmnFormatDatePipe]
})
export class FormatDateModule {}
