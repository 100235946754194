import { Injectable } from '@angular/core';
import { TaskStatus } from './task-status.type';

@Injectable()
export class TaskStatusService {
    getStatus(dueDate: number): TaskStatus {
        if (!dueDate) {
            return TaskStatus.NONE;
        }

        const millisecondsUntilDue = dueDate - Date.now();
        if (millisecondsUntilDue >= (3 * 24 * 60 * 60 * 1000)) { // due in 3+ days
            return TaskStatus.HAS_TASK;
        } else if (millisecondsUntilDue >= 0) {
            return TaskStatus.DUE_SOON;
        } else {
            return TaskStatus.PAST_DUE;
        }
    }

    getStatusClass(status: TaskStatus): string {
        switch (status) {
            case TaskStatus.NONE:
                return 'none';
            case TaskStatus.HAS_TASK:
                return 'hasTask';
            case TaskStatus.DUE_SOON:
                return 'dueSoon';
            case TaskStatus.PAST_DUE:
                return 'pastDue';
            default:
                return '';
        }
    }
}
