/**
 * CenterSuite Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaxIdentificationNumberTypeApiEnum } from './taxIdentificationNumberTypeApiEnum';
import { ShipToConfigurationApiModel } from './shipToConfigurationApiModel';
import { AccountClassificationApiModel } from './accountClassificationApiModel';
import { BillToConfigurationApiModel } from './billToConfigurationApiModel';
import { GroupConfigurationApiModel } from './groupConfigurationApiModel';


export interface AccountTypeApiModel { 
    accountTypeId: number;
    tenantId: number;
    accountTypeName: string;
    order: number;
    isDefault: boolean;
    accountNameRequired: boolean;
    accountClassifications?: Array<AccountClassificationApiModel> | null;
    groupConfiguration?: GroupConfigurationApiModel;
    shipToConfiguration?: ShipToConfigurationApiModel;
    billToConfiguration?: BillToConfigurationApiModel;
    commissionEnabled: boolean;
    wholesalePricingEnabled: boolean;
    dropshipEnabled: boolean;
    taxIdentificationNumberType: TaxIdentificationNumberTypeApiEnum;
}

