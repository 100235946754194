import { Injectable } from '@angular/core';
import { BATCH, REMOVE_APP_COLUMN, REMOVE_APP_COLUMN_UNSAVED_DATA } from '../actions';
import { AppColumnAction, BatchAction } from '../action-types';

@Injectable({
    providedIn: 'root'
})
export class RemoveAppColumnCreator {
    removeAppColumn(appColumnId: number, viewportWidth: number): BatchAction {
        const actions: AppColumnAction[] = [
            {
                type: REMOVE_APP_COLUMN,
                payload: {
                    appColumnId,
                    viewportWidth
                }
            },
            {
                type: REMOVE_APP_COLUMN_UNSAVED_DATA,
                payload: {
                    appColumnId
                }
            }
        ];

        return {
            type: BATCH,
            payload: actions
        };
    }
}
