import { Injectable } from '@angular/core';
import { ADD_APP_COLUMN, BATCH, MOVE_APP_COLUMN_ONSTAGE, SELECT_APP_COLUMN } from '../actions';
import { AppColumn } from '../../../app-column-router/app-column';
import { AppColumnAction, BatchAction } from '../action-types';

@Injectable({
    providedIn: 'root'
})
export class AddAppColumnCreator {
    addAppColumn(
        appColumnId: number,
        url: string,
        routeGroupingKey: string,
        viewportWidth: number,
        appColumns: AppColumn[]
    ): BatchAction {
        let actions: AppColumnAction[] = [
            {
                type: ADD_APP_COLUMN,
                payload: {
                    appColumnId,
                    url,
                    routeGroupingKey
                }
            },
            {
                type: SELECT_APP_COLUMN,
                payload: {
                    appColumnId,
                    dateAccessed: Date.now()
                }
            }];

        if (appColumns.length > 0) {
            actions = [
                ...actions,
                // TODO: Commented out per DEV-4535 app column changes.
                // {
                //     type: MOVE_APP_COLUMN,
                //     payload: {
                //         appColumnId,
                //         targetId: appColumns[0].id
                //     }
                // },
                {
                    type: MOVE_APP_COLUMN_ONSTAGE,
                    payload: {
                        viewportWidth
                    }
                }
            ];
        }

        return {
            type: BATCH,
            payload: actions
        };
    }
}
