import { AppColumn } from '../../../../app-column-router/app-column';
import { UpdateColumnSizeAction } from '../../action-types';

export const updateColumnSize = (appColumns: AppColumn[], action: UpdateColumnSizeAction) => {
    return appColumns.map(appColumn => {
        if (appColumn.id === action.payload.appColumnId) {
            return Object.assign(appColumn.copy(), { size: action.payload.size });
        }
        return appColumn;
    });
};
