import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CssClassBinderDirective } from '@center-suite/shared/utility/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[comLinkIcon]',
    templateUrl: './link-icon.component.html',
    styleUrls: ['./link-icon.component.scss']
})
export class LinkIconComponent extends CssClassBinderDirective implements OnInit {
    @Input() textMain: string;
    @Input() lastWord: string;
    @Input() linkId: string | number;
    @HostBinding('class.comLinkIcon__simple')
    get hasLastWord(): boolean {
        return this.lastWord === undefined;
    }
    iconPath = 'assets/shared/ui/core-theme/assets/icons/general-icons/icon-arrow-03.svg#icon';
    viewBox = '0 0 9 9';

    ngOnInit() {
        switch (true) {
            case this.cssClassBinder && this.cssClassBinder.includes('comLinkIcon__layout--02'):
                this.iconPath = 'assets/shared/ui/core-theme/assets/icons/general-icons/icon-box-with-arrow-01.svg#icon';
                this.viewBox = '0 0 17 13.4';
                break;
            case this.cssClassBinder && this.cssClassBinder.includes('comLinkIcon__layout--03') || this.cssClassBinder.includes('comLinkIcon__layout--04'):
                this.iconPath = 'assets/shared/ui/core-theme/assets/icons/general-icons/icon-arrow-01.svg#icon';
                this.viewBox = '0 0 5.742 3.534';
                break;
            default:
                break;
        }
    }
}
